import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, IconButton, ButtonGroup } from '@mui/material';




import React, { useContext, useCallback } from 'react';

import CmsContext from '../../../store/cms-context';

import { useState, useRef, useEffect } from "react";

import SearchEngineListingPreview from './SubComponents/SearchEngineListingPreview';
import ProductOptions from './SubComponents/ProductOptions';
import Shipping from './SubComponents/Shipping';
import Inventory from './SubComponents/Inventory';
import Media from './SubComponents/Media';
import MediaGrid from './SubComponents/MediaGrid';
import CreateProductSideExtras from './SubComponents/CreateProductSideExtras';
import Editor from './SubComponents/Editor';

import VariantsTable from './VariantsTable/VariantsTable';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { default_deploy_dialog, default_google_merchant_product, hide_dialog, ProductStatus, set_dialog_loading, show_dialog } from '../../../Utilities/Metrics';

import { default_variant } from '../../../Utilities/Metrics';


import Pricing from './SubComponents/Pricing';
import { cartesian, getAccessJwtToken } from '../../../store/globalUtilities';
import SelectImageDialog from '../../SelectImageDialog/SelectImageDialog';

import { Storage } from 'aws-amplify';

import cloneDeep from 'lodash/cloneDeep';
import EditVariantDialog from '../EditVariantDialog/EditVariantDialog';
import GoogleMerchantDialog from '../../GoogleMerchantDialog/GoogleMerchantDialog';
import DeployDialog from '../../DeployDialog/DeployDialog';
import CurrenciesInfo from '../../../Utilities/CurrenciesInfo';

function CreateProduct(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [title, settitle] = useState('');

  const [ck_state, set_ck_state] = useState('');

  const [inventory_sku, set_inventory_sku] = useState('');

  const [inventory_barcode, set_inventory_barcode] = useState('');

  const [track_quantity, set_track_quantity] = useState(false);

  const [continue_selling, set_continue_selling] = useState(false);

  const [available_quantity, set_available_quantity] = useState(0);

  const [shipping_is_physical, set_shipping_is_physical] = useState(true);

  const [shipping_weight, set_shipping_weight] = useState(parseFloat(0.0).toFixed(1));

  const [weight_metric_value, set_weight_metric_value] = useState('kg');

  const [selected_country, set_selected_country] = useState('');

  const [hs_code, set_hs_code] = useState('');

  const [initial_product_data, set_initial_product_data] = useState({'templates':[], 'collections':[],'vendors':[],'publications':[]});

  const [selected_product_template, set_selected_product_template] = useState('');

  const [selected_collection, set_selected_collection] = useState('');

  const [vendor, set_vendor] = useState('');

  const [product_options, set_product_options] = useState([]);

  const [page_title, set_page_title] = useState('');

  const [meta_description, set_meta_description] = useState('');

  const [url_handle_text, set_url_handle_text] = useState('');

  const [productType, setProductType] = React.useState('');

  const [customProductType, setCustomProductType] = React.useState('');

  const [standardizedProductType, setStandardizedProductType] = React.useState('');

  const [productCategory, setProductCategory] = React.useState('');

  const [sales_channels_settings, set_sales_channels_settings] = React.useState({'online_store_selected':false,'point_of_sale_selected':false});

  const [tags, set_tags] = React.useState('');

  const [schedule_date, set_schedule_date] = React.useState({'date': new Date().setTime((new Date()).getTime() + (1*60*60*1000)), 'should_schedule':false});

  const [imageFiles, setImageFiles] = React.useState([]);

  const [all_variants, set_all_variants] = React.useState([]);

  const [price, set_price] = React.useState('');

  const [compare_at_price, set_compare_at_price] = React.useState('');

  const [cost_per_item, set_cost_per_item] = React.useState('');    

  const [charge_tax, set_charge_tax] = React.useState(false);

  const [tax_code, set_tax_code] = React.useState('');

  const [product_status, set_product_status] = React.useState('Draft');

  const [selected_variant_image, set_selected_variant_image] = React.useState({'variant_index':-1, 'merchant_index':-1, 'image_index':-1,'is_dialog_open':false})

  const [product, set_product] = React.useState({})

  const [variant_dialog_state, set_variant_dialog_state] = React.useState({'index':0,'is_dialog_open': false, 'variant':cloneDeep(default_variant)});

  const [product_collections, set_product_collections] = React.useState([])

  const [is_first, set_is_first] = React.useState(true);

  const [google_merchant_checked, set_google_merchant_checked] = React.useState(false);

  const [google_merchant_dialog_state, set_google_merchant_dialog_state] = React.useState({"is_dialog_open":false,"shippings":{}, variant_index:-1,'isLoading':false, variant:cloneDeep(default_variant), google_merchant:cloneDeep(default_google_merchant_product)});

  const [deploy_dialog_state, set_deploy_dialog_state] = React.useState(cloneDeep(default_deploy_dialog))

  const [store_currency, set_store_currency] = React.useState('£')

  const [all_stores, set_all_stores] = React.useState([])


  useEffect(()=>{

    isMountedRef.current = true

  

    if(!isLoading){

    if(props.type === "Edit"){

      get_product_data("Edit", props.product_type)

    }else if(props.type === "Create"){

      get_product_data("Create", props.product_type)

    }else{

      process_product_data(props.variation)

    }

    // console.log('props store was: ', props.variation)

    if(props.variation && props.variation.store){
      set_store_currency(CurrenciesInfo[props.variation.store.currency].symbol)
    }

  }

    return () => isMountedRef.current = false;

  },[])


  useEffect(() =>{

    const temp_stores = cmsContext.current_shop.connected_stores;

    var all_stores = [];

    all_stores.push({"name":"Master","currency":"GBP","store_domain":"master","targetCountry":"GB","google_merchant_available":true})

    all_stores = all_stores.concat(temp_stores)

    set_all_stores(all_stores)

  },[cmsContext.current_shop.connected_stores])



  function reset_page(delayed){

    setTimeout(function() {

      window.location.reload();

  }, delayed?100:1)

}


  useEffect(() => {

    // console.log('product options were: ', product_options)

    var mini_values = [];

    var individual_options = [];

    const temp_options = [...product_options];

    for(var temp_index = 0; temp_index < temp_options.length; temp_index++){

      mini_values = [];

      for(var second_index=0; second_index < temp_options[temp_index].option_values.length; second_index++){

        mini_values.push(temp_options[temp_index].option_values[second_index].value + "___" + temp_options[temp_index].option_values[second_index].unique_id)

      }

      individual_options.push(mini_values);

    }


    const cartesian_rows = cartesian(...individual_options)

    var temp_key = ""

    const temp_variants = []

    // console.log('cartesian rows were: ', cartesian_rows)

    cartesian_rows.forEach((row) => {

      // console.log('row was: ', row)

      const new_variant = cloneDeep(default_variant)

      temp_key = row.toString().replaceAll(',',' / ')

      // console.log('key was: ', temp_key)

      var variant_value = "";

      var unique_id = "";

      const row_length = cartesian_rows.length

      row.forEach((element, index) => {

       // console.log('element was: ', element)

        if(index > 0){
          variant_value = variant_value + " / " + element.split("___")[0]
        }else{
          variant_value = variant_value+ element.split("___")[0]
        }

        unique_id = unique_id + element.split("___")[1]

      })

      //new_variant.value = temp_key.split("___")[0]

      new_variant.value = variant_value

      // console.log('row current was: ', row)

      //new_variant.unique_id = temp_key.split("___")[1]

      new_variant.unique_id = unique_id

      if(price.toString().length > 0){

        try{

          const temp_price = parseFloat(price)

          if(!Number.isNaN(temp_price)){

          new_variant.price = temp_price

          }
        
        }catch(err){
          new_variant.price = ''
          // console.log('error was: ' + err)
        }
    
      }

      if(selected_country){
        new_variant.selected_country = selected_country
      }

      if(hs_code.trim().length > 0){
        new_variant.hs_code = hs_code.trim()
      }

      temp_variants.push(new_variant)

    })

    

    // console.log('f rows were', cloneDeep(temp_variants))

    const previous_variants = [...all_variants];

    // console.log('previous variants were: ', previous_variants)

    for (var y = 0; y < previous_variants.length; y++){

     // console.log('prev variant: ', previous_variants[y].unique_id)

      for (var z = 0; z < temp_variants.length; z++){

       //console.log('temp varaint: ', temp_variants[z].unique_id)

        if(previous_variants[y].value === temp_variants[z].value || (previous_variants[y].unique_id && temp_variants[z].unique_id && (previous_variants[y].unique_id === temp_variants[z].unique_id))){

          const temp_value = temp_variants[z].value
          temp_variants[z] = previous_variants[y]
          temp_variants[z].value = temp_value

        }

      }

    }

    // console.log('setting from optio1ns: ' , temp_variants)

    set_all_variants(temp_variants)

  },[product_options])




  useEffect(() => {


    // console.log('pr av changed')

    //console.log('selected country was: ', selected_country)
    
    
    try{

      if (price && hs_code && selected_country) {

      const temp_price = parseFloat(price)

      const temp_hs_code = hs_code.trim()

      const temp_selected_country = selected_country

      if(!Number.isNaN(temp_price) || temp_hs_code.trim().length > 0){

      const previous_variants = [...all_variants];
  
      for (var i = 0; i < previous_variants.length;i++){

        if(!Number.isNaN(temp_price)){
        
        previous_variants[i].price = temp_price

        }

        if(temp_hs_code.length > 0){

          previous_variants[i].hs_code = temp_hs_code

        }

        previous_variants[i].selected_country = temp_selected_country

        
      }
    
      // console.log('setting from optio1ns', previous_variants)
    
      set_all_variants(previous_variants)

    }

    }
    
    }catch(err){
      // console.log('error was: ' + err)
    }


    
    },[price, hs_code, selected_country])

    /*

    useEffect(() =>{

      if(props.is_enabled){

        set_is_first(false)

      }

    },[props.is_enabled])

    */



  function select_variant_image(index, merchant_index) {

    // handles both merchant and variant images hint: variant index and merchant_index

    // console.log('changing variant image')

    // check this

    var image_index = -1

    const temp_image_files = [...imageFiles]


    temp_image_files.forEach((temp_image, tindex) => {
      if(all_variants[index].aws_key && all_variants[index].aws_key === temp_image.aws_key){
        image_index = tindex
      }
    })

    set_selected_variant_image({'variant_index':index, 'merchant_index': merchant_index, 'image_index':image_index, 'is_dialog_open':true})
    
  }

  const editor_ready = useCallback(
    () => {

      // console.log('editor_ready from inside', product) 

      if(product && product.ck_state){

        // console.log('html was', product.ck_state)
      set_ck_state(product.ck_state)

      }else{
        // console.log("no html")
      }

    
    
    },
    [product],
  );


  const handle_accepted_files = useCallback(

    (acceptedFiles) => {

    var temp_files = [...imageFiles];

    acceptedFiles = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file), is_uploaded:false, mediaContentType:'',originalSource:'',aws_key:'',is_checked:false}));

    var temp_accepted_files = [...acceptedFiles];

    temp_accepted_files.forEach((file) =>{

      file.modified_name = Date.now().toString() + "_" + file.name.replaceAll(' ', '_')

    })

    /*

    for(var i=0; i < temp_files.length; i++){

      

      for(var j=0; j < temp_accepted_files.length; j++){

        if(temp_files[i].path === temp_accepted_files[j].path){
          temp_accepted_files.splice(j,1)
        }

      }
      

      temp_accepted_files[i].name = temp_accepted_files[i].name + "_" + Date.now().toString()

    }

    */
    
    // console.log('ct files',temp_accepted_files);
    
    temp_files = temp_files.concat(temp_accepted_files);
    
    // console.log('files were',temp_files); 

    setImageFiles(temp_files);

    upload_files(temp_accepted_files)

    },[imageFiles]);


  function update_variant(){

    const temp_dialog_state = {...variant_dialog_state}

    const temp_variants = [...all_variants]

    temp_variants[temp_dialog_state.index] = temp_dialog_state.variant

    temp_dialog_state.is_dialog_open = false

    set_variant_dialog_state(temp_dialog_state)

    set_all_variants(temp_variants)
    
    sendProductRequest("Save")

  }


  function handle_variant_dialog_state(index, variant, is_open){

    const temp_dialog_state = {...variant_dialog_state}

    temp_dialog_state.is_dialog_open = is_open

    temp_dialog_state.variant = cloneDeep(variant)

    temp_dialog_state.index = index
    
    //{'is_dialog_open': false, 'variant':{'price': '','cost_per_item':'','track_quantity':false, 'continue_selling': false, 'available_quantity':0, 'shipping_is_physical':false,'shipping_weight':'','weight_metric_value':'','selected_country':'','hs_code':'','inventory_barcode':''}

    set_variant_dialog_state(temp_dialog_state)

  }

  function handle_variant_merchant_state(index, variant, is_open, product, shippings){

    // console.log('product was mc:', product)

    if(Object.keys(product).length > 0){

      // console.log('setting retrieved mercahant: ', product)

      if(product.imageLink.aws_key){

        const temp_files = [...imageFiles]

        const index = temp_files.findIndex((file) =>{
          return file.aws_key === product.imageLink.aws_key
        })

        if(index > -1){
          // console.log('index found')
          product.imageLink.url = temp_files[index].originalSource
        }else{
          // console.log('index not found')
          product.imageLink = {url:""}
        }

      }

      product.additionalImageLinks.forEach((imageLink, index) =>{

        if(imageLink.aws_key){

          const temp_files = [...imageFiles]
  
          const index2 = temp_files.findIndex((file) =>{
            return file.aws_key === imageLink.aws_key
          })
  
          if(index > -1){
            // console.log('index found')
            imageLink.url = temp_files[index2].originalSource
          }else{
            // console.log('index not found')
            imageLink = {url:""}
          }
  
        }

      })


      set_google_merchant_dialog_state({"is_dialog_open":is_open,variant_index:index, 'shippings': cloneDeep(shippings), 'isLoading':false, variant:cloneDeep(variant), google_merchant:cloneDeep(product)})

    }else{

      const temp_dialog_state = {...google_merchant_dialog_state}

      temp_dialog_state.is_dialog_open = is_open

      temp_dialog_state.google_merchant = cloneDeep(default_google_merchant_product)

      temp_dialog_state.index = index

      temp_dialog_state.variant = cloneDeep(variant)
      
      //{'is_dialog_open': false, 'variant':{'price': '','cost_per_item':'','track_quantity':false, 'continue_selling': false, 'available_quantity':0, 'shipping_is_physical':false,'shipping_weight':'','weight_metric_value':'','selected_country':'','hs_code':'','inventory_barcode':''}

      //temp_dialog_state.google_merchant.title = page_title + " " + variant.value; // product description or seo?

      temp_dialog_state.google_merchant.brand = vendor;

     // temp_dialog_state.google_merchant.description = meta_description; // seo?

      // console.log('merchant store was: ', props.variation.store)

      // console.log('variant was: ', variant)

      //temp_dialog_state.google_merchant.link = "https://" + props.variation.store.store_domain + "/products/"  + url_handle_text // seo?

      const temp_files = [...imageFiles]

      // console.log('temp files were: ', temp_files)

      // console.log('aws key was: ', variant.aws_key)

      if(variant.aws_key){

        const index = temp_files.findIndex((file) =>{
          return file.aws_key === variant.aws_key
        })

        if(index > -1){
          // console.log('index found')
          temp_dialog_state.google_merchant.imageLink = {aws_key: variant.aws_key, url: temp_files[index].originalSource}
        }else{
          // console.log('index not found')
          temp_dialog_state.google_merchant.imageLink = {url:""}
        }

      }else{
        temp_dialog_state.google_merchant.imageLink = {url:""}
      }

      temp_dialog_state.google_merchant.price.value = variant.price

      temp_dialog_state.google_merchant.price.currency = props.variation.store.currency ? props.variation.store.currency : "GBP"

      temp_dialog_state.google_merchant.targetCountry = props.variation.store.targetCountry ? props.variation.store.targetCountry : "GB"

      temp_dialog_state.google_merchant.shippingWeight.value = variant.shipping_weight

      temp_dialog_state.google_merchant.shippingWeight.unit = variant.weight_metric_value

      temp_dialog_state.shippings = shippings

      temp_dialog_state.google_merchant.gtin = variant.barcode
        
      set_google_merchant_dialog_state(temp_dialog_state)

  }

  }


  return(

    <>
    
    {/*!is_first ? <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: props.type === "Deploy" ? 2 : 8,
        display: props.is_enabled ? "block" : "none"
      }*/}

      <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: props.type === "Deploy" ? 2 : 8,
        display: "block"
      }}
      >

      {!isLoading?


      <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
    
      <Grid
          container
          spacing={2}
          style={{overflow:'visible'}}
        >

          <Grid
            item
            xs={12}
            md={8}
          >

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >

            <div style={{display:'flex', alignItems:'center'}}>
              
              {(props.type === "Edit" || props.type === "Deploy") && props.set_component_state != null ? <IconButton aria-label="delete" color="primary" onClick={() => {props.set_component_state({layout_type: 'list', product_id : ''})}}>
                <ArrowBackIcon />
              </IconButton> : null}

            <Typography variant="h5">Products / {props.type} {props.type === "Deploy" ? " / " + props.variation.store.name : null}</Typography> 

            </div>

          {props.type !== "Deploy" ? <Button variant="contained" disabled = {title.trim().length === 0} onClick = {() => {sendProductRequest(props.type === "Edit" ? "Update" : "Create")}}>{props.type === "Edit" ? "Update" : props.type === "Deploy" ? "Deploy" : "Create"} Product</Button> : null}

          </Stack>

        </Grid>


       {props.type === "Deploy" ? <Grid
          item
          xs={12}
          md={4}
        >

            <ButtonGroup disableElevation variant="contained" >
            <Button onClick = {() => {sendProductRequest("Delete")}}>{props.product_type === "Master" ? "Delete": "Delete"}</Button>
            <Button onClick = {() => {sendProductRequest("Save")}}>Save</Button>
            <Button onClick = {() => {show_dialog(deploy_dialog_state, set_deploy_dialog_state)}}>{props.product_type === "Master" ? "Deploy all": "Deploy"}</Button>
            </ButtonGroup>

        </Grid> : null}

        <Grid
          item
          xs={12}
          md={8}
        >

        <Grid
          container
          spacing={3}
          alignItems="center"
        >

          <Grid
            item
            xs={12}
            
          >

        <Card elevation={2} style={{padding:20,overflow:'visible'}} >

          <TextField id="outlined-basic" label="Title" variant="outlined" value={title} onChange={(event)=>{settitle(event.target.value)}} InputLabelProps={{ shrink: true }} fullWidth/>

          <Typography variant='subtitle1' style={{marginTop:20}}>Description</Typography>

           <Editor editor_ready={editor_ready} ck_state={ck_state} set_ck_state={set_ck_state}></Editor>

        </Card>

        </Grid>
    
        </Grid>


      
      <MediaGrid type={props.type} domain={props.variation ? props.variation.store.store_domain : cmsContext.current_shop.store_domain} all_variants={all_variants} set_all_variants={set_all_variants} handle_accepted_files={handle_accepted_files} imageFiles = {imageFiles} setImageFiles = {setImageFiles}></MediaGrid>


      <Pricing store_currency={store_currency} price = {price} set_price={set_price} compare_at_price={compare_at_price} set_compare_at_price={set_compare_at_price} cost_per_item={cost_per_item} set_cost_per_item = {set_cost_per_item} charge_tax={charge_tax} set_charge_tax = {set_charge_tax} tax_code = {tax_code} set_tax_code = {set_tax_code}></Pricing>


      <Inventory available_quantity={available_quantity} set_available_quantity={set_available_quantity} inventory_sku = {inventory_sku} set_inventory_sku = {set_inventory_sku} inventory_barcode = {inventory_barcode} set_inventory_barcode = {set_inventory_barcode} track_quantity = {track_quantity} set_track_quantity = {set_track_quantity} continue_selling = {continue_selling} set_continue_selling = {set_continue_selling}></Inventory>


       <Shipping shipping_is_physical={shipping_is_physical} set_shipping_is_physical={set_shipping_is_physical} weight_metric_value={weight_metric_value} set_weight_metric_value = {set_weight_metric_value} shipping_weight = {shipping_weight} set_shipping_weight = {set_shipping_weight} selected_country = {selected_country} set_selected_country = {set_selected_country} hs_code={hs_code} set_hs_code = {set_hs_code}></Shipping>



        <ProductOptions product_options={product_options} set_product_options = {set_product_options}></ProductOptions>

        

        <VariantsTable store_currency={store_currency} type={props.type} google_merchant_dialog_state={google_merchant_dialog_state} get_merchant_product={get_merchant_product} handle_variant_dialog_state={handle_variant_dialog_state} set_component_state={props.set_component_state} select_variant_image = {select_variant_image} all_variants={all_variants} set_all_variants={set_all_variants} product_options={product_options} set_product_options = {set_product_options}></VariantsTable>



       { props.type === "Deploy" ? <SearchEngineListingPreview type="product" url_handle_text = {url_handle_text} set_url_handle_text = {set_url_handle_text} meta_description={meta_description} set_meta_description={set_meta_description} page_title={page_title} set_page_title={set_page_title}></SearchEngineListingPreview> : null}


        </Grid>
        

        <Grid
            item
            xs={12}
            md={4}
          >

            {props.type === "Deploy" ? <CreateProductSideExtras google_merchant_available={props.variation.store.google_merchant_available} google_merchant_checked={google_merchant_checked} set_google_merchant_checked={set_google_merchant_checked} product_collections={product_collections} set_product_collections={set_product_collections} customProductType={customProductType} setCustomProductType={setCustomProductType} standardizedProductType={standardizedProductType} setStandardizedProductType={setStandardizedProductType} productCategory={productCategory} setProductCategory={setProductCategory} product={product} product_status = {product_status} set_product_status = {set_product_status} schedule_date = {schedule_date} set_schedule_date = {set_schedule_date} tags = {tags} set_tags = {set_tags} sales_channels_settings={sales_channels_settings} set_sales_channels_settings = {set_sales_channels_settings} initial_product_data = {initial_product_data} set_initial_product_data = {set_initial_product_data} selected_product_template ={selected_product_template} set_selected_product_template = {set_selected_product_template} selected_collection = {selected_collection} set_selected_collection = {set_selected_collection} vendor = {vendor} set_vendor = {set_vendor}></CreateProductSideExtras> : null}
       
        </Grid>

        </Grid>

      </Container>      

    :  <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> }  

        <SelectImageDialog handle_selected_variant_image={handle_selected_variant_image} selected_variant_image={selected_variant_image} set_selected_variant_image={set_selected_variant_image} handle_accepted_files={handle_accepted_files} imageFiles={imageFiles}></SelectImageDialog>

        <EditVariantDialog update_variant={update_variant} variant_dialog_state={variant_dialog_state} set_variant_dialog_state={set_variant_dialog_state}></EditVariantDialog>

        <GoogleMerchantDialog product_type={props.product_type} send_merchant_request={send_merchant_request} select_variant_image={select_variant_image} google_merchant_dialog_state={google_merchant_dialog_state} set_google_merchant_dialog_state={set_google_merchant_dialog_state}></GoogleMerchantDialog>

      {deploy_dialog_state ? <DeployDialog product_type={props.product_type}  type="individual" sendProductRequest={sendProductRequest} all_stores={all_stores} deploy_dialog_state={deploy_dialog_state} set_deploy_dialog_state={set_deploy_dialog_state}></DeployDialog> : null}

    </Box>
  
    </>

  );


  function get_product_data(type, product_type) {
    
    // console.log('store_access_token')

    // user api

    // error handling and state managment required


    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      const request_headers = {'store_domain': cmsContext.current_shop.store_domain,
      'Authorization':jwt}
  
      if(type === "Edit"){
        request_headers.product_id = props.product_id
      }

      request_headers.product_type = product_type

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/create_product_data',{
          crossDomain:true,
          method: 'GET',
          headers:request_headers})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
               //console.log('result was:',result)

              process_product_data(result)
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
             //console.log('error was',error)
            }
      )

    })

  }


  function process_product_data(result){


    // console.log('result inner was: ' , result)


    if(result.data){

      var temp_data = {...result.data}

      temp_data.publications = temp_data.publications.map((publication) => {
        return(Object.assign(publication,{'is_checked':false}))
      }); 

      if(result.data.product){

        const product = result.data.product

        if(product){

          refresh_state_from_product(product)

        }else{
          // console.log('no product')
        }

        if(props.product_type !== product.type ){

          product.last_request_status = "New"

        }

        set_product(product)

       // // console.log('body was', product.descriptionHtml)

        //settitle(product.title)
      
        if(product.publications && product.publications.length > 0){

          // console.log('inside previous 0')

          // console.log('inside previous')

          temp_data.publications.forEach((publication) => {

            product.publications.forEach((published_publication) =>{
              if(published_publication.id === publication.node.id){
                publication.is_checked = true
              }else{
                // console.log('not equals', publication.node.id, published_publication.id)
              }
            })

          })

        }

      }

      delete temp_data.product

      // console.log('temp data was ', temp_data)
      
      set_initial_product_data(temp_data)

    
    }else{
      
       console.log('Error', result)

      cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")

    }


  }


  function sendProductRequest(request_type){

    const selected_options = [];

    const request_body = {};

    if(request_type === "Deploy"){

      const temp_state = {...deploy_dialog_state}

      if(props.product_type === "Master"){

        if(temp_state.selected_stores.length === 0){
          cmsContext.setSnackBarOpen(true, "error", "Please select valid stores!")
          return
        }

        const selected_stores = [];

        temp_state.selected_stores.forEach((store) => {

            selected_stores.push(store.store_domain)

        })

        request_body.selected_stores = selected_stores;

      }

      temp_state.options.forEach((option) => {

        if(option.is_checked){
          selected_options.push(option.key)
        }

      })
      

      if(selected_options.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please select at least one component!")
        return
      }
      

    }
  

    if(title.trim().length === 0){

      // console.log('invalid title')
      return

    }

    const final_product = {}

    final_product.title = title.trim()

    var found_incorrect_option = false;
    
    final_product.options = product_options.map((option) => {

      if(option.option_name.trim().length === 0){
        found_incorrect_option = true;
      }
      return(option.option_name)

    })


    if(found_incorrect_option){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a non-empty value for each option")
      return

    }




    var temp_images = cloneDeep(imageFiles)

    //  "originalSource": image.originalSource

    temp_images = temp_images.map((image) => {

      return({"mediaContentType": image.mediaContentType, "aws_key":image.aws_key})

    });


    // console.log('temp images were',temp_images)

    final_product.media = temp_images


    // Default variant

    //parseInt(variant.price) || 0

    var found_incorrect_variant = false;

    if(all_variants.length === 0 && inventory_sku.trim().length > 0){

      const default_variant = {};

      default_variant.title='Default Title'

      default_variant.sku = inventory_sku.trim();

      default_variant.barcode = inventory_barcode.trim();

      default_variant.inventoryItem={
        "cost": (parseFloat(price) || 0).toString(),
        "tracked": track_quantity
      }

      default_variant.price =  (parseFloat(price) || 0).toString();


      default_variant.compareAtPrice = (parseFloat(compare_at_price) || 0).toString();

      default_variant.taxable = charge_tax;

      default_variant.taxCode = tax_code.trim();

      default_variant.weight = (parseFloat(shipping_weight) || 0).toString();

      //default_variant.weightUnit = Metrics[weight_metric_value]

      //const metric_value = Metrics[weight_metric_value]

      const metric_value = weight_metric_value

      if(metric_value === 'g'){
        default_variant.weightUnit = "GRAMS";
      }else if(metric_value === 'lb'){
        default_variant.weightUnit = "POUNDS";
      }else if(metric_value === 'oz'){
        default_variant.weightUnit = "OUNCES";
      }else{
        default_variant.weightUnit = "KILOGRAMS";
      }

      default_variant.harmonizedSystemCode  = hs_code;

      final_product.variants = [default_variant];

    }else{

        const temp_variants = []

        all_variants.forEach((variant) => {

          if(variant.value.trim().length === 0){
            found_incorrect_variant = true;
          }

          // handle dynamic location

          const temp_variant = {...variant}

          if(variant.imageSrc){
           // // console.log("yes variant has imaged", variant)
            //& request_type === "handle"

            temp_variant.aws_key = variant.aws_key

            temp_variants.push(temp_variant)

          }else{
            // console.log("no variant has no image")
            temp_variants.push(temp_variant)
          }

        })

        final_product.variants = temp_variants;

    }

    if(found_incorrect_variant){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a non-empty value for each variant")
      return
    }


    const publications = [];

    initial_product_data.publications.forEach(publication => {
      if(publication.is_checked){
        publications.push({"id": publication.node.id, "name": publication.node.name})
      }
    });

    final_product.publications = publications;

    final_product.ck_state = ck_state;

    final_product.inventory_sku = inventory_sku;

    final_product.inventory_barcode = inventory_barcode;

    final_product.track_quantity = track_quantity;

    final_product.continue_selling = continue_selling;

    final_product.available_quantity = available_quantity;

    final_product.shipping_is_physical = shipping_is_physical;

    final_product.shipping_weight = shipping_weight;

    final_product.weight_metric_value = weight_metric_value;

    final_product.selected_country = selected_country;
       
    final_product.hs_code = hs_code;

    final_product.selected_product_template = selected_product_template;

    final_product.selected_collection = selected_collection;

    final_product.product_options = product_options;

    final_product.page_title = page_title;

    final_product.meta_description = meta_description;

    final_product.url_handle_text = url_handle_text;

    final_product.sales_channels_settings = sales_channels_settings;

    final_product.tags = tags;

    final_product.schedule_date = schedule_date;

    final_product.selected_country = selected_country;

    final_product.weight_metric_value = weight_metric_value;

    final_product.initial_product_data = initial_product_data;

    final_product.selected_product_template = selected_product_template;

    final_product.selected_collection = selected_collection;

    final_product.vendor = vendor;

    final_product.product_options = product_options;

    final_product.page_title = page_title;

    final_product.meta_description = meta_description;

    final_product.url_handle_text = url_handle_text;

    final_product.sales_channels_settings = sales_channels_settings;

    final_product.standardizedProductType = standardizedProductType;

    // change waiting for product taxonomy nodes

    //product.productCategory = productCategory.trim().length > 0 ? productCategory.trim() : "Clothing";

    final_product.google_merchant_checked = google_merchant_checked;

    final_product.customProductType = customProductType.trim();

    // dont store original source

    temp_images = cloneDeep(imageFiles);

    temp_images.forEach((image) => {
      delete image.originalSource
      delete image.preview
    })

    final_product.imageFiles = temp_images;

    const temp_variants = cloneDeep(all_variants);

    temp_variants.forEach((variant) =>{
      delete variant.imageSrc
    })

    final_product.all_variants = temp_variants;

    final_product.price = price;

    final_product.compare_at_price = compare_at_price;

    final_product.cost_per_item = cost_per_item;
  
    final_product.charge_tax = charge_tax;

    final_product.tax_code = tax_code;

    final_product.product_status = product_status;

    final_product.selected_variant_image = selected_variant_image;

    if(request_type === "Deploy"){
      final_product.selected_options = selected_options
    }

    // console.log("product state was, " , product) 

    request_body.product = final_product

    request_body.request_type = request_type

    
    if(product.id){

      if(props.product_type === "Master" || product.parent){

        request_body.product.id = product.id

      }

    }

    if(props.product_type === "Variation"){

      request_body.product.parent = product.parent ? product.parent : product.id

    }

    request_body.product_type = props.product_type

    // console.log('product type was, ' + props.product_type)

    // console.log('request final body was: ', JSON.stringify(request_body))

    const temp_product = {...product}


    // handle remove return

    if(request_type === "Deploy"){
      set_dialog_loading(deploy_dialog_state, set_deploy_dialog_state, true)
    }else{
      setIsLoading(true)  
    }
    


    getAccessJwtToken().then((jwt)=>{


    fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/create',{
            crossDomain:true,
            method: 'POST',
            headers: {
              'store_domain': props.variation ? props.variation.store.store_domain : cmsContext.current_shop.store_domain,
              'email': cmsContext.current_shop.email,
              'content-type':'application/json',
              'Authorization':jwt
            },
            body: JSON.stringify(request_body)
          })
            .then(res => res.json())
            .then(
              (result) => {
    
                if(request_type === "Deploy"){
                  set_dialog_loading(deploy_dialog_state, set_deploy_dialog_state, false)
                }else{
                  setIsLoading(false)  
                }

                // console.log('result was',result)

                if(result.message){

                  cmsContext.setSnackBarOpen(true, "success", result.message)

                  if(props.product_type !== "Master"){

                    if(request_type === "Save"){
                      temp_product.last_request_status = "Saved"
                    }else if(request_type === "Delete"){
                      temp_product.last_request_status = "Deleting"
                    }else{
                      temp_product.last_request_status = "Deploying"
                      hide_dialog(deploy_dialog_state, set_deploy_dialog_state)
                    }

                  }

                  set_product(temp_product)

                  if(props.type !== "Deploy"){
                  reset_page(true)
                  }else{
                    if(props.product_type === "Master"){
                      setTimeout(function(){
                        if(request_type === "Deploy" || request_type === "Save"){
                        //props.get_product_data()
                        }else{
                          reset_page()
                        }
                      }, 1000); 
                    }
                  }

                }else if(result.errorMessage){
                  cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                  return
                }else{
                  cmsContext.setSnackBarOpen(true, "error", "Error creating collection, please try again")
                  return
                }
                
              },
          
              (error) => {
                if(request_type === "Deploy"){
                  set_dialog_loading(deploy_dialog_state, set_deploy_dialog_state, false)
                }else{
                  setIsLoading(false)  
                }
                cmsContext.setSnackBarOpen(true, "error", error.toString())
                // console.log('error was',error)
              }
        );

    });


  }


  function set_merchant_loading(is_loading, variant_index){

    const temp_merchant_state = {...google_merchant_dialog_state};

    temp_merchant_state.isLoading = is_loading;

    if(variant_index != null){
      // console.log('setting non null index')
      temp_merchant_state.variant_index = variant_index
    }

    set_google_merchant_dialog_state(temp_merchant_state);

  }


  function get_merchant_product(index, variant, is_open){

    // console.log('getting: ', variant, " : ", index)

    set_merchant_loading(true, index);

    if(!product.id){
      cmsContext.setSnackBarOpen(true, "error", "Invalid product id!")
      return
    }

    var master_id = "";

    if(props.product_type === "Master"){

      master_id = product.id + "_" + variant.sku

    }else if(product.parent && product.parent.length > 0){

      master_id = product.parent + "_" + variant.sku

    }else{
      cmsContext.setSnackBarOpen(true, "error", "Invalid master product id!")
      return
    }

    var id = product.id + "_" + variant.sku

    /*

    var master_id = "";

    if(props.master_product.data.product.id){
      master_id = props.master_product.data.product.id + "_" + variant.sku
    }else{
      master_id = cmsContext.current_shop.email + "_" + props.master_product.data.product.created_at + "_" + variant.sku
    }

    if(props.product_type === "Master"){

      id = master_id

    }
    else{

      if(product.id){
        id = product.id + "_" + variant.sku
      }else{
        id = props.variation.store.store_domain + "_" + product.parent ? product.parent : props.master_product.id + "_" + "0" + "_" + variant.sku
      }

    }

    */

    // console.log('getting product id: ', id)
        

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/google_merchant', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt,
            'product_id':id,
            'store_domain': props.variation.store.store_domain,
            'master_id':master_id
          },
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              // console.log('result was',result)

              if(result.product && result.shippings){

                handle_variant_merchant_state(index, cloneDeep(variant), is_open, cloneDeep(result.product), cloneDeep(result.shippings))
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_merchant_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
                // console.log('Error', result)
                set_merchant_loading(false)

              }
  
            },
        
            (error) => {
              set_merchant_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              // console.log('error was',error)
            }
      )

    })



  }


  function send_merchant_request(request_type){

    const temp_merchant_state = {...google_merchant_dialog_state};

    // console.log('variant was: ', temp_merchant_state.variant.sku)

    if(temp_merchant_state.variant.sku == null || temp_merchant_state.variant.sku.trim().length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Variant must have a valid sku")
      return
    }

    set_merchant_loading(true);

    // console.log('updating: ', temp_merchant_state)

    var request_body = {}

    request_body.product = temp_merchant_state.google_merchant

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    request_body.product.variant_value =  temp_merchant_state.variant.value

    if(typeof request_body.product.availabilityDate === 'object'){

      request_body.product.availabilityDate = request_body.product.availabilityDate.toISOString()

    }

    if(typeof request_body.product.expirationDate === 'object'){

      request_body.product.expirationDate = request_body.product.expirationDate.toISOString()
  
    }


    if(props.product_type === "Master"){

      request_body.product.type = "Master"

      if(product.id){
        request_body.product.parent = product.id
        request_body.product.id = product.id + "_" + temp_merchant_state.variant.sku
      }else{
        request_body.product.parent = cmsContext.current_shop.email + "_" + product['created_at']
        request_body.product.id = cmsContext.current_shop.email + "_" + product['created_at'] + "_" + temp_merchant_state.variant.sku
      }
              
    }else{

      request_body.product.type = "Variation"

      request_body.product.store_domain = props.variation.store.store_domain

      if(product.id){
        request_body.product.parent = product.id
        request_body.product.id = product.id + "_" + temp_merchant_state.variant.sku
      }else{
        request_body.product.parent = props.variation.store.store_domain + "_" + product.parent ? product.parent : product.id + "_" + "0"
        request_body.product.id = props.variation.store.store_domain + "_" + product.parent ? product.parent : product.id + "_" + "0" + "_" + temp_merchant_state.variant.sku
      }

    }

    if(temp_merchant_state.shippings && temp_merchant_state.shippings.checked_index != null){
      request_body.product.shippings = temp_merchant_state.shippings.services[temp_merchant_state.shippings.checked_index].name
    }
        

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/google_merchant', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              // console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                set_google_merchant_dialog_state({"is_dialog_open":false, shippings:{}, 'isLoading':false, variant_index:-1,variant:cloneDeep(default_variant), google_merchant:cloneDeep(default_google_merchant_product)})
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_merchant_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
                // console.log('Error', result)
                set_merchant_loading(false)

              }
  
            },
        
            (error) => {
              set_merchant_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              // console.log('error was',error)
            }
      )

    })



  }

  async function upload_files(acceptedFiles) {

    //set_uploading(true)

    for(var i=0; i < acceptedFiles.length ; i++){

      const file = acceptedFiles[i];

      var url;

      var key;

    try {

      key = await Storage.put(cmsContext.current_shop.email + "/images/" + file.modified_name, file, {
       contentType: file.type, // contentType is optional
      });

      // console.log('key was', key)
      

      url = await Storage.get(key.key, {
        level: 'public',
        bucket: 'snag-cms175447-staging',
        expires:86400,
        region: 'eu-west-2',
      });



      // console.log('file was', file, url);

    } catch (error) {
      cmsContext.setSnackBarOpen(true, "error", error.toString())
    }

    const static_url = url;

    const static_key = key;

    setImageFiles((imageFiles) => {

      const temp_files = [...imageFiles];

      var index = 0;

      for(var z=0; z < temp_files.length; z++){

        if(file.modified_name === temp_files[z].modified_name){

         temp_files[z]['mediaContentType'] = 'IMAGE';
         temp_files[z]['originalSource'] = static_url;
         temp_files[z].aws_key = static_key.key;
         index = z;
         break

        }

        index = index + 1;

      }

      if(!static_url){

        temp_files.splice(index, 1)
        cmsContext.setSnackBarOpen(true, "error", "Error uploading some images, please try again!")

      }

      // console.log('files were',temp_files)

      return setImageFiles(temp_files)

    })


  }
   // set_uploading(false)

  }


  async function load_media_files(media_files, prev_variants){

    
    try {

    for(var i=0; i < media_files.length; i++){


     const url = await Storage.get(media_files[i].aws_key, {
        level: 'public',
        bucket: 'snag-cms175447-staging',
        expires:86400,
        region: 'eu-west-2',
      });

      media_files[i].originalSource = url;

      media_files[i].is_checked = false

      }


      } catch (error) {
        cmsContext.setSnackBarOpen(true, "error", error)
      }


      setImageFiles(media_files)
      

      set_all_variants((previous_varians) => {

        const temp_variants = prev_variants;

        // console.log('previous variants were', temp_variants)

        for(var y=0; y < temp_variants.length; y++){

          // console.log('1st loop')
  
          if(temp_variants[y].aws_key){
  
            for(var z=0; z < media_files.length; z++){
  
              if(temp_variants[y].aws_key === media_files[z].aws_key){

                // console.log('matched')
  
                temp_variants[y].imageSrc = media_files[z].originalSource
  
              }else{
                // console.log('not matched')
              }
  
            }
  
          }else{
            // console.log('no aws key')
          }
  
        }

        // console.log('settings all variants', temp_variants)

        return(set_all_variants(temp_variants))
  

      })


  }


  function handle_selected_variant_image(){

    const temp_image = {...selected_variant_image};

    // console.log('temp image was: ', temp_image)

    if(temp_image.merchant_index == null){

    // console.log('handling variant')

    //{'variant_index':-1, 'image_index':-1,'is_dialog_open':false}

   // console.log('image index was: ', temp_image.image_index, " : ", temp_image.variant_index )

    if(temp_image.variant_index > -1 && temp_image.variant_index < all_variants.length ){

      if(temp_image.image_index > -1 && imageFiles[temp_image.image_index].originalSource){

        set_all_variants((previous_variants) => {

          const temp_variants = [...previous_variants]

          temp_variants[temp_image.variant_index].imageSrc = imageFiles[temp_image.image_index].originalSource

          temp_variants[temp_image.variant_index].aws_key = imageFiles[temp_image.image_index].aws_key

          // console.log('updated variants were', temp_variants)

          return(set_all_variants(temp_variants))

        })

    

    }else{

      set_all_variants((previous_variants) => {

        const temp_variants = [...previous_variants]

        //console.log('variant at index was: ',temp_variants[temp_image.variant_index] )

        temp_variants[temp_image.variant_index].imageSrc = ""

        temp_variants[temp_image.variant_index].aws_key = ""

        // console.log('updated variants were', temp_variants)

        return(set_all_variants(temp_variants))

      })

    }

    set_selected_variant_image({'variant_index':-1 , 'image_index':-1,'is_dialog_open':false})
    
    }

  }else{

    // console.log('handling merchant')

    const temp_dialog_state = {...google_merchant_dialog_state}

    if(temp_image.merchant_index > 0){

      temp_dialog_state.google_merchant.additionalImageLinks[temp_image.merchant_index-1] =  {aws_key:  imageFiles[temp_image.image_index].aws_key , url:imageFiles[temp_image.image_index].originalSource}

    }else{

      temp_dialog_state.google_merchant.imageLink = {aws_key:  imageFiles[temp_image.image_index].aws_key , url:imageFiles[temp_image.image_index].originalSource}

    }

    set_google_merchant_dialog_state(temp_dialog_state)

    set_selected_variant_image({'variant_index':-1 , 'image_index':-1,'is_dialog_open':false})


  }

  }

  function refresh_state_from_product(product){

    if(product.title){
    settitle(product.title);
    }

    if(product.inventory_sku){
    set_inventory_sku(product.inventory_sku);
    }

    if(product.inventory_barcode){
    set_inventory_barcode(product.inventory_barcode);
    }

    if(product.track_quantity){
    set_track_quantity(product.track_quantity);
    }

    if(product.continue_selling){
    set_continue_selling(product.continue_selling);
    }

    if(product.available_quantity){
    set_available_quantity(product.available_quantity);
    }

    if(product.shipping_is_physical){
    set_shipping_is_physical(product.shipping_is_physical);
    }

    if(product.shipping_weight){
    set_shipping_weight(product.shipping_weight);
    }

    if(product.weight_metric_value){
    set_weight_metric_value(product.weight_metric_value);
    }

    if(product.selected_country){
    set_selected_country(product.selected_country);
    }

    if(product.hs_code){
    set_hs_code(product.hs_code.toString());
    }

    if(product.selected_product_template){
    set_selected_product_template(product.selected_product_template);
    }

    if(product.selected_collection){
    set_selected_collection(product.selected_collection);
    }

    if(product.vendor){
    set_vendor(product.vendor);
    }

    const temp_options = [...product.product_options]

    if(temp_options){

    for (var i=0; i<temp_options.length; i++){

      const temp_time = new Date().getTime().toString() + i.toString();

      if(temp_options[i].option_values){

        for (var z = 0; z < temp_options[i].option_values.length; z++){

          if(!temp_options[i].option_values[z].unique_id){
            temp_options[i].option_values[z].unique_id = temp_time + z.toString()
          }

        }

      }

    }

    set_product_options(temp_options);

    }


    if(product.all_variants){

      const temp_variants = [...product.all_variants]

      temp_variants.forEach((variant) =>{

        temp_options.forEach((option) =>{

          option.option_values.forEach((option_value) => {

            // console.log("matching: ", variant, " : ", option_value)

            if(option_value.value === variant.value){

              // console.log("matched")

              variant.unique_id = option_value.unique_id

            }
  
          })

        })

      })

      // console.log('processed variants were: ', temp_variants)

      set_all_variants(temp_variants);

    }

    if(product.page_title){
    set_page_title(product.page_title);
    }

    if(product.meta_description){
    set_meta_description(product.meta_description);
    }

    if(product.url_handle_text){
    set_url_handle_text(product.url_handle_text);
    }

    if(product.sales_channels_settings){
    set_sales_channels_settings(product.sales_channels_settings);
    }

    if(product.tags){
    set_tags(product.tags);
    }

    if(product.schedule_date){
    set_schedule_date(product.schedule_date);
    }

    if(product.selected_country){
    set_selected_country(product.selected_country);
    }

    if(product.weight_metric_value){
    set_weight_metric_value(product.weight_metric_value);
    }

    if(product.selected_product_template){
    set_selected_product_template(product.selected_product_template);
    }

    if(product.selected_collection){
    set_selected_collection(product.selected_collection);
    }

    if(product.page_title){
    set_page_title(product.page_title);
    }

    if(product.meta_description){
    set_meta_description(product.meta_description);
    }

    if(product.url_handle_text){
    set_url_handle_text(product.url_handle_text);
    }

    if(product.sales_channels_settings){
    set_sales_channels_settings(product.sales_channels_settings);
    }

    // console.log('settings first variants', product.all_variants);

    load_media_files(product.imageFiles, product.all_variants);

    //setMedia(product.media);
    if(product.price){
    set_price(product.price);
    }

    if(product.compare_at_price){
    set_compare_at_price(product.compare_at_price);
    }

    if(product.cost_per_item){
    set_cost_per_item(cost_per_item);
    }

    if(product.charge_tax){
    set_charge_tax(product.charge_tax);
    }

    if(product.tax_code){
    set_tax_code(product.tax_code);
    }

    if(product.product_status != null){
    set_product_status(product.product_status);
    }

    // console.log('custom types were: ' + product.productType + " : " + product.customProductType + " : " + product.standardizedProductType)

    if(product.productType){
      setCustomProductType(product.productType)
    }

    if(product.customProductType){
      setCustomProductType(product.customProductType)
    }


    if(product.standardizedProductType){
      setStandardizedProductType(product.standardizedProductType)
    }

    if(product.productCategory){
      setProductCategory(product.productCategory)
    }

    // console.log('product collections were: ', product.product_collections)

    if(product.product_collections){
      set_product_collections(product.product_collections)
    }

    if(product.google_merchant_checked){
      set_google_merchant_checked(product.google_merchant_checked)
    }

    if(product.selected_options){

      const temp_state = {...deploy_dialog_state}

      temp_state.options.forEach((option) =>{
        if(product.selected_options.indexOf(option.key) > -1){
          option.is_checked = true
        }else{
          option.is_checked = false
        }
      })

      set_deploy_dialog_state(temp_state)


    }

    //set_selected_variant_image(product.selected_variant_image);
    
  }



}

export default React.memo(CreateProduct);

// Create Meta fields specific to store

// Settings, create metafields, assign to products

// change them inside product create

// view files inside app

// 3rd party oauth

// creating product is different from deploying -> select stores, different sales channels for each store, different languages, different templates.

// create product side extras on deployment part instead of creating

// delete collections async

// show delete status and possible errors

// all collections should be app ones

// 16.13.1

// replace reset page with reset state?

// variants check mark error

// tabs for each store -> Information respective store -> variations -> inconsistencies

// everything on edit page -> So edit page becomes -> edit/deploy

// when updating product image file shouldn't be deleted right away

// unecessary aws stored files?

// continue: ck editor incorrect state

// now move to backend

// product type, handler, gift_card should be there

// store variant options separately no /

// Overwrite only new fields, don't overwrite empty fields

// Metafields for different ids for each store


// handle standardized product type


// onclick product open instead of edit/deploy

// variant price sometimes changes to null


// unmounted components check ref?


// collection to join / leave

// delete image from s3 on product delete

// hs_code needs to be string

// handle csv products