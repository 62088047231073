import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Avatar, TextField, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Autocomplete} from '@mui/material';


import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';


import CmsContext from '../../../../store/cms-context';





function CampaignsDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);



  function handle_close() {

    const temp_state = {...props.campaign_dialog_state}

    temp_state.is_dialog_open = false

    props.set_campaign_dialog_state(temp_state)

  }

  console.log('campaign dialog state was: ', props.campaign_dialog_state)

  return (
    <div>
      <Dialog
        open={props.campaign_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}
      >

        <DialogTitle id="alert-dialog-title">
         Campaign
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

              <Grid
              item
              xs={12}
              >

                <div style={{display:'flex'}}>

                <Avatar
                    sx={{
                      height: 84,
                      width: 84
                    }}
                    src="/static/images/campaign.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>

              </div>

              </Grid>

              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth label="Name" InputLabelProps={{shrink:true}} value={props.campaign_dialog_state.campaign.name} onChange={(event) => {const temp_state = {...props.campaign_dialog_state}; temp_state.campaign.name = event.target.value; props.set_campaign_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel id="demo-select-small-label">Account</InputLabel>
                <Select
                  disabled
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  fullWidth
                  value={props.campaign_dialog_state.selected_account}
                  label="Account"
                  onChange={(event) => {const temp_state = {...props.campaign_dialog_state}; temp_state.selected_account = event.target.value; props.set_campaign_dialog_state(temp_state);}}
                >
                  {props.campaign_dialog_state.accounts.map((item, index)=> (
                  <MenuItem
                  key={index} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
                </FormControl>

              </Grid>

                  

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Objective</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Campaign" />}
                  fullWidth
                  value={props.campaign_dialog_state.campaign.objective}
                  onChange={(event) => {const temp_state = {...props.campaign_dialog_state}; temp_state.campaign.objective = event.target.value; props.set_campaign_dialog_state(temp_state);}}
                >
                  {props.campaign_dialog_state.all_objectives.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}</MenuItem>
                ))}
                </Select>
                </FormControl>

              </Grid>

              { props.campaign_dialog_state.all_funding_instruments.length > 0 ? <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Funding Instrument</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Funding Instrument" />}
                  fullWidth
                  value={props.campaign_dialog_state.campaign.funding_instrument}
                  onChange={(event) => {const temp_state = {...props.campaign_dialog_state}; temp_state.campaign.funding_instrument = event.target.value; props.set_campaign_dialog_state(temp_state);}}
                >
                  {props.campaign_dialog_state.all_funding_instruments.map((item, index)=> (
                  <MenuItem
                  key={index} value={item.id}>{item.name}</MenuItem>
                ))}
                </Select>
                </FormControl>
                
              </Grid>

              : null

              }

                <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Goal</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Goal" />}
                  fullWidth
                  value={props.campaign_dialog_state.campaign.goal_type}
                  onChange={(event) => {const temp_state = {...props.campaign_dialog_state}; temp_state.campaign.goal_type = event.target.value; props.set_campaign_dialog_state(temp_state);}}
                >
                  {props.campaign_dialog_state.all_goal_types.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}
                  </MenuItem>
                ))}
                </Select>
                </FormControl>

              </Grid>

             { props.campaign_dialog_state.campaign.goal_type && props.campaign_dialog_state.campaign.goal_type !== "None" ?
              
              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth type="number" label="Goal Value" InputLabelProps={{shrink:true}} value={props.campaign_dialog_state.campaign.goal_value} onChange={(event) => {const temp_state = {...props.campaign_dialog_state}; temp_state.campaign.goal_value = event.target.value; props.set_campaign_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>

             :

             null
             
             
             }

            { props.campaign_dialog_state.campaign ?
              
              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth type="number" label="Spend Cap" InputLabelProps={{shrink:true}} value={props.campaign_dialog_state.campaign.spend_cap} onChange={(event) => {const temp_state = {...props.campaign_dialog_state}; temp_state.campaign.spend_cap = event.target.value; props.set_campaign_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>

             : null
             
             }

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">View Through Conversion Type</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="View Through Conversion Type" />}
                  fullWidth
                  value={props.campaign_dialog_state.campaign.view_through_conversion_type}
                  onChange={(event) => {const temp_state = {...props.campaign_dialog_state}; temp_state.campaign.view_through_conversion_type = event.target.value; props.set_campaign_dialog_state(temp_state);}}
                >
                  {props.campaign_dialog_state.all_view_through_conversion_types.map((item, index)=> (

                  <MenuItem
                  key={index} value={item}>{item}
                  </MenuItem>

                ))}
                </Select>
                </FormControl>
                
              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Status</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Status" />}
                  fullWidth
                  value={props.campaign_dialog_state.campaign.status}
                  onChange={(event) => {const temp_state = {...props.campaign_dialog_state}; temp_state.campaign.status = event.target.value; props.set_campaign_dialog_state(temp_state);}}
                >
                  {props.campaign_dialog_state.all_statuses.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}</MenuItem>
                ))}
                </Select>
                </FormControl>

              </Grid> 

              
            </Grid>

        </DialogContent>
        <DialogActions>
        <Button disabled={props.campaign_dialog_state.is_dialog_loading} onClick={() => {props.post_campaign(props.campaign_dialog_state)}} variant='outlined'>{props.campaign_dialog_state.type} </Button>
        <Button onClick={handle_close} variant='outlined'>{"Close"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

export default React.memo(CampaignsDialog);