import React,  { useContext, useState, useEffect, useRef, useMemo } from 'react';


import { Box, InputLabel, FormControl, Select, MenuItem, Container, Grid, Card, Button, CircularProgress, Tabs, Tab} from '@mui/material';


import CmsContext from '../../../store/cms-context';


import {getAccessJwtToken } from '../../../store/globalUtilities';

import CreateProduct from '../CreateProduct/CreateProduct';

import cloneDeep from 'lodash/cloneDeep';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';




function DeployProducts(props) {

    const cmsContext = useContext(CmsContext);

    const isMountedRef = useRef(null);

    const [selectedStores, setSelectedStores] = useState([]);

    const [mainLoading, setMainLoading] = useState(false);

    //const [all_variations, set_all_variations] = React.useState([]);


    const [variation, set_variation] = useState({});


    //console.log('deploy rendered')


    const handleChange = (event) => {
      
      const {
        target: { value },
      } = event;
      setSelectedStores(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    useEffect(()=>{

      isMountedRef.current = true
  
      get_product_data(props.product_type)
  
      return () => isMountedRef.current = false;
  
    },[])






    return(

      <>

     {mainLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> : variation && Object.keys(variation).length > 0  ? 
     
     <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
          
         {/* <Grid
          container
          spacing={3}
          alignItems="center"
          style={{padding:20}}
        >

          <Grid
            item
            xs={12}
            
          >

          <Card elevation={2} style={{padding:20,overflow:'visible'}} >

          <FormControl  sx={{ width: '100%' }}>
            
            <InputLabel id="product-deploy-select-stores">Stores</InputLabel>
            
            <Select
              labelId="product-deploy-select-autowidth-label"
              id="product-deploy-select-autowidth"
              autoWidth
              multiple
              value={selectedStores}
              onChange={handleChange}
              label="Stores"
            >
            
            {
              cmsContext.current_shop.connected_stores.map((store, index) => {
            
                return(<MenuItem value={index} key={index}>{store.name}</MenuItem>)
            
              })
            }
              
            </Select>
      
      </FormControl>


          </Card>

        </Grid>

          </Grid> */}


         {/*<Product type="Deploy" index={selected_tab} variation = {cloneDeep(all_variations[selected_tab])} handle_product_change={handle_product_change}></Product>*/}

        

        {/*all_variations.map((variation, index) => {

          return(<CreateProduct get_product_data={get_product_data} master_product={all_variations[0]} reset_all_with_master={reset_all_with_master} key={index} is_enabled={index === props.selected_tab} product_type={props.product_type} type="Deploy" variation = {variation}>
      
          </CreateProduct>)


        })*/}

      {variation && Object.keys(variation).length > 0 ? <CreateProduct set_component_state={props.set_component_state} get_product_data={get_product_data} variation={variation} product_type={props.product_type} type="Deploy">
      
      </CreateProduct>: null}


       

      
      {/*selectedStores.map((store, index) => {
      
          return(<CreateProduct key={index} type="Deploy" store={cmsContext.current_shop.connected_stores[store]} product_data={product_data}>
      
            </CreateProduct>)
        
      
      })*/}

      </Container>

      : null }
      
      </>
      
      )


      function get_product_data(product_type) {


  
        setMainLoading(true)
    
        getAccessJwtToken().then((jwt)=>{
    
          const request_headers = {'store_domain': cmsContext.current_shop.store_domain,
          'Authorization':jwt}
      
          request_headers.product_id = props.product_id

          request_headers.product_type = product_type
    
          fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/create_product_data',{
              crossDomain:true,
              method: 'GET',
              headers:request_headers})
              .then(res => res.json())
              .then(
                (result) => {
      
                  // console.log('result was',result)
                  

                  if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

                  }else{

                  
                  const variation = {"store":props.store, "data": cloneDeep(result.data)}

                  set_variation(variation)

                  /*

                  const variations = temp_data.variations

                  delete temp_data.product

                  delete temp_data.variations

                  // console.log('master was: ', master_product)

                  // console.log('variation was: ', variations)

                  //.product.product_state.initial_product_data = new_data;

                  const temp_stores = cmsContext.current_shop.connected_stores;

                  const temp_variations = [];

                  temp_data.product = master_product

                  temp_variations.push({"store":{"name":"Master","currency":"GBP","store_domain":"store_domain","targetCountry":"GB","google_merchant_available":true}, "data": cloneDeep(temp_data)})

                  var temp_data_variation;

                  temp_stores.forEach((store, index) => {

                    if(variations[store.store_domain]){

                      temp_data_variation = cloneDeep(temp_data);

                      temp_data_variation.product = variations[store.store_domain]

                      temp_variations.push({"store":store, "data": temp_data_variation})

                    }else{

                      temp_variations.push({"store":store, "data": cloneDeep(temp_data)})

                    }

                  })


                  set_all_variations(temp_variations)


                    /*
                  temp_data.publications = temp_data.publications.map((publication) => {
                    return(Object.assign(publication,{'is_checked':false}))
                  }); 
            
                  if(product.publications && product.publications.length > 0){
            
            
                    temp_data.publications.forEach((publication) => {
            
                      product.publications.forEach((published_publication) =>{

                        if(published_publication.id === publication.node.id){
                          publication.is_checked = true
                        }else{
                          // console.log('not equals', publication.node.id, published_publication.id)
                        }

                      })
            
                    })
            
                  }






                const new_data = cloneDeep(temp_data)

                delete new_data.product

                temp_data.product.product_state.initial_product_data = new_data;

                const temp_stores = cmsContext.current_shop.connected_stores;

                const fetched_variations = temp_data.variations;

                const temp_variations = [];

                temp_variations.push({"store":{"name":"Master"}, "data": JSON.parse(JSON.stringify(temp_data))})

                var temp_data_variation;

                temp_stores.forEach((store, index) => {

                  if(fetched_variations[store.store_domain]){

                    temp_data_variation = cloneDeep(temp_data);

                    temp_data_variation.product = fetched_variations[store.store_domain]

                    temp_variations.push({"store":store, "product": temp_data_variation})

                  }else{

                    temp_variations.push({"store":store, "product": JSON.parse(JSON.stringify(temp_data))})

                  }

                })

                // console.log('variations generated', temp_variations)

                set_all_variations(temp_variations)
                */
                

                }

                setMainLoading(false)

      
                },
            
                (error) => {
                  setMainLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                  // console.log('error was',error)
                }
          )
    
        })
    
    
      }

      function reset_all_with_master(){

        /*

        const temp_variations = {...all_variations}

        const new_variations = []

        var master_variation = {}

        var temp_variation = {}

        new_variations.push(cloneDeep(temp_variations[0]))

        for (var i=1; i< temp_variations.length; i++){

          master_variation = cloneDeep(temp_variations[0])

          temp_variation = cloneDeep(temp_variations[i])

          temp_variation.data = master_variation.data

          new_variations.push(temp_variation)

        }

        set_all_variations(new_variations)

        */

      }
    



}


export default DeployProducts;