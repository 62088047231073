import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Chip, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, Card, CardMedia, CardContent, CardActions} from '@mui/material';
import RedditIcon from '@mui/icons-material/Reddit';

import { UserCircle as UserCircleIcon } from '../../../icons/user-circle';



import CmsContext from '../../../store/cms-context';
import { CardCover } from '@mui/joy';




function RedditDialog(props) {
  
  console.log("reddit dialog state: ", props.reddit_dialog_state)

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  function handle_view(){

    console.log(props.reddit_dialog_state.reddit)

    window.open(props.reddit_dialog_state.reddit.permalink_url, "_blank")

  }


  function findHashtags(searchText) {
      var regexp = /\B\#\w\w+\b/g
      var result = searchText.match(regexp);
      if (result) {
          return(result)
      } else {
          return([])
      }
  }

  var hash_tags = [];

  if(props.reddit_dialog_state.reddit.message_tags){

    if(props.reddit_dialog_state.reddit.message_tags.length > 0){
      hash_tags = props.reddit_dialog_state.reddit.message_tags.split(',');
    }else{
      hash_tags = props.reddit_dialog_state.reddit.message_tags
    }

  }


  function handle_close(){

    const temp_state = {...props.reddit_dialog_state}

    temp_state.is_dialog_open = false

    props.set_reddit_dialog_state(temp_state)

  }


  var url = "";

  if(props.reddit_dialog_state.breakdown && props.reddit_dialog_state.breakdown.media_url && props.reddit_dialog_state.breakdown.media_url.length > 0){

    url = props.reddit_dialog_state.breakdown.media_url

  }else if(props.reddit_dialog_state.reddit.full_picture){

    url = props.reddit_dialog_state.reddit.full_picture

  }else{

    url = "/static/images/404-error.png"

  }

  console.log("url was: ", url)

  React.useEffect(() =>{

    console.log("breakdown was: ", props.reddit_dialog_state.breakdown)

  },[props.reddit_dialog_state.breakdown])



  return (
    <div>
      <Dialog
        open={props.reddit_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         Image Stats
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

            <Grid
              item
              xs={12}
             
              >

                <div style={{display:'flex'}}>

                <Avatar
                    sx={{
                      height: 84,
                      width: 84
                    }}
                    src="/static/images/stats.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>

                <Typography style={{marginTop:'auto', marginBottom:4, marginLeft:8}} fontSize={12}><strong>{props.reddit_dialog_state.reddit.username}</strong></Typography>

                
                </div>

              </Grid> 


              {props.reddit_dialog_state.breakdown ? <Grid
              item
              xs={12}>

                <Grid
                container
                spacing={2}
                >

                  <Grid
                  item
                  xs={12}
                  >

                  <Stack
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={1}
                  >

                    <Typography variant='h6' fontSize={20} style={{color:'#4e40e3'}}>Statistics</Typography>

                  </Stack>

                  </Grid>

                  <Grid
                  item
                  xs={12}
                  >

                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      alignItems="center"
                      spacing={1}
                    >

                      {props.reddit_dialog_state.breakdown.spend ? <Typography variant='subtitle2' fontSize={14}>

                        Spend: {props.reddit_dialog_state.breakdown.spend.toFixed(2)}

                      </Typography> : null}

                      {props.reddit_dialog_state.breakdown.conversions ? <Typography variant='subtitle2' fontSize={14}>

                        Conversions: {props.reddit_dialog_state.breakdown.conversions.toFixed(2)}

                      </Typography> : null}

                      {props.reddit_dialog_state.breakdown.cpc ? <Typography variant='subtitle2' fontSize={14}>

                        cpc: {props.reddit_dialog_state.breakdown.cpc.toFixed(2)}

                      </Typography> : null}

                    </Stack>
                  
                </Grid>


                </Grid>


              </Grid> : null}

          

             <Grid
              item
              xs={12}
              >
               {!props.reddit_dialog_state.is_dialog_loading && props.reddit_dialog_state.breakdown ?   <div style={{width:'100%',textAlign:'center'}}>


                {props.reddit_dialog_state.breakdown.type === 'VIDEO' ?

                  <video
                  width="100%"
                  controls
                  autoPlay
                  src={url}
                  >
                  </video> 

                  :

                  <img alt="fb_img" style={{width: '100%'}}
                  src={url}
                  onError={({currentTarget}) => {currentTarget.onerror = null; console.log('image error received'); currentTarget.src = '/static/images/404-error.png';}}
                  >
                  </img>

                }


                </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                  <CircularProgress  />
                </div>
                
                }

              </Grid> 


              {props.reddit_dialog_state.reddit.title && props.reddit_dialog_state.reddit.title.length > 0 ?  <Grid item xs={12}>

              <Typography fontSize={14}><strong>Title</strong></Typography>
              <Typography style={{marginTop:8}} fontSize={13}>{props.reddit_dialog_state.reddit.title}</Typography>

            </Grid> : null}

            {props.reddit_dialog_state.reddit.headline && props.reddit_dialog_state.reddit.headline.length > 0 ?  <Grid item xs={12}>

              <Typography fontSize={14}><strong>Headline</strong></Typography>
              <Typography style={{marginTop:8}} fontSize={13}>{props.reddit_dialog_state.reddit.headline}</Typography>

            </Grid> : null}

            {hash_tags.length > 0 ? <Grid item xs={12}>

            <Typography fontSize={14} style={{marginBottom:8}}><strong>Tags</strong></Typography>
              
              {hash_tags.map((hash_tag, index) =>{
                         return(<Chip key={index} size="small" color="primary" label={hash_tag} style={{marginLeft:4, marginTop:4, marginBottom:4}}></Chip>)
                       })}

            </Grid> : null}


            
            </Grid>


        </DialogContent>
        <DialogActions>
       {/* <Button onClick={handle_view} startIcon={<RedditIcon />} variant='outlined'>{"View"}</Button> : null */}
        <Button onClick={handle_close} variant='outlined'>{"Close"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default RedditDialog;