import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper } from '@mui/material';

import PropTypes from 'prop-types';

import styles from './ListProducts.module.css';

import CmsContext from '../../../store/cms-context';

import ProductsListTable from "./ProductsListTable";

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';
import SelectCsvDialog from "../../SelectCsvDialog/SelectCsvDialog";
import { default_confirm_dialog, default_bulk_tags_dialog, hide_dialog, product_table_sort_options, set_dialog_loading, show_dialog, default_bulk_update_dialog, default_deploy_dialog, default_products_list_table_style_tab, default_minstsoft_options, default_create_product, default_create_folder_dialog, default_mintsoft_product } from "../../../Utilities/Metrics";
import TagsDialog from "./TagsDialog";
import { cloneDeep } from "lodash";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import BulkUpdateDialog from "../../BulkUpdateDialog/BulkUpdateDialog";
import DeployDialog from "../../DeployDialog/DeployDialog";
import CreateProductDialog from "../ProductsStyles/SubComponents/CreateProductDialog";
import MintsoftProductDialog from "../ProductsStyles/SubComponents/MintsoftProductDialog";


function ListProducts(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_products, set_all_products] = React.useState([]);

  const [all_tags, set_all_tags] = React.useState([]);

  const [component_state, set_component_state] = React.useState({layout_type: 'list', product_id : ''});

  const [select_csv_dialog_state, set_select_csv_dialog_state] = React.useState({'is_dialog_open':false,'uploading_csv':false,'csv_files':[]});

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [sort_order, set_sort_order] = React.useState('');

  const [selected_filters, set_selected_filters] = React.useState({"selected_vendors":[], "selected_tags":[],"selected_status":[],"selected_sorts":[],"selected_sku":"","search_value":""});

  const [tags_dialog_state, set_tags_dialog_state] = React.useState(cloneDeep(default_bulk_tags_dialog))

  const [confirm_dialog_state, set_confirm_dialog_state] = React.useState(cloneDeep(default_confirm_dialog))

  const [bulk_update_dialog_state, set_bulk_update_dialog_state] = React.useState(cloneDeep(default_bulk_update_dialog));

  const [bulk_actions_state, set_bulk_actions_state] = React.useState({type:''})

  const [deploy_dialog_state, set_deploy_dialog_state] = React.useState(cloneDeep(default_deploy_dialog))

  const [selected_tab, set_selected_tab] = React.useState(0);

  const [all_stores, set_all_stores] = React.useState([]);

  const [selected_deployment, set_selected_deployment] = React.useState("mintsoft")

  const [mintsoft_options, set_mintsoft_options] = React.useState(default_minstsoft_options)

  const [all_tabs, set_all_tabs] = React.useState([])

  const [create_product_dialog_state, set_create_product_dialog_state] = React.useState(cloneDeep(default_create_product));

  const [mintsoft_product_dialog_state, set_mintsoft_product_dialog_state] = React.useState(cloneDeep(default_mintsoft_product))
  
  const navigate = useNavigate();

  useEffect(()=>{

    isMountedRef.current = true

    const temp_stores = cmsContext.current_shop.connected_stores;


    get_products_list('', default_products_list_table_style_tab)

    return () => isMountedRef.current = false;

  },[])


  useEffect(() =>{

    const temp_stores = cmsContext.current_shop.connected_stores;

    var all_stores = [];

    all_stores.push(default_products_list_table_style_tab)

    all_stores = all_stores.concat(temp_stores)

    set_all_stores(all_stores)

  },[cmsContext.current_shop.connected_stores])


  useEffect(() =>{

    if(selected_deployment === "mintsoft"){
      set_all_tabs(mintsoft_options)
    }else{
      set_all_tabs(all_stores)
    }

  },[selected_deployment])

  

  function handleTabChange(event, newValue) {

    set_selected_tab(newValue)

    get_products_list('', all_tabs[newValue])

  }


  

  function handle_tags_dialog_search_value(new_val){

    const temp_state = {...tags_dialog_state}

    var filteredTags = [...temp_state.all_tags]

    if(new_val.trim().length > 0){

      filteredTags = filteredTags.filter((row) => {
        return row.toLowerCase().includes(new_val.trim().toLowerCase());
      });

    }

    filteredTags = filteredTags.filter((row) => {

      return temp_state.selected_tags.indexOf(row) === -1

    });

    temp_state.search_value = new_val

    temp_state.filtered_tags = filteredTags

    set_tags_dialog_state(temp_state)

  }



  const handle_accepted_csv_files = useCallback(

    (acceptedFiles) => {

    if(acceptedFiles.length === 1) {

      var temp_accepted_files = [...acceptedFiles]

      temp_accepted_files.forEach((file) =>{

        file.modified_name = Date.now().toString() + "_" + file.name.replaceAll(' ', '_')

      })

      // console.log('ct files',temp_accepted_files)

      const temp_state = {...select_csv_dialog_state}

      temp_state.csv_files = acceptedFiles

      set_select_csv_dialog_state(temp_state)


  }

    },[select_csv_dialog_state]);


    function handle_bulk_actions_change(action_value, numSelected) {

      if(numSelected === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please select valid products!")
        return
      }

      // console.log('bulk actions change called: ', action_value)

      if(all_tags != null && (action_value === "add_tags" || action_value === "remove_tags")) {

        // console.log('yes inside')
  
        const temp_state = cloneDeep(default_bulk_tags_dialog)

        temp_state.action_type = action_value
  
        temp_state.all_tags = [...all_tags]

        temp_state.numSelected = numSelected
  
        temp_state.filtered_tags = [...all_tags]
  
        temp_state.is_dialog_open = true
  
        set_tags_dialog_state(temp_state)

        const temp_bulk_state = {...bulk_actions_state}

        temp_bulk_state.type = "tags"

        set_bulk_actions_state(temp_bulk_state)
  
      }else if(action_value === "Active" || action_value === "Draft" || action_value === "Archived") {

        const temp_dialog_state = {...confirm_dialog_state}

        temp_dialog_state.action_type = action_value

        if(action_value === "Active"){
          temp_dialog_state.title = `Set ${numSelected} products as active?`
          temp_dialog_state.body = "Setting products as active will make them available to their selected sales channels and apps."
        }else if(action_value === "Draft"){
          temp_dialog_state.title = `Set ${numSelected} products as draft?`
          temp_dialog_state.body = "Setting products as draft will hide them from all sales channels and apps."
        }else if(action_value === "Archived"){
          temp_dialog_state.title = `Archive ${numSelected} products?`
          temp_dialog_state.body = "Archiving products will hide them from your sales channels and Shopify admin. You’ll find them using the status filter in your product list."
        }

        // console.log('show dialog called')

        show_dialog(temp_dialog_state, set_confirm_dialog_state)

        const temp_bulk_state = {...bulk_actions_state}

        temp_bulk_state.type = "product_status"

        set_bulk_actions_state(temp_bulk_state)

      }else if(action_value === "bulk_update"){

        get_bulk_update_data(numSelected)

      }else if(action_value === "bulk_deploy"){

        const temp_state = cloneDeep(default_deploy_dialog)

        temp_state.schedule_date = new Date()

        temp_state.numSelected = numSelected

        show_dialog(temp_state, set_deploy_dialog_state)

        const temp_bulk_state = {...bulk_actions_state}

        temp_bulk_state.type = "bulk_deploy"

        set_bulk_actions_state(temp_bulk_state)

      }else if(action_value === "duplicate"){
        handle_bulk_actions(action_value )
      }
      
    }

    function handle_confirm_dialog(){

      handle_bulk_actions()
      
    }

    useEffect(() => {

    // console.log('sort order was: ', product_table_sort_options[sort_order])



    set_all_products((previous_products) => {

      if(previous_products !== null){

      const temp_products = [...previous_products]

      if(product_table_sort_options[sort_order] === "Product title A-Z"){

        temp_products.sort(function(first, second) {
          return first.title.localeCompare(second.title)
        });

      }else if(product_table_sort_options[sort_order] === "Product title Z-A"){

        temp_products.sort(function(first, second) {
          return second.title.localeCompare(first.title)
         });

      }
      else if(product_table_sort_options[sort_order] === "Created: oldest first"){

        // console.log('date 1 was: ', )

        temp_products.sort(function(first, second) {
          return( new Date(first.created_at.replace(/-/g, "/")) - new Date(second.created_at.replace(/-/g, "/")))
         });


      }
      else if(product_table_sort_options[sort_order] === "Created: newest first"){

        temp_products.sort(function(first, second) {
          return( new Date(second.created_at.replace(/-/g, "/")) - new Date(first.created_at.replace(/-/g, "/")))
         });

      }else{
        
        temp_products.sort(function(first, second) {
          return first.title.localeCompare(second.title)
        });

      }
  
       // console.log("sorted products were: ", temp_products)
  
       if(temp_products.length > 0){
        return set_all_products(temp_products)
       }

      }

    })


    }, [sort_order])



    useEffect(() => {

      if(component_state.layout_type === "edit" || component_state.layout_type === "deploy"){

        console.log(selected_tab, " : ", selected_deployment)

        if(selected_deployment === "mintsoft"){

          if(selected_tab === 0) {

            console.log('yes inside')
  
            const temp_state = cloneDeep(default_create_product)
  
            const products = all_products.filter((product) => product.id === component_state.product_id)
  
            console.log('products from edit/deploy were: ', products)
  
            const temp_product = cloneDeep(products[0])
  
            temp_state.product = temp_product
  
            temp_state.is_dialog_open = true
  
            temp_state.type = "Deploy"
  
            set_create_product_dialog_state(temp_state)
  
          }else if(selected_tab === 1) {
  
            console.log('yes inside')
  
            const temp_state = cloneDeep(default_mintsoft_product)
  
            const products = all_products.filter((product) => product.id === component_state.product_id)
  
            console.log('products were: ', products)
  
            const temp_product = cloneDeep(products[0])

            if(temp_product.active_from){

              temp_product.active_from = new Date(temp_product.active_from)

            }

            if(temp_product.active_to){

              temp_product.active_to = new Date(temp_product.active_to)

            }
  
  
            temp_state.product = temp_product

            temp_state.is_dialog_open = true
  
            temp_state.type = "Deploy"
          
            set_mintsoft_product_dialog_state(temp_state)
  
          }

        }

      }

    },[component_state])


  return(

    <>

    {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> : component_state.layout_type === "list" || component_state.layout_type === "edit" || component_state.layout_type === "deploy" ? 
    

    <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Products</Typography>

          <div style={{"display":"flex"}}>

          <Button variant="contained" onClick = {() => {show_upload_csv_dialog()}}>Import CSV</Button>

          <Button style={{marginLeft:8}} variant="contained" onClick = {() => {navigate("/products/styles");}}>Create Product</Button>

          </div> 

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

          {all_products && all_products.length > 0 ? <ProductsListTable selected_option={all_tabs[selected_tab]} products_type={selected_tab === 0 ? "Master" : "Variation"} all_tabs={all_tabs} selected_deployment={selected_deployment} selected_tab={selected_tab} handleTabChange={handleTabChange} all_tags={all_tags} set_all_tags={set_all_tags} handle_bulk_actions_change={handle_bulk_actions_change} selected_filters={selected_filters} set_selected_filters={set_selected_filters} get_products_list={get_products_list} sort_order={sort_order} set_sort_order={set_sort_order} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} set_component_state={set_component_state} delete_products={delete_products} all_products={all_products} set_all_products={set_all_products}></ProductsListTable> : null}

        </Grid>

      </Grid>

    </Container>      

    </Box> 
    
    : null} 


    <SelectCsvDialog type={"Products"} upload_csv_files={upload_csv_files} handle_accepted_csv_files={handle_accepted_csv_files} select_csv_dialog_state={select_csv_dialog_state} set_select_csv_dialog_state={set_select_csv_dialog_state}></SelectCsvDialog>

    {tags_dialog_state ? <TagsDialog handle_bulk_actions={handle_bulk_actions} handle_tags_dialog_search_value={handle_tags_dialog_search_value} tags_dialog_state={tags_dialog_state} set_tags_dialog_state={set_tags_dialog_state}></TagsDialog> : null}

    {confirm_dialog_state ? <ConfirmDialog handle_confirm_dialog={handle_confirm_dialog} confirm_dialog_state={confirm_dialog_state} set_confirm_dialog_state={set_confirm_dialog_state}></ConfirmDialog> : null}

    {bulk_update_dialog_state ? <BulkUpdateDialog bulk_update_dialog_state={bulk_update_dialog_state} set_bulk_update_dialog_state={set_bulk_update_dialog_state} handle_bulk_actions={handle_bulk_actions}></BulkUpdateDialog> : null}

    {deploy_dialog_state ? <DeployDialog product_type={selected_tab === 0 ? "Master" : "Variation"} all_stores={cmsContext.current_shop.connected_stores} type="bulk" sendProductRequest={handle_bulk_actions} deploy_dialog_state={deploy_dialog_state} set_deploy_dialog_state={set_deploy_dialog_state}></DeployDialog> : null}

    {create_product_dialog_state ? <CreateProductDialog post_product={post_product} create_product_dialog_state={create_product_dialog_state} set_create_product_dialog_state={set_create_product_dialog_state}></CreateProductDialog> : null} 

    {mintsoft_product_dialog_state ? <MintsoftProductDialog post_product={post_product} mintsoft_product_dialog_state={mintsoft_product_dialog_state} set_mintsoft_product_dialog_state={set_mintsoft_product_dialog_state}></MintsoftProductDialog> : null} 


    </> 

  );



  async function upload_csv_files() {

    if(select_csv_dialog_state.csv_files.length !== 1){

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid file!")

      return

    }


    const temp_state = {...select_csv_dialog_state}

     const file = select_csv_dialog_state.csv_files[0];

    temp_state.uploading_csv = true

    set_select_csv_dialog_state(temp_state)


      var url;

      var key;

    try {

      key = await Storage.put(cmsContext.current_shop.email + "/product_csv/" + file.modified_name, file, {
       contentType: file.type,
      });

  

      url = await Storage.get(key.key, {
        level: 'public',
        bucket: 'snag-cms175447-staging',
        expires:86400,
        region: 'eu-west-2',
      });

      // console.log('file was', file, url);

    } catch (error) {
      cmsContext.setSnackBarOpen(true, "error", error)
    }

    const static_url = url;

    const static_key = key;

    // console.log('static: ', static_url, ' : ', static_key)


    if(static_key && static_key.key){

      const request_body = {}

      request_body.file = {}

      request_body.file.type = "product"
  
      request_body.file.file_type = "csv"
  
      request_body.file.aws_key = static_key.key

      request_body.file.id = file.modified_name
  

      getAccessJwtToken().then((jwt)=>{

        fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/streams', {
            crossDomain:true,
            method: 'POST',
            headers: {
              'email': cmsContext.current_shop.email,
              'Authorization':jwt,
              'content-type':'application/json',
            },
            body: JSON.stringify(request_body)
            })
            .then(res => res.json())
            .then(
              (result) => {
    
                // console.log('result was: ', result)
  
                if(result.message){

                  stop_uploading_products_csv(true)
                  cmsContext.setSnackBarOpen(true, "info", result.message)

                }else if(result.errorMessage){
  
                  stop_uploading_products_csv(false)
                  cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                  
                } else{

                  stop_uploading_products_csv(false)
                  cmsContext.setSnackBarOpen(true, "error", "Error uploading file, please try again")
                  // console.log('Error', result)

                }
    
              },
          
              (error) => {

                stop_uploading_products_csv(false)
                cmsContext.setSnackBarOpen(true, "error", "Error uploading file, please try again")
                // console.log('error was',error)

              }
        )
  
      })

    }else{
      cmsContext.setSnackBarOpen(true, "error", "Error uploading file please try again!")
    }


  }

  function stop_uploading_products_csv(was_success){


    if(was_success){

      set_select_csv_dialog_state({'is_dialog_open':false,'uploading_csv':false,'csv_files':[]})

    }else{

      const temp_state = {...select_csv_dialog_state}

      temp_state.uploading_csv = false

      set_select_csv_dialog_state(temp_state)
      
    }


  }

  async function get_picture_async(key, index){

    const picUrl = await Storage.get(key, {
      level: 'public',
      bucket: 'snag-cms175447-staging',
      expires:86400,
      region: 'eu-west-2',
    });

    // console.log('url was', picUrl)

    set_all_products((previous_products) =>{

      const temp_products = [...previous_products]

      if(index < temp_products.length){
        temp_products[index].imageFiles[0].url = picUrl;
      }

      return(set_all_products(temp_products))

    })

  }


  function show_upload_csv_dialog(){

    const temp_state = {'is_dialog_open':false,'uploading_csv':false,'csv_files':[]}

    temp_state.is_dialog_open = true;

    set_select_csv_dialog_state(temp_state)

  }


  function get_products_list(filters, selected_store) {
    
    // console.log('store_access_token')

    // user api

    // error handling and state managment required
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt) => {

      const headers = {
        'email': cmsContext.current_shop.email,
        'Authorization':jwt,
        'filters':filters,
        'store_domain': selected_store.store_domain
      }
  
      headers.product_type = selected_store.store_domain


      console.log('headers were:', headers)

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products',{
          crossDomain:true,
          method: 'GET',
          headers: headers})
          .then(res => res.json())
          .then(
            (result) => {
  
             setIsLoading(false)
  
             console.log('result was',result)

              if(result.products){

                var temp_products = result.products;

                if(temp_products.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No products yet. Let's create one to get started.")
                  set_all_products([])

                  console.log('setting all_products to 0')
                  return

                }
                
                temp_products = temp_products.map((collection) => {
                  return(Object.assign(collection,{'is_checked':false}))
                });

                temp_products.forEach((product)=>{

                  if(product.variants){
                  product.variants.forEach((variant) =>{
                    variant.is_checked = false
                  })
                }
                })

                set_all_products(temp_products)

                for(var product_index in temp_products){

                  if(temp_products[product_index].imageFiles && temp_products[product_index].imageFiles.length > 0){
                    get_picture_async(temp_products[product_index].imageFiles[0].aws_key, product_index)
                  }

                }
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                // console.log('Error', result)
              }
  
            },

            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              // console.log('error was',error)
            }
      )

    })


  }


  function get_bulk_update_data(numSelected) {
    
    // console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS//bulk_update_data',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'store_domain': cmsContext.current_shop.store_domain,
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              // console.log('result was: ', result)

              if(result.data){

                const temp_dialog_state = cloneDeep(default_bulk_update_dialog)

                temp_dialog_state.is_dialog_open = true
                
                temp_dialog_state.numSelected = numSelected

                temp_dialog_state.templates = result.data.templates

                set_bulk_update_dialog_state(temp_dialog_state)

                const temp_bulk_state = {...bulk_actions_state}

                temp_bulk_state.type = "bulk_update"

                set_bulk_actions_state(temp_bulk_state)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again")
                // console.log('Error', result)
                
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again")
              // console.log('error was',error)
            }
      )

    })


  }

  function delete_products(){

    // console.log('delete products')

    var temp_products = [...all_products].filter((product) => product.is_checked);

    // console.log('filtered were', temp_products)

    if(temp_products.length === 0){
      // might never execute (need to select products to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid products!")
      return;
    }else if(temp_products.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 products!")
      return;
    }

    temp_products = temp_products.map((product) => {

      return product.sku

    })
    

    // console.log('final products were', temp_products)

    const request_body = {}

    request_body.delete_ids = temp_products

    request_body.payload_type = "product_styles_variants"

    request_body.request_type = "Delete"
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/styles',{
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  // console.log('result was',result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_products_list('', all_tabs[selected_tab])
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    // console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  // console.log('error was',error)
                }
          );
  
      });

  }

  function handle_bulk_actions(action_value) {

    const request_body = {}

    request_body.products = [...all_products]

    request_body.products = request_body.products.filter((product) => {
      return product.is_checked === true
    });

    const temp_products = []

    request_body.products.forEach((product) =>{
      temp_products.push(product.id)
    })

    request_body.products = temp_products;

    if(request_body.products.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select valid products!")
      return
    }

    if(action_value && action_value === "duplicate"){

      request_body.request_type = action_value

      setIsLoading(true)

    }else{

    if(bulk_actions_state.type === "tags"){

    const temp_state = {...tags_dialog_state}

    if(temp_state.selected_tags.length === 0 && temp_state.newly_added_tags.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select valid tags!")
      return
    }


    request_body.request_type = bulk_actions_state.type

    request_body.tags = {}

    request_body.tags.all_tags = temp_state.selected_tags.concat(temp_state.newly_added_tags)

    request_body.tags.action_type = temp_state.action_type 

    // console.log('request body is: ', request_body)

    set_dialog_loading(temp_state, set_tags_dialog_state, true)


    }else if(bulk_actions_state.type === "product_status"){

      request_body.request_type = bulk_actions_state.type

      request_body.product_status = {}

      request_body.product_status.status = confirm_dialog_state.action_type

      set_dialog_loading(confirm_dialog_state, set_confirm_dialog_state, true)
      
    }else if(bulk_actions_state.type === "bulk_update"){

      const temp_state = {...bulk_update_dialog_state}

      if(temp_state.selected_options.length === 0 && temp_state.selected_variant_options.length === 0){

        cmsContext.setSnackBarOpen(true, "error", "Please select valid options")
        return

      }

      if(temp_state.selected_options.indexOf("template") > -1 && temp_state.template.trim().length === 0){

        cmsContext.setSnackBarOpen(true, "error", "Please select a valid template")

        return

      }

      if(temp_state.selected_variant_options.indexOf("price") > -1 && temp_state.price.trim().length === 0){

        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid price")

        return

      }

      if(temp_state.selected_variant_options.indexOf("cost_per_item") > -1 && temp_state.cost_per_item.trim().length === 0){

        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid cost per item")

        return

      }

      if(temp_state.selected_variant_options.indexOf("shipping") > -1 && temp_state.shipping_weight.trim().length > 0 && temp_state.weight_metric_value.trim().length === 0){

        cmsContext.setSnackBarOpen(true, "error", "Please select a valid weight metric")

        return

      }

      const temp_data = {}


      temp_state.selected_options.forEach((option) => {
        temp_data[option] = temp_state[option]
      })

      const temp_variant_data = {}

      temp_state.selected_variant_options.forEach((variant_option) => {

        if(variant_option === "quantity"){
          temp_variant_data['track_quantity'] = temp_state['track_quantity']
          temp_variant_data['continue_selling'] = temp_state['continue_selling']
        }

        if(variant_option === "shipping"){
          temp_variant_data['shipping_is_physical'] = temp_state['shipping_is_physical']
          temp_variant_data['shipping_weight'] = temp_state['shipping_weight']
          temp_variant_data['weight_metric_value'] = temp_state['weight_metric_value']
        }

        if(variant_option in temp_state){
          temp_variant_data[variant_option] = temp_state[variant_option]
        }

      })

      request_body.bulk_update_data = temp_data

      request_body.bulk_update_variant_data = temp_variant_data

      request_body.request_type = "bulk_update"

      set_dialog_loading(temp_state, set_bulk_update_dialog_state, true)

    }else if(bulk_actions_state.type === "bulk_deploy"){


      request_body.products = temp_products;

      if(request_body.products.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please select valid products!")
        return
      }

      const temp_state = {...deploy_dialog_state}

      if(temp_state.selected_stores.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please select valid stores!")
        return
      }

      const selected_stores = [];

      temp_state.selected_stores.forEach((store) => {

          selected_stores.push(store.store_domain)

      })

      request_body.selected_stores = selected_stores;

      const selected_options = [];

      temp_state.options.forEach((option) => {

        if(option.is_checked){
          selected_options.push(option.key)
        }

      })

      if(selected_options.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please select at least one component!")
        return
      }

      if(temp_state.product_status.length > 0 && selected_options.indexOf("status") < 0){
        cmsContext.setSnackBarOpen(true, "error", "Please select status checkbox when changing Product status!")
        return
      }

      request_body.selected_options = selected_options

      request_body.schedule_date = temp_state.schedule_date.getTime()

      request_body.product_status = temp_state.product_status

      request_body.request_type = "bulk_deploy"

      request_body.email = cmsContext.current_shop.email

      // console.log('body was: ', request_body)

      set_dialog_loading(temp_state, set_deploy_dialog_state, true)
      
    }else{
      cmsContext.setSnackBarOpen(true,"error",'Invalid action')
      return
    }

    }

    request_body.store_domain = all_stores[selected_tab].store_domain


     //console.log('request body was: ', request_body)
    
  
    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/bulk_actions', {
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
            .then(res => res.json())
              .then(
                (result) => {

              
                  if(action_value && action_value === "duplicate"){
                    setIsLoading(false)
                  }
                  // console.log('result was',result)

                  if(result.message){

                    if(action_value && action_value === "duplicate"){
                      get_products_list('', all_stores[selected_tab])
                    }else{
                    reset_dialogs(bulk_actions_state)
                    }

                    cmsContext.setSnackBarOpen(true, "success", result.message)
                  
                  }else{

                    stop_loading_dialogs(bulk_actions_state)

                   if(result.errorMessage){

                      cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                      
                    }else{

                      cmsContext.setSnackBarOpen(true, "error", "Error with request, please try again!")
                      // console.log('Error', result)

                    }

                }
                  
                },
            
                (error) => {

                  stop_loading_dialogs(bulk_actions_state)
                  
                  cmsContext.setSnackBarOpen(true, "error", "Error with request, please try again!")
                  // console.log('error was',error)

                }
          );
  
      });

  }


  function hide_dialogs(bulk_actions_state){

    if(bulk_actions_state.type === "tags"){

      hide_dialog(tags_dialog_state, set_tags_dialog_state)

    }else if(bulk_actions_state.type === "product_status"){

      hide_dialog(confirm_dialog_state, set_confirm_dialog_state)
      
    }

  }

  function reset_dialogs(bulk_actions_state){

    if(bulk_actions_state.type === "tags"){

      set_tags_dialog_state(cloneDeep(default_bulk_tags_dialog))

    }else if(bulk_actions_state.type === "product_status"){

      set_confirm_dialog_state(cloneDeep(default_confirm_dialog))
      
    }else if(bulk_actions_state.type === "bulk_update"){

      set_bulk_update_dialog_state(cloneDeep(default_bulk_update_dialog))

    }else if(bulk_actions_state.type === "bulk_deploy"){

      const temp_state = cloneDeep(default_deploy_dialog)

      temp_state.schedule_date = new Date()

      set_deploy_dialog_state(temp_state)

    }

  }

  function stop_loading_dialogs(bulk_actions_state){

    if(bulk_actions_state.type === "tags"){

      const temp_state = {...tags_dialog_state}

      temp_state.isLoading = false

      set_tags_dialog_state(temp_state)

    }else if(bulk_actions_state.type === "product_status"){

      const temp_state = {...confirm_dialog_state}

      temp_state.isLoading = false

      set_confirm_dialog_state(temp_state)
      
    }else if(bulk_actions_state.type === "bulk_update"){

      const temp_state = {...bulk_update_dialog_state}

      temp_state.isLoading = false

      set_bulk_update_dialog_state(temp_state)

    }else if(bulk_actions_state.type === "bulk_deploy"){

      const temp_state = {...deploy_dialog_state}

      temp_state.isLoading = false

      set_deploy_dialog_state(temp_state)
      
    }

  }

  function set_product_dialog_loading(is_loading){

    const temp_style_state = {...create_product_dialog_state}

    temp_style_state.isLoading = is_loading

    console.log('new state was: ', temp_style_state)

    set_create_product_dialog_state(temp_style_state)

  }

  function set_mintsoft_dialog_loading(is_loading){

    const temp_style_state = {...mintsoft_product_dialog_state}

    temp_style_state.isLoading = is_loading

    console.log('new state was: ', temp_style_state)

    set_mintsoft_product_dialog_state(temp_style_state)

  }

  function reset_product_dialog_state(){

    set_create_product_dialog_state(cloneDeep(default_create_product))

  }

  function reset_mintsoft_product_dialog_state(){

    set_mintsoft_product_dialog_state(cloneDeep(default_mintsoft_product))

  }

  function post_product(style, request_type, mintsoft_product){

    console.log('updating')

    console.log(all_stores[selected_tab])

    var request_body = {}

    if(selected_deployment === "mintsoft"){

      if(mintsoft_product){

        console.log('mintsoft is loading')

        set_mintsoft_dialog_loading(true)

        request_body.mintsoft_product = mintsoft_product

      }else{

        set_product_dialog_loading(true)

        request_body.all_variants = style.all_variants

        request_body.product  = style.product

      }

    }else{

      console.log("Invalid selected type atm!")
      return

    }

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/create', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt,
            'store_domain':cmsContext.current_shop.store_domain
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                if(mintsoft_product){
                  reset_mintsoft_product_dialog_state()
                }else{
                  reset_product_dialog_state()
                }

                get_products_list('', all_tabs[selected_tab])
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                if(mintsoft_product){
                  set_mintsoft_dialog_loading(false)
                }else{
                  set_product_dialog_loading(false)
                }
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                if(mintsoft_product){
                  set_mintsoft_dialog_loading(false)
                }else{
                  set_product_dialog_loading(false)
                }

              }
  
            },
        
            (error) => {
              if(mintsoft_product){
                set_mintsoft_dialog_loading(false)
              }else{
                set_product_dialog_loading(false)
              }
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })

  }



};


export default ListProducts;