import { Grid, Paper, TextField, FormControlLabel, Checkbox, MenuItem, FormControl, Typography, Select, InputLabel, InputAdornment, Divider} from '@mui/material';


import React from 'react';

import Countries from '../../../../Utilities/Countries'

import SearchIcon from '@mui/icons-material/Search';

import Metrics from '../../../../Utilities/Metrics';



function Shipping(props){

    //console.log('shipping render')


    return(

      <Paper elevation = {2} style={{marginTop:20, padding:20}}>
  
      <Grid
        container
        spacing={3}
        alignItems="center"
      >
        <Grid
          item
          xs={12}
        >

      <Typography variant='h6'>Shipping</Typography>

      </Grid>

      <Grid
          item
          xs={12}
        >


      <FormControlLabel control={<Checkbox checked={props.shipping_is_physical} onChange={(event) => {props.set_shipping_is_physical(event.target.checked)}} />} label="This is a physical product" />

      </Grid>
      

      <Grid
          item
          xs={12}
        >

      <Divider></Divider>

      </Grid>

      {props.shipping_is_physical ? <>

      <Grid
          item
          xs={12}
        >

       <Typography variant = 'subtitle1'>Weight</Typography>

       <Typography variant='body2'>Used to calculate shipping rates at checkout and label prices during fulfillment.</Typography>

  

      </Grid>

      <Grid item 
      xs={12}
      style={{ display: "flex", gap: "0.2rem" }}
      >

      <TextField

      id="weight"
      label="Weight"
      type="number"
      placeholder='0.0'
      inputProps={{step:0.1}} 
      value={props.shipping_weight}
      onChange={(event) => {props.set_shipping_weight(event.target.value)}}
      InputLabelProps={{
        shrink: true,
      }}

      />

    <FormControl  sx={{ minWidth: 100 }}>

      <InputLabel id="demo-simple-select-autowidth-label">Metric</InputLabel>

      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        autoWidth
        value={props.weight_metric_value}
        onChange={(event)=>{props.set_weight_metric_value(event.target.value)}}
        label="Metric"
      >

        {Metrics.map((item, index)=> (
          <MenuItem key={index} value={item}>{item}</MenuItem>
        ))}

        
      </Select>

    </FormControl>

      </Grid>

      <Grid
          item
          xs={12}
        >

      <Divider></Divider>

      </Grid>

      <Grid
          item
          xs={12}
      >

      <Typography variant="subtitle1">Customs Information</Typography>

      <Typography variant='body2'>Customs authorities use this information to calculate duties when shipping internationally. Shown on printed customs forms.</Typography>
        
      <Typography variant='body2' style={{marginTop:16}}>Country/Region of origin</Typography>

      <FormControl size="small" sx={{ minWidth: 200 , marginTop:1.8}}>

      <InputLabel id="select-countries-autowidth-label">Countries</InputLabel>

      <Select
        labelId="select-countries-autowidth-label"
        id="select-countries-autowidth"
        autoWidth
        label="Countries"
        value={props.selected_country}
        onChange={(event) => {props.set_selected_country(event.target.value)}}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          }
        }}
      >

        {Countries.map((country, index) =>(

        <MenuItem value={index} key={index}>{country.country}</MenuItem>
        
        ))}

      </Select>

      </FormControl>

      <Typography variant='body2' style={{marginTop:2}}>In most cases, where the product is manufactured.</Typography>

      
      </Grid>

      <Grid
          item
          xs={12}
      >

      <Typography variant="subtitle1">HS (Harmonized System) code</Typography>

        <TextField
        id="input-with-icon-textfield"
        label="HS Code"
        fullWidth
        placeholder='Search or enter a HS code'
        value={props.hs_code}
        onChange={(event) => {props.set_hs_code(event.target.value)}}
        style={{marginTop:12}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
    
      />

      <Typography variant='body2' style={{marginTop:4}}>Manually enter codes that are longer than 6 numbers.</Typography>

      </Grid>

      </> : null}

    
      </Grid>

      </Paper>


    )




}


export default React.memo(Shipping)