import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Avatar, TextField, Autocomplete} from '@mui/material';


import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../store/cms-context';

import cloneDeep from 'lodash/cloneDeep';

import MintsoftCountries from "../../../../Utilities/MintsoftCountries"
import { parseDouble } from '../../../../Utilities/HelperFunctions';



function StyleDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);

  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_style_state(key, value){
    
    const temp_state = {...props.style_dialog_state}

    temp_state.style[key] = value

    props.set_style_dialog_state(temp_state)
    
  }

  function set_variant_style_state(key, value){
    
    const temp_state = {...props.style_dialog_state}

    temp_state.variant_style[key] = value

    props.set_style_dialog_state(temp_state)
    
  }


  function handle_style() {

    var temp_state = cloneDeep(props.style_dialog_state)  

    console.log("handle style state was: ", temp_state)

    temp_state.style.price_gbp = parseDouble(temp_state.style.price_gbp); 

    temp_state.style.price_eur = parseDouble(temp_state.style.price_eur); 

    temp_state.style.cost_price_eur = parseDouble(temp_state.style.cost_price_eur); 
    
    temp_state.style.price_usd = parseDouble(temp_state.style.price_usd);
    
    temp_state.style.cost_price_usd =  parseDouble(temp_state.style.cost_price_usd);
    
    temp_state.style.price_cad = parseDouble(temp_state.style.price_cad);
    
    temp_state.style.cost_price_cad = parseDouble(temp_state.style.cost_price_cad);

    temp_state.style.price_ausd = parseDouble(temp_state.style.price_ausd);
    
    temp_state.style.cost_price_ausd = parseDouble(temp_state.style.cost_price_ausd);

    const name = temp_state.style.name.trim();

    const colour = temp_state.style.colour.trim();

    const hs_code = temp_state.style.hs_code.trim();

    const customs_description = temp_state.style.customs_description.trim();

    const sage_product_code = temp_state.style.sage_product_code.trim();

    const sage_product_group = temp_state.style.sage_product_group.trim();

    if(name.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid style name!")
      return
    }

    if(colour.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid color!")
      return
    }


    /*

    if(customs_description.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Customs Description!")
      return
    }

    if(Object.keys(temp_state.style.country_of_origin).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Country of Origin!")
      return
    }

    if(hs_code.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid HS Code!")
      return
    }


    if(sage_product_code.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Sage Product Code!")
      return
    }

    if(sage_product_group.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Sage Product Group!")
      return
    }

    */

    if(temp_state.style.price_gbp && temp_state.style.price_gbp < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid GBP Price!")
      return

    }


    if(temp_state.style.price_eur && temp_state.style.price_eur < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid EUR Price!")
      return

    }

    if(temp_state.style.cost_price_eur && temp_state.style.cost_price_eur < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid EUR Cost Price!")
      return

    }

    if(temp_state.style.price_usd && temp_state.style.price_usd < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid USD Price!")
      return

    }

    if(temp_state.style.cost_price_usd && temp_state.style.cost_price_usd < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid USD Cost Price!")
      return

    }

    if(temp_state.style.price_cad && temp_state.style.price_cad < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid CAD Price!")
      return

    }

    if(temp_state.style.cost_price_cad && temp_state.style.cost_price_cad < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid CAD Cost Price!")
      return

    }

    if(temp_state.style.price_ausd && temp_state.style.price_ausd < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid AUSD Price!")
      return

    }

    if(temp_state.style.cost_price_ausd && temp_state.style.cost_price_ausd < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid AUSD Cost Price!")
      return

    }

    temp_state = {'style': temp_state.style}

    console.log("state was: ", temp_state)
    
    props.post_style(temp_state.style, props.style_dialog_state.type)

  }




  function handle_close(){

    const temp_state = {...props.style_dialog_state}

    temp_state.is_dialog_open = false

    props.set_style_dialog_state(temp_state)

  }





  return (
    <div>

      <Dialog
        open={props.style_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.style_dialog_state.type} Style
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar
                    sx={{
                      height: 160,
                      width: 160,
                      ml: 'auto',
                      mr:'auto'
                    }}
                    src="/static/images/prototype.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" disabled={props.style_dialog_state.type === "Update"} label="Name" variant="outlined" value={props.style_dialog_state.style.name} onChange={(event)=>{set_style_state("name", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" label="Colour" variant="outlined" value={props.style_dialog_state.style.colour} onChange={(event)=>{set_style_state("colour", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" label="Customs Description" variant="outlined" value={props.style_dialog_state.style.customs_description} onChange={(event)=>{set_style_state("customs_description", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
              
                <Autocomplete
                    id="country-of-origin"
                    options={MintsoftCountries}
                    getOptionLabel={(option) => option.Name ? option.Name : ""}
                    value={props.style_dialog_state.style.country_of_origin}
                    isOptionEqualToValue={(option, value) => {
                      return value.ID ? value.ID === option.ID : false
                    }}
                    onChange={(event, newValue) => {

                      set_style_state("country_of_origin", newValue)

                    }}
                    renderOption={(props, option, state) => {
                      //console.log('index was: ', props, " : ", option, " : ", state)
                      const key = `listItem-${option.ID}`;
                      return (
                        <li {...props} key={key}>
                          {option.Name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Country Of Origin" placeholder="Country Of Origin" />
                    )}
                />

                </Grid>


              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="HS Code" variant="outlined" value={props.style_dialog_state.style.hs_code} onChange={(event)=>{set_style_state("hs_code", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Price (GBP)" variant="outlined" value={props.style_dialog_state.style.price_gbp} onChange={(event)=>{set_style_state("price_gbp", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>


              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Sage Product Code" variant="outlined" value={props.style_dialog_state.style.sage_product_code} onChange={(event)=>{set_style_state("sage_product_code", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>


              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Sage Product Group" variant="outlined" value={props.style_dialog_state.style.sage_product_group} onChange={(event)=>{set_style_state("sage_product_group", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Price (EUR)" variant="outlined" value={props.style_dialog_state.style.price_eur} onChange={(event)=>{set_style_state("price_eur", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Cost Price (EUR)" variant="outlined" value={props.style_dialog_state.style.cost_price_eur} onChange={(event)=>{set_style_state("cost_price_eur", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Price (USD)" variant="outlined" value={props.style_dialog_state.style.price_usd} onChange={(event)=>{set_style_state("price_usd", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Cost Price (USD)" variant="outlined" value={props.style_dialog_state.style.cost_price_usd} onChange={(event)=>{set_style_state("cost_price_usd", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Price (CAD)" variant="outlined" value={props.style_dialog_state.style.price_cad} onChange={(event)=>{set_style_state("price_cad", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Cost Price (CAD)" variant="outlined" value={props.style_dialog_state.style.cost_price_cad} onChange={(event)=>{set_style_state("cost_price_cad", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Price (AUSD)" variant="outlined" value={props.style_dialog_state.style.price_ausd} onChange={(event)=>{set_style_state("price_ausd", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type="number" label="Cost Price (AUSD)" variant="outlined" value={props.style_dialog_state.style.cost_price_ausd} onChange={(event)=>{set_style_state("cost_price_ausd", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

            </Grid>

            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.style_dialog_state.isLoading} disabled={false} onClick={handle_style}>{props.style_dialog_state.type}</LoadingButton>
        </DialogActions>

      </Dialog>
    </div>
  );
}


export default StyleDialog;