import * as React from 'react';
import { useState , useContext } from 'react';
import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { DashboardSidebar } from '../DashboardSideBar/DashboardSideBar';
import Dashboard from '../Dashboard/Dashboard';
import { DashboardNavbar } from '../DashboardNavBar/DashboardNavBar';
import CmsContext from '../../store/cms-context';
import CollectionEncapsulation from '../CreateCollection/CollectionEncapsulation'
import ListCollections from '../ListCollections/ListCollections'
import FacebookAds from '../FacebookAds/FacebookAds';
import AnalyticsCMS from '../AnalyticsCMS/AnalyticsCMS';
import Instagram from '../Instagram/Instagram';
import FacebookTags from '../FacebookTags/FacebookTags'
import GoogleAds from '../GoogleAds/GoogleAds';
import FacebookSync from '../FacebookSync/FacebookSync';
import GoogleSearch from '../GoogleSearch/GoogleSearch';
import GoogleAdsManageSearch from '../GoogleAdsManageSearch/GoogleAdsManageSearch';
import InstagramShopify from '../InstagramShopify/InstagramShopify';
import ScheduledProducts from '../ScheduledProducts/ScheduledProducts';
import StorageImages from '../StorageImages/StorageImages';
import Shipping from '../Shipping/Shipping';
import ShippingReports from '../ShippingReports/ShippingReports';
import FacebookConfigs from '../FacebookConfigs/FacebookConfigs';
import InventoryReports from '../InventoryReports/InventoryReports';
import PreOrderGoals from '../Shopify/Preorder/PreOrderGoals';
import FacebookCountryBreakDowns from '../FacebookCountryBreakdown/FacebookCountryBreakdowns';
import FacebookAdGroups from '../FacebookAdGroups/FacebookAdGroups';
import BringItBack from '../Shopify/BringItBack/BringItBack';
import BringItBackConfigs from '../Shopify/BringItBack/BringItBackConfigs';
import ProductsSync from '../Shopify/Products/Sync/ProductsSync';
import ProductDiscounts from '../Shopify/Products/Discounts/ProductDiscounts';
import ReviewsAndComments from '../ReviewsAndComments/ReviewsAndComments';
import MintsoftOrderSplits from '../Warehouse/Mintsoft/MintsoftOrderSplits';
import PostPurchase from '../Shopify/Checkouts/PostPurchase/PostPurchase';
import ConversionRates from '../Shopify/Conversion/Rates/ConversionRates';
import MintsoftOrdersSync from '../Warehouse/Orders/Sync/MintsoftOrdersSync';
import Carriers from '../Warehouse/Carriers/Carriers';
import TrustPilotReviewsInvitations from '../Reviews/Mintsoft/TrustPilotReviewsInvitations';
import RedditConfigs from '../Reddit/Configs/RedditConfigs';
import RedditAds from '../Reddit/Ads/RedditAds';
import RedditAdsCountryBreakdowns from '../Reddit/CountryBreakdowns/RedditAdsCountryBreakdowns';
import RedditAdGroups from '../Reddit/RedditAdGroups/RedditAdGroups';
import RedditSync from '../Reddit/RedditSync/RedditSync';
import ListProducts from '../Products/ListProducts/ListProducts';
import ProductEncapsulation from '../Products/CreateProduct/ProductEncapsulation';
import ProductsStyles from '../Products/ProductsStyles/ProductsStyles';
import VariantsStyles from '../Products/ProductsStyles/Variants/VariantsStyles';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DashboardLayout(props) {

  const { type } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const cmsContext = useContext(CmsContext);


  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
         { type === "dashboard" ? <Dashboard></Dashboard> : type === "products" ? <ProductEncapsulation type={'Create'}></ProductEncapsulation> : 
         type === "product_ind_view" ? <ProductEncapsulation type={'independent_view'}></ProductEncapsulation>  : type === "collections" ? 
         <CollectionEncapsulation type="Create"></CollectionEncapsulation> : type === "list_collections" ? <ListCollections></ListCollections> : 
         type === "list_products" ? <ListProducts></ListProducts> : type === "schedule_products" ? <ScheduledProducts></ScheduledProducts> : 
         type === "facebook_ads" ? <FacebookAds></FacebookAds> : type === "analytics_contacts" ? <AnalyticsCMS></AnalyticsCMS> : 
         type === "instagram_tags" ? <Instagram></Instagram> : type === "facebook_tags" ? <FacebookTags></FacebookTags> : 
         type === "facebook_configs" ? <FacebookConfigs></FacebookConfigs> : type === "facebook_sync"  ? <FacebookSync></FacebookSync> : 
         type === "google_ads" ? <GoogleAds></GoogleAds> : type === "google_search" ? <GoogleSearch></GoogleSearch> : 
         type === "google_manage" ? <GoogleAdsManageSearch></GoogleAdsManageSearch> : type === "instagram_shopify" ? <InstagramShopify></InstagramShopify> : 
         type === "storage_images" ? <StorageImages></StorageImages> : type === "shipping_services" ? <Shipping></Shipping> : 
         type === "shipping_reports" ? <ShippingReports></ShippingReports> : type === "product_styles" ? <ProductsStyles></ProductsStyles> : 
         type === "product_variants" ? <VariantsStyles></VariantsStyles> : type === "inventory_reports" ? <InventoryReports></InventoryReports> : 
         type === "shopify_order" ? <PreOrderGoals></PreOrderGoals> : type === "facebook_breakdowns" ? <FacebookCountryBreakDowns></FacebookCountryBreakDowns> : 
         type === "facebook_ad_groups" ? <FacebookAdGroups></FacebookAdGroups> : type === "bring_it_back_targets" ? <BringItBack></BringItBack>: 
         type === "bring_it_back_configs" ? <BringItBackConfigs></BringItBackConfigs> : type === "products_sync" ? <ProductsSync></ProductsSync> : 
         type === "product_discounts" ? <ProductDiscounts></ProductDiscounts> : type === "reviews_comments" ? <ReviewsAndComments></ReviewsAndComments> : 
         type === "mintsoft_order_splits" ? <MintsoftOrderSplits></MintsoftOrderSplits> : type === "post_purchase" ? <PostPurchase></PostPurchase> : 
         type === "shopify_conversion_rates" ? <ConversionRates></ConversionRates> : type === "mintsoft_orders_sync" ? <MintsoftOrdersSync></MintsoftOrdersSync> : 
         type === "carrier_services" ? <Carriers></Carriers> : type === "trust_pilot_reviews_invitations" ? 
         <TrustPilotReviewsInvitations></TrustPilotReviewsInvitations> : type === "reddit_configs" ? <RedditConfigs></RedditConfigs> : 
         type === "reddit_ads" ? <RedditAds></RedditAds> : 
         type === "reddit_ad_groups" ? <RedditAdGroups></RedditAdGroups> :
         type === "reddit_breakdowns" ? <RedditAdsCountryBreakdowns></RedditAdsCountryBreakdowns> :
         type === "reddit_sync" ? <RedditSync></RedditSync> : 
         null
         }
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />

      <Snackbar anchorOrigin={{vertical: "bottom", horizontal: "center"}} open={cmsContext.snackbar_state.is_open} autoHideDuration={3000}  onClose={() =>{cmsContext.setSnackBarOpen(false, null)}} >

        <Alert severity={cmsContext.snackbar_state.severity} sx={ cmsContext.snackbar_state.severity === 'success' ? { width: '100%', background:'#4caf50' } : { width: '100%' } }>
          {cmsContext.snackbar_state.message}
        </Alert>  

      </Snackbar>

    </>
  );
};


export default DashboardLayout;