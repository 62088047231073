import { cloneDeep } from "lodash";

const Metrics = [
    'lb',
    'oz',
    'kg',
    'g'
]

export const ProductStatus = [
    'Active',
    'Draft',
    'Archived'
]

export const ONE_DAY = 86400000;

export const default_conversion_rate = {'is_dialog_open': false,'isLoading': false, 'rate': {'source_currency': '', 'target_currency': '', 'value': 0.0}, 'type':'Create'}

export const default_contact = {is_dialog_open: false, isLoading: false, contact: { email: '', phone: '', notifications: [{ notificationType: '', days: [], hours: [] }]}, type: 'Update' };

export const default_variant_style  = {'variant_id': -1, 'variant_name': '', 'barcode': '', 'sku': '', 'weight': '', 'active_from': new Date(), 'active_to': null}

export const default_product_style = {'is_dialog_open': false, 'editing_variant':-1, 'isLoading':false, 'style': {id: -1, name: '', colour: '', customs_description: '', country_of_origin: {}, hs_code: '', price_gbp: '', sage_product_code: '', sage_product_group: '', price_eur: '', cost_price_eur: '', price_usd: '', cost_price_usd: '', price_cad: '', cost_price_cad: '', price_ausd: '', cost_price_ausd: ''}, 'type':'Create'}

export const default_mintsoft_product = {'is_dialog_open': false, 'isLoading':false, 'product':{id: -1, name: '', colour: '', customs_description: '', country_of_origin: {}, hs_code: '', price_gbp: '', cost_price_gbp: '', sage_product_code: '', sage_product_group: '', price_eur: '', cost_price_eur: '', price_usd: '', cost_price_usd: '', price_cad: '', cost_price_cad: '', price_ausd: '', cost_price_ausd: '', sku: '', barcode: '', weight: '', cost_price: '', size: ''},'type':'Create'}

export const default_create_product = {'is_dialog_open': false, 'isLoading': false, style_id: -1, product: {id: -1, name: '', colour: '', customs_description: '', country_of_origin: {}, hs_code: '', price_gbp: '', cost_price_gbp: '', sage_product_code: '', sage_product_group: '', price_eur: '', cost_price_eur: '', price_usd: '', cost_price_usd: '', price_cad: '', cost_price_cad: '', price_ausd: '', cost_price_ausd: '', sku: '', barcode: '', weight: '', size: ''},'type':'Create'}

export const default_products_list_table_style_tab = {"name":"Products", "currency":"GBP", "store_domain":"style", "targetCountry":"GB", "google_merchant_available":true}
    
export const default_minstsoft_options = [default_products_list_table_style_tab, {"name":"Mintsoft", "currency":"GBP", "store_domain":"mintsoft", "targetCountry":"GB", "google_merchant_available":false}]

export const CollectionConditionKeys = [
    {shopify_key: "TITLE", shopify_rest_key: "title", local_value: "Product title"},
    {shopify_key: "TYPE", shopify_rest_key: "type", local_value: "Product type"},
    {shopify_key: "VENDOR", shopify_rest_key: "vendor", local_value: "Product vendor"},
    {shopify_key: "VARIANT_PRICE", shopify_rest_key: "variant_price", local_value: "Product price"},
    {shopify_key: "TAG", shopify_rest_key: "tag" , local_value: "Product tag"},
    {shopify_key: "VARIANT_COMPARE_AT_PRICE", shopify_rest_key: "variant_compare_at_price", local_value: "Compare at price"},
    {shopify_key: "VARIANT_WEIGHT", shopify_rest_key: "variant_weight", local_value: "Weight"},
    {shopify_key: "VARIANT_INVENTORY", shopify_rest_key: "variant_inventory", local_value: "Inventory stock"},
    {shopify_key: "VARIANT_TITLE", shopify_rest_key: "variant_title", local_value: "Variant's title"}
]

export const CollectionTypes = [
    {shopify_key: "EQUALS", shopify_rest_key: "equals" , local_value: "is equal to"},
    {shopify_key: "NOT_EQUALS", shopify_rest_key: "not_equals", local_value: "is not equal to"},
    {shopify_key: "GREATER_THAN", shopify_rest_key: "greater_than", local_value: "is greater than"},
    {shopify_key: "LESS_THAN", shopify_rest_key: "less_than", local_value: "is less than"},
    {shopify_key: "STARTS_WITH", shopify_rest_key: "starts_with", local_value: "starts with"},
    {shopify_key: "ENDS_WITH", shopify_rest_key: "ends_with", local_value: "ends with"},
    {shopify_key: "CONTAINS", shopify_rest_key: "contains", local_value: "contains"},
    {shopify_key: "NOT_CONTAINS", shopify_rest_key: "not_contains", local_value: "does not contain"},
    {shopify_key: "IS_SET", shopify_rest_key: "is_not_empty", local_value: "is not empty"},
    {shopify_key: "IS_NOT_SET", shopify_rest_key: "is_empty", local_value: "is empty"},
    //{shopify_key: "IS_SET", shopify_rest_key: "is_not_empty", local_value: "is not empty"},
   //{shopify_key: "IS_NOT_SET", shopify_rest_key: "is_empty", local_value: "is empty"}
]

export const key_ui_map = {

    "title": "Product title",
    "type": "Product type",
    "vendor": "Product vendor",
    "variant_price": "Product price",
    "tag" : "Product tag",
    "variant_compare_at_price": "Compare at price",
    "variant_weight":  "Weight",
    "variant_inventory": "Inventory stock",
    "variant_title": "Variant's title",
    "equals": "is equal to",
    "not_equals": "is not equal to",
    "greater_than": "is greater than",
    "less_than": "is less than",
    "starts_with": "starts with",
    "ends_with": "ends with",
    "contains": "contains",
    "not_contains": "does not contain",
  //  "is_not_empty": "is not empty",
   // "is_empty": "is empty"

}

export const key_ui_map_ql = {

    "TITLE": "Product title",
    "TYPE": "Product type",
    "VENDOR": "Product vendor",
    "VARIANT_PRICE": "Product price",
    "TAG" : "Product tag",
    "VARIANT_COMPATE_AT_PRICE": "Compare at price",
    "VARIANT_WEIGHT":  "Weight",
    "VARIANT_INVENTORY": "Inventory stock",
    "VARIANT_TITLE": "Variant's title",
    "EQUALS": "is equal to",
    "NOT_EQUALS": "is not equal to",
    "GREATER_THAN": "is greater than",
    "LESS_THAN": "is less than",
    "STARTS_WITH": "starts with",
    "ENDS_WITH": "ends with",
    "CONTAINS": "contains",
    "NOT_CONTAINS": "does not contain",
    "IS_SET": "is not empty",
    "IS_NOT_SET": "is empty",
  //  "is_not_empty": "is not empty",
   // "is_empty": "is empty"

}

export const default_variant = {
'is_checked':false,
'value':'', 
'imageSrc': '',
'image_alt_text':'',
'price': '',
'quantity': '', 
'sku': '',
'barcode': '',
'cost_per_item': '',
'continue_selling': false,
'shipping_is_physical':false,
'shipping_weight': '',
'weight_metric_value':'',
'selected_country':'',
'hs_code':'',
'inventory_tracker':'',
'fulfillment_service':'',
'compare_at_price':'',
'taxable':true,
'tax_code':'',
'track_quantity':true
}

export const sort_options = [
    "Best selling",
    "Product title A-Z",
    "Product title Z-A",
    "Highest price",
    "Lowest price",
    "Newest",
    "Oldest",
    "Manually"
]

export const product_table_sort_options = [
    "Product title A-Z",
    "Product title Z-A",
    "Created: oldest first",
    "Created: newest first",
    "Updated: oldest first",
    "Updated: newest first"
]



export const default_instagram = {'is_dialog_open': false,'isLoading':false, 'contact':{'email':'','phone':'','daily_sales_email':false, 'daily_sales_whatsapp':false,'hourly_sales_email':false, 'hourly_sales_whatsapp':false},'type':'Create'}


export const instagram_tags = [
    {
          "permalink": "https://www.instagram.com/p/CdyckkbD5eg/",
          "media_url": "https://scontent.cdninstagram.com/v/t51.29350-15/282029445_755766585415780_3282441938861142151_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=GmcmgeGMCccAX-OyqHq&_nc_ht=scontent.cdninstagram.com&edm=AJ7ooaQEAAAA&oh=00_AT8NGbvee2tEHFxod6XNjAVQ__ON_WIwbo9tkvZrCrZCHw&oe=628C545D",
          "timestamp": "2022-05-20T17:27:35+0000",
          "username": "happy_susan_blog",
          "media_type": "CAROUSEL_ALBUM",
          "caption": `Hey lieverds vandaag heb ik zowat de hele dag uitgeput op de bank gehangen. Ik had een vrij onrustige nacht achter de rug en ben pas vanmiddag om twee uur of zo me eens gaan douchen en aankleden😜. Heerlijk zo effe helemaal niks doen en uitrusten. 
    
    Wel ben ik in de namiddag tussen de buien door twee verkochte jurkjes op de post gaan doen. Okay op de terugweg ben ik wel nat geworden😉maar hey lekker aan het ontspullen en anderen blij maken met jurkjes die ik niet meer draag of niet pas😉
    
    Én ik heb ik net maar eens twee paar Snag Tights te koop gezet. Ik vond het zo'n gave prints, maar ik realiseerde me dat ik dat toch te druk vind bij mijn vrolijke Tante Betsy jurkjes. Dus ze mogen iemand anders blij gaan maken. De maat is Short F maar er is niet echt wat kort aan die panty's...
    Ze zijn zelfs langer dan de Pamela Mann panty's waar ik ook nog iemand blij mee wil gaan maken. 
    
    Ik draag al jaren Snag Tights sinds ik eens een paar van Binnie van SoMuch Fashion mocht passen en ik ben sindsdien verkocht. Nooit meer last van panty's die in het kruis afzakken🙌🙌🙌
    En omdat ik ze kan optrekken tot onder mijn bh heb ik dan geen last van gratis extra buikrollen😜🤣
    Maar ja printjes op printjes is toch niet mijn ding...wellicht hou jij er wel van🌈💕🌸💙
    
    Lieverds een hele fijne avond gewenst💃💕😃😘
    
    #happysusanblog
    #365daysofhappytantebetsydresses #happinessinadress #tantebetsyjurk tante_betsy 
    #tantebetsyfans #tantebetsygirls 
    #elkedageentantebetsydag
    #happysusanblogshop  #365dagenontspullen
    #colourfullife #snagtights snagtights #snagtightsarethebest #snagdmme #snagmariecurie 
    #domoreofwhatmakesyouhappy #tantebetsyjurkjestekoop  #tantebetsyfan #tekoopopmarktplaats (link in bio)`,
          "id": "17898310082613044"
    }
]


export const availability_options = [
    'backorder',
    'in stock',
    'limited availability',
    'out of stock',
    'preorder'
]


export const conditions = [
    'new',
    'refurbished',
    'used'
]

export const size_systems = [
    'AU',
    'BR',
    'CN',
    'DE',
    'EU',
    'FR',
    'IT',
    'JP',
    'MEX',
    'UK',
    'US'
]


export const energy_efficiencies = [
    'A',
    'A+',
    'A++',
    'A+++',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G'
]

export const length_units = [
    'cm',
    'inch'
]


export const default_installment = { months: "", amount: {value:"", currency:""}}

export const default_subscription_cost = {period: "", periodLength: "", amount: { value: "", currency: "" }}

export const default_loyality_points = {name: "", pointsValue: "", ratio: ""}

export const default_google_merchant_product = {merchant_id:"", targetCountry:"GB", contentLanguage:"en", distination_shopping_ads:true, gtin:'', mpn:'', 'identifierExists':true, id:'', title: '', brand: '', description:'', link: '', mobileLink: '', imageLink: {url:''}, price:{"value": "","currency": ""}, salePrice:{"value": "","currency": ""}, productDetails:[{sectionName: '', attributeName: '', attributeValue: ''}], costOfGoodsSold:{"value": "","currency": ""}, unitPricingMeasure:{"value": "","currency": ""}, unitPricingBaseMeasure:{"value": "","currency": ""}, promotionIds:[''], productTypes:[''], adsRedirect: '', availability:'in stock', availabilityDate:'',expirationDate:'', customLabel0:'', customLabel1:'', customLabel2:'', customLabel3:'', customLabel4:'',condition:'', has_apparel:true, colors:[], sizes:[], genders:[], ageGroups:[], material:[], pattern:[], sizeTypes:[{value:"big", is_checked:false}, {value:"maternity", is_checked:false}, {value:"petite", is_checked:false}, {value:"plus", is_checked:false}, {value:"regular", is_checked:false}, {value:"tall", is_checked:false}], adult:false, multipack:'', isBundle:false, sizeSystem:'', itemGroupId:'', productHighlights:[''], energyEfficiencyClass:'', maxEnergyEfficiencyClass:'', minEnergyEfficiencyClass:'', shippingLabel:'', shippingWeight:{value:"",unit:""}, shippingLength:{value:"",unit:""}, shippingWidth:{value:"",unit:""}, shippingHeight:{value:"",unit:""}, minHandlingTime:"", maxHandlingTime:"", transitTimeLabel:"", additionalImageLinks:[]}

export const default_link_dialog = {is_dialog_open: false, action_type: 'Create', isLoading: false,  merchant_stores: [], selected_merchant_account: {}}

export const default_product_sync_task_dialog = {is_dialog_open: false, action_type: 'Create', isLoading: false,  "all_stores": [], "source": {}, "target_options":["Shopify", "Mintsoft"], "mintsoft_clients": [], "target": {}, "target_type": ""}

export const default_order_sync_task_dialog = {is_dialog_open: false, action_type: 'Create', isLoading: false,  "all_stores": [], "source": {}, "target_options":["Mintsoft"], "mintsoft_clients": [], "target": {}, "target_type": "", "order_prefix": ""}

export const default_gsearch_link_dialog = {is_dialog_open: false, action_type:'Create', isLoading:false,  "search_accounts": [], "selected_search_account":{}}

export const default_insta_shopify_stream_link_dialog = {is_dialog_open: false, number_of_posts:10, action_type: 'Create', isLoading: false, sort_options:['Top','Recent'], sort_type:'',"stream_types": ['Account', 'Hashtag'], 'selected_stream_type': '', 'stream_name': '', 'hash_tag_value': '', "instagram_accounts": [], "shopify_accounts": [], "selected_instagram_account": {}, "selected_shopify_account": {}}

export const default_bulk_tags_dialog = {isLoading:false, is_dialog_open:false, all_tags:[], action_type:'', filtered_tags:[], search_value:'', selected_tags:[], newly_added_tags:[], numSelected:0}

export const default_confirm_dialog = {isLoading:false, is_dialog_open:false, title:'', body:'', action_type:''}

export const default_bulk_update_dialog = {isLoading: false, is_dialog_open: false, numSelected: 0, title: '', body: '', action_type: '', product_status: '', productCategory: '', product_type: '', vendor: '', status: '', templates: [], template: "", url_handle: "", price:"", cost_per_item:"", track_quantity:false, continue_selling:false, quantity:"", shipping_is_physical:false, shipping_weight:"", weight_metric_value:"", options:[{key:"productCategory", title: "Product Category"}, {key:"product_type", title: "Product Type"}, {key:"vendor", title: "Vendor"}, {key:"template", title: "Template"}, {key:"url_handle", title: "URL handle"}], variant_options:[{key:"price", title: "Price"},{key:"cost_per_item", title: "Cost per item"},{key:"quantity", title: "Quantity"},{key:"shipping", title: "Shipping"}],selected_options:[], selected_variant_options:[]}

export const default_deploy_dialog = {isLoading: false, is_dialog_open: false, selected_stores:[], numSelected:0, all_checked: true, product_status:'', schedule_date: new Date() ,options:[{key:"title", title: "Title",is_checked:true}, {key:"description", title: "Description",is_checked:true},{key:"media", title: "Media",is_checked:true}, {key:"status", title: "Status",is_checked:true}, {key:"variants", title: "Variants",is_checked:true}, {key:"seo", title: "SEO",is_checked:true}, {key:"sales_channels", title: "Sales Channels",is_checked:true}, {key:"product_organization", title: "Product organization",is_checked:true}, {key:"tags", title: "Tags",is_checked:true},{key:"online_store", title: "Online Store",is_checked:true}],selected_options:[]}


export const default_shipping_service_dialog = {
    shipping_service:{
    Courier: '',
    Warehouse: '',
    Country: '',
    ParcelifyName: '',
    ServiceName: '',
    ShopifyReference: '',
    Currency: '',
    SnagPriceBase: 0.0,
    SnagPriceVAT: 0.0,
    SnagPricePerKG: 0.0,
    CourierBasePrice: 0.0,
    CourierPerKG: 0.0,
    CourierBasePrice6: 0.0
    },
    isLoading: false,
    is_dialog_open: false,
    numSelected:0,
    all_checked: true
}

export const default_preorder_goal_dialog = {
    preorder_goal:{
    product:'',
    kinds:['preorder'],
    selected_kind: 'preorder',
    warehouses:['livingston', 'shipbob', 'venlo'],
    selected_warehouse: 'venlo',
    variant_name:'',
    variants:[] 
    },
    isLoading: false,
    is_dialog_open: false,
    numSelected:0,
    all_checked: true
}

export const default_mintsoft_order_split_target_dialog = {
    mintsoft_order_split_target:{
    selected_client: '',
    selected_currency: '',
    threshold: 0.0,
    omitted_tags: [],
    omitted_tags_text_field: ''
    },
    isLoading: false,
    is_dialog_open: false
}

export const default_trust_pilot_review_invitation_setting_dialog = {
    invitation_setting:{
    all_business_units: [],
    business_unit: '',
    template_id: '',
    delay: 0,
    reply_to: '',
    preferred_day_time: '',
    preferred_day_time_is_active: true
    },
    isLoading: false,
    is_dialog_open: false
}

export const default_carrier_mapping_dialog = {
    carrier_mapping:{
        created_at: '',
        updated_at: '',
        source: '',
        target: '',
        target_type: '',
        source_carrier: '',
        target_carrier: ''
    },
    carriers_data:{},
    source_shipping_carriers:[],
    target_shipping_carriers:[],
    isLoading: false,
    is_dialog_open: false
}

export const default_bring_it_back_goal_dialog = {
    bring_it_back_goal:{
    product:'',
    quantity:0
    },
    isLoading: false,
    is_dialog_open: false
}

export const default_create_folder_dialog = {isLoading: false, is_dialog_open: false, folder_name: ''};

export const default_deploy_image_dialog = {isLoading: false, is_dialog_open: false, selected_stores:[]};

export const default_ad_group_dialog = {is_dialog_open: false, is_dialog_loading: false, type: "", adgroup: {'name': ''}, ads: [], accounts: [], selected_account: '', selected_campaign: {}, campaigns: [], adsets: [], selected_adset: {}, next_token: '', query_execution_id: '', campaign_input_value: ''}

export const default_reddit_ad_group_dialog = {is_dialog_open: false, is_dialog_loading: false, type: "", adgroup: {'id': ''}, ads: [], accounts: [], selected_account: '', selected_campaign: {}, campaigns: [], adsets: [], selected_adset: {}, next_token: '', query_execution_id: '', campaign_input_value: ''}

export const default_campaign_dialog = {is_dialog_open: false, is_dialog_loading: false, type: "", accounts: [], selected_account: '', campaign: {'name': '', 'objective': 'OUTCOME_SALES', 'status': 'PAUSED', 'special_ad_categories': []}, all_objectives: ['APP_INSTALLS', 'BRAND_AWARENESS', 'CONVERSIONS', 'EVENT_RESPONSES', 'LEAD_GENERATION', 'LINK_CLICKS', 'LOCAL_AWARENESS', 'MESSAGES', 'OFFER_CLAIMS', 'OUTCOME_APP_PROMOTION', 'OUTCOME_AWARENESS', 'OUTCOME_ENGAGEMENT', 'OUTCOME_LEADS', 'OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'PAGE_LIKES', 'POST_ENGAGEMENT', 'PRODUCT_CATALOG_SALES', 'REACH', 'STORE_VISITS', 'VIDEO_VIEWS'], all_statuses: ['ACTIVE', 'PAUSED']}

export const default_adset_dialog = {is_dialog_open: false, is_dialog_loading: false, type: "", accounts: [], selected_account: '', campaigns: [], selected_campaign: {}, adset: {name: '', daily_budget: '1000', billing_event: 'IMPRESSIONS', destination_type: 'WEBSITE', optimization_goal: 'OFFSITE_CONVERSIONS', bid_amount: '100', targeting_countries: [], status: 'PAUSED'}, 'billing_events':['APP_INSTALLS', 'CLICKS', 'IMPRESSIONS', 'LINK_CLICKS', 'NONE', 'OFFER_CLAIMS', 'PAGE_LIKES', 'POST_ENGAGEMENT', 'THRUPLAY', 'PURCHASE', 'LISTING_INTERACTION'], 'optimization_goals':['APP_INSTALLS', 'AD_RECALL_LIFT', 'ENGAGED_USERS', 'EVENT_RESPONSES', 'IMPRESSIONS', 'LEAD_GENERATION', 'QUALITY_LEAD', 'LINK_CLICKS', 'OFFSITE_CONVERSIONS', 'PAGE_LIKES', 'POST_ENGAGEMENT', 'QUALITY_CALL', 'REACH', 'LANDING_PAGE_VIEWS', 'VISIT_INSTAGRAM_PROFILE', 'VALUE', 'THRUPLAY', 'DERIVED_EVENTS', 'APP_INSTALLS_AND_OFFSITE_CONVERSIONS', 'CONVERSATIONS', 'IN_APP_VALUE', 'MESSAGING_PURCHASE_CONVERSION', 'MESSAGING_APPOINTMENT_CONVERSION', 'SUBSCRIBERS', 'REMINDERS_SET'], all_statuses:['ACTIVE', 'PAUSED'], destinations: ['WEBSITE', 'APP', 'MESSENGER', 'INSTAGRAM_DIRECT', 'ON_AD', 'ON_POST', 'ON_VIDEO', 'ON_PAGE', 'ON_EVENT']}

export const default_reddit_campaign_dialog = {is_dialog_open: false, is_dialog_loading: false, type: "", accounts: [], selected_account: '', all_funding_instruments:[], campaign: {name: '', objective: 'CONVERSIONS', funding_instrument: '', goal_type:"None", goal_value: '', status: 'PAUSED', special_ad_categories: [], spend_cap: '', view_through_conversion_type: "None"}, 
all_objectives: ['APP_INSTALLS', 'CATALOG_SALES', 'CLICKS', 'CONVERSIONS', 'REACH', 'IMPRESSIONS', 'LEAD_GENERATION', 'VIDEO_VIEWABLE_IMPRESSIONS'], 
all_goal_types:['None', 'LIFETIME_SPEND', 'DAILY_SPEND'], 
all_statuses: ['ACTIVE', 'PAUSED'],
all_view_through_conversion_types:['None', 'SEVEN_DAY_CLICKS', 'SEVEN_DAY_CLICKS_ONE_DAY_VIEW']}

export const default_reddit_adset_dialog = {
is_dialog_open: false, is_dialog_loading: false, type: "", accounts: [], selected_account: '', campaigns: [],  selected_campaign: {},
keywords_text: '',
excluded_keywords_text: '',
adset: {
name: '', bid_strategy: 'None', bid_type: 'None', bid_value: '', campaign_id: '', status: 'PAUSED', start_time: '', 
end_time: '', goal_type: 'None', goal_value: '', view_through_conversion_type:'', optimization_goal: '',
targeting: {
    carriers:[],
    communities:[],
    excluded_communities: [],
    geolocations:[],
    excluded_geolocations:[],
    devices:[],
    gender: 'None',
    interests:[], 
    excluded_interests:[],
    keywords:[],
    excluded_keywords:[],
    locations: [],
    platforms:[]
},
},
 all_bid_strategies: ['None', 'BIDLESS', 'MANUAL_BIDDING', 'MAXIMIZE_VOLUME'],
 all_bid_types: ['None', 'CPC', 'CPM', 'CPV'],
 all_statuses:['ACTIVE', 'PAUSED'], 
 all_goal_types:['None', 'DAILY_SPEND', 'LIFETIME_SPEND'],
 optimization_goals:['BRAND_AWARENESS', 'REACH', 'TRAFFIC', 'ENGAGEMENT', 'APP_INSTALLS', 'VIDEO_VIEWS', 'CONVERSIONS'],
 targeting_options: {
 carriers:
['ATT_WIRELESS_US', 'SPRINT_US', 'TMOBILE_US', 'VERIZON_US', 'EE_UK', 'VODAFONE_UK', 'O2_UK', 'THREE_UK', 'SKY_MOBILE_UK', 'BT_MOBILE_UK', 'TALKTALK_UK', 
'VIRGIN_MEDIA_UK', 'ROGERS_WIRELESS_CA', 'BELL_MOBILITY_CA', 'VIRGIN_MOBILE_CA', 'BELL_MTS_CA', 'TELUS_MOBILITY_CA', 'SHAW_MOBILE_CA', 'HI3G_ACCESS_AB_SE',
'BOUYGUES_TELECOM', 'FREE_MOBILE_SAS', 'TELE2_SWEDEN', 'SWISSCOM', 'SOUTHERN_PHONE_COMPANY', 'EXETEL', 'TDC_DANMARK', 'ODIDO_NETHERLANDS', 'TELENOR_SVERIGE',
'ICE_NORWAY', 'OPTUS', 'HI3G_ACCESS_AB_DK', 'BELONG', 'ORANGE_BELGIUM', 'O2_DEUTSCHLAND', 'BREDBAND2_AB', 'SFR', 'TELIA_NORGE_AS', 'TELIA_FINLAND', 'ELISA', 
'VODAFONE_AUSTRALIA', 'ELISA_MOBILE', 'TELENOR_AS', 'PROXIMUS', 'DNA_OYJ', 'TELENOR_SVERIGE_AB', 'EWETEL', 'SPINTEL', 'AUSSIE_BROADBAND', 'ZIGGO', 'VODAFONE_GERMANY',
'IINET_LIMITED', 'WILHELMTEL', 'SUPERLOOP', 'TELIA_SE', 'TELSTRA_INTERNET', 'ORANGE', 'TELIA_DK', 'TMOBILE_NETHERLANDS', 'WIRELINE', 'TPG_INTERNET', 'SALT_MOBILE', 'VOO', 
'TELIA_NORGE', 'TELENOR_NORGE', 'TELIA_MOBILE', 'TELENET', 'DEUTSCHE_TELEKOM_AG', 'SUNRISE', 'KPN'],

genders: ['None', 'MALE', 'FEMALE' ],

locations: [
    'FEED',
    'LIGHTBOX_SIDEBAR',
    'MAIN_SIDEBAR',
    'SIDEBARS',
    'COMMENTS_PAGE',
    'TRENDING_DISCOVERY',
    'TRENDING_SEARCH_RESULT',
    'FULL_BLEED_VIDEO_FEED'
],

platforms: [
    'ALL',
    'DESKTOP',
    'DESKTOP_LEGACY',
    'MOBILE_NATIVE',
    'MOBILE_WEB',
    'MOBILE_WEB_3X',
    'SHREDTOP'
]

},

view_through_conversion_types: ['None', 'SEVEN_DAY_CLICKS', 'SEVEN_DAY_CLICKS_ONE_DAY_VIEW']

}

// get all options from api, when creating adset

//export const default_campaign_dialog = {is_dialog_open: false, is_dialog_loading: false, type: "", accounts: [], selected_account: '', selected_campaign: '', campaigns: [], next_token: '', query_execution_id: ''}

export function show_dialog(dialog_state, set_dialog_state){
    const temp_dialog_state = {...dialog_state}
    temp_dialog_state.is_dialog_open = true
    set_dialog_state(temp_dialog_state)
}

export function hide_dialog(dialog_state, set_dialog_state){
    const temp_dialog_state = {...dialog_state}
    temp_dialog_state.is_dialog_open = false
    set_dialog_state(temp_dialog_state)
}

export function set_dialog_loading(dialog_state, set_dialog_state, isLoading){
    const temp_dialog_state = {...dialog_state}
    temp_dialog_state.isLoading = isLoading
    set_dialog_state(temp_dialog_state)
}

export function download_image(file){

    fetch(file.preview)
    .then(res => res.blob())
    .then(blob => {

        try {

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.name); //or any other extension
            document.body.appendChild(link);
            link.click();
            
        } catch (error) {
            console.log("error was: ", error)
        }

    })

}

export default Metrics;