import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Grid, Avatar, TextField} from '@mui/material';

import { UserCircle as UserCircleIcon } from '../../../../../icons/user-circle';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../../store/cms-context';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import DateTimePicker from '@mui/lab/DateTimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';




function StyleDialog(props) {

  const cmsContext = React.useContext(CmsContext);


  function set_style_state(key, value){
    
    const temp_state = {...props.style_dialog_state}

    temp_state.style[key] = value

    props.set_style_dialog_state(temp_state)
    
  }

  function handle_style(){

    const style = {...props.style_dialog_state.style}

    const name = style.variant_name.trim()
    
    const weight = style.weight.trim()


    if(name.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid name!")
      return
    }

    if(weight.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid weight!")
      return
    }

    style.variant_name = name

    style.weight = parseFloat(weight)

    console.log("style was: ", style)

    props.post_style(style, props.style_dialog_state.type)

  }

  

  function handle_close(){

    const temp_state = {...props.style_dialog_state}

    temp_state.is_dialog_open = false

    props.set_style_dialog_state(temp_state)

  }


  return (
    <div>
      <Dialog
        open={props.style_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.style_dialog_state.type} Style
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar
                    sx={{
                      height: 120,
                      width: 120,
                      ml: 'auto',
                      mr:'auto'
                    }}
                    src="/static/images/product_variant.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" disabled={props.style_dialog_state.type === "Update"} label="Name" variant="outlined" value={props.style_dialog_state.style.variant_name} onChange={(event)=>{set_style_state("variant_name", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Weight" variant="outlined" value={props.style_dialog_state.style.weight} type="number" onChange={(event)=>{set_style_state("weight", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>


              <Grid item xs={12}>

                <LocalizationProvider dateAdapter={AdapterDateFns}>

                <DateTimePicker
                  renderInput={(params) => <TextField fullWidth {...params}  />}
                  value={props.style_dialog_state.style.active_from}
                  onChange={(newValue) => {
                    const temp_val = {...props.style_dialog_state};
                    temp_val.style.active_from = newValue;
                    console.log('date was', typeof(temp_val.date))
                    props.set_style_dialog_state(temp_val);
                  }}
                  label="Active From"
                />
                </LocalizationProvider>


              </Grid> 

              <Grid item xs={12}>

              <LocalizationProvider dateAdapter={AdapterDateFns}>

              <DateTimePicker
                renderInput={(params) => <TextField fullWidth {...params}  />}
                value={props.style_dialog_state.style.active_to}
                onChange={(newValue) => {
                  const temp_val = {...props.style_dialog_state};
                  temp_val.style.active_to = newValue;
                  console.log('date was', typeof(temp_val.date))
                  props.set_style_dialog_state(temp_val);
                }}
                label="Active To"
              />
              </LocalizationProvider>

              </Grid> 

            </Grid>
        
        
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.style_dialog_state.isLoading} disabled={false} onClick={handle_style}>{props.style_dialog_state.type}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default StyleDialog;