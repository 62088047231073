import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { OutlinedInput, InputAdornment, ButtonGroup, Button, Stack, Tab, TextField, Grid, Select, MenuItem , Chip, Popper} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';


import cloneDeep from 'lodash/cloneDeep';

function TablePaginationActions(props) {

  const theme = useTheme();
  
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (

    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );

  }


  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'headline',
    numeric: false,
    disablePadding: false,
    label: 'Headline',
  },
  {
    id: 'cpa',
    numeric: false,
    disablePadding: false,
    label: 'cpa',
  },
  {
    id: 'spend',
    numeric: false,
    disablePadding: false,
    label: 'Spend',
  },
  {
    id: 'conversions',
    numeric: false,
    disablePadding: false,
    label: 'Conversions',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={props.check_box_disabled}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
      </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {


  const { numSelected } = props;

  

  return (
    <Toolbar
    disableGutters
      sx={{
        pl: { sm: 2.5 },
        pr: { xs: 1, sm: 1 },
        pt:0,
        pb:0
      }}
    > 



    </Toolbar>
  );
};


EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const temp_styles = theme => ({
    listItemText:{
      fontSize:12
    }
  });


 function RedditAdGroupDialogAdsListTable(props) {

  const theme = useTheme();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  

  const selected = React.useMemo(() => {

    return(props.reddit_ad_group_dialog_state.ads.filter((ad) => ad.is_checked).length)

  }, [props.reddit_ad_group_dialog_state])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_reddit_ad_group_dialog_state((previous_state) => {

      var temp_state = cloneDeep(previous_state)

      temp_state.ads.map((ad) => {

        ad.is_checked = is_checked;
  
        return(ad);

      })
  
      return(props.set_reddit_ad_group_dialog_state(temp_state));

    })

  };



    
  const handle_row_update = (ad_id, type, value, optionNum) => {

   // replace all (don't need this !!!)


    props.set_reddit_ad_group_dialog_state((previous_state) => {

        const temp_state = cloneDeep(previous_state)

        // console.log('temp options', temp_options, 'num: ', optionNum)

        const index = temp_state.ads.findIndex(object => {

          return object.ad_id === ad_id;

        });

        // console.log('found index was: ', index)

        temp_state.ads[index][type] = value;

        return(props.set_reddit_ad_group_dialog_state(temp_state))

      })


  }

  const handle_product_state = (type) => {

    const ad = props.reddit_ad_group_dialog_state.ads.filter((ad) => ad.is_checked === true)

    if(ad.length > 0){

      console.log('ad was: ', ad)

      props.set_component_state({layout_type: type, ad_id: ad[0].id})

    }else{

      console.log("Error getting product")

    }

  }


  useEffect(() => {
    
    if(props.reddit_ad_group_dialog_state.ads && props.reddit_ad_group_dialog_state.ads.length > 0) {

      const filteredRows = [...props.reddit_ad_group_dialog_state.ads]

      setRows(filteredRows);

    }

  }, [props.reddit_ad_group_dialog_state])



  function handleClick(event, index){

    handle_row_update(props.reddit_ad_group_dialog_state.ads[index].ad_id, "is_checked", event.target.checked, index)

  }

  

  return (
    <>
     <Box sx={{ width: '100%'}} style={{marginTop:20}}>

      <Paper sx={{ width: '100%', mb: 2 }}>

        {props.reddit_ad_group_dialog_state.ads.length > 0 ? <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              check_box_disabled={!(props.reddit_ad_group_dialog_state.type === "Edit")}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.reddit_ad_group_dialog_state.ads.length}
            />
            <TableBody>
              {(rows
               ).map((breakdown, index) => {

                var url = "";


                if(breakdown.thumbnail_url && breakdown.thumbnail_url.length > 0) {
                  url = breakdown.thumbnail_url
                }else if(breakdown.full_picture) {
                  url = breakdown.full_picture
                }else {
                 url = "/static/images/photo.png"
                }



              var cpc = "";

              if(breakdown.cpc != null){

                cpc = parseFloat(breakdown.cpc).toFixed(2).toString()

              }

              var spend = "";

              if(breakdown.spend != null){

                spend = parseFloat(breakdown.spend).toFixed(2).toString()

              }

              if(breakdown.spend_company_fx != null){

                spend = parseFloat(breakdown.spend_company_fx).toFixed(2).toString()

              }

              var conversions = "";

              if(breakdown.conversions != null){

                conversions = parseFloat(breakdown.conversions).toFixed(2).toString()

              }



              return(

                <TableRow
                style={{cursor:'pointer'}}
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
                >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={!(props.reddit_ad_group_dialog_state.type === "Edit")}
                          color="primary"
                          checked={breakdown.is_checked}
                          onChange={(event) => {handleClick(event, index)}}
                        />
                      </TableCell>
                      <TableCell align="left">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{border:'1px solid lightgray', width:50, height:50, borderRadius:4}}
                      >
                        <img style={{width: 48, height: 48}} alt="snag" src={url} onError={({currentTarget}) => {currentTarget.onerror=null; console.log('image error received'); currentTarget.src='/static/images/photo.png';}}/>
                      </Box> 
                      </TableCell>

                      <TableCell align="left">
                       <Typography fontSize={12}>{breakdown.headline}</Typography>
                      </TableCell>

                      <TableCell align="left">
                       <Typography fontSize={12}>{cpc}</Typography>
                      </TableCell>

                      <TableCell align="left">
                       <Typography fontSize={12}>{spend}</Typography>
                      </TableCell>

                      <TableCell align="left">
                       <Typography fontSize={12}>{conversions}</Typography>
                      </TableCell>

                    </TableRow>
                    
                  );
                
              })}
              
            </TableBody>
            <TableFooter>

        </TableFooter>
          </Table>
        </TableContainer> : null}
        
      </Paper>
      
    </Box> 
    </>
  );
}


export default React.memo(RedditAdGroupDialogAdsListTable);