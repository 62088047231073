import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Avatar, TextField, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Autocomplete, Typography, Chip} from '@mui/material';


import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';

import CmsContext from '../../../../store/cms-context';

import FBCTCountries from '../../../../Utilities/FBCTCountries';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


function AdsetDialog(props) {

  console.log('AdsetDialog.js', props)

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);


  function handle_close() {

    const temp_state = {...props.adset_dialog_state}

    temp_state.is_dialog_open = false

    props.set_adset_dialog_state(temp_state)

  }
  
  return (
    <div>
      <Dialog
        open={props.adset_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}
      >

        <DialogTitle id="alert-dialog-title">
         Adset
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

              <Grid
              item
              xs={12}
              >

                <div style={{display:'flex'}}>

                <Avatar
                    sx={{
                      height: 84,
                      width: 84
                    }}
                    src="/static/images/bullhorn.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>

              </div>

              </Grid>

              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth label="Name" InputLabelProps={{shrink:true}} value={props.adset_dialog_state.adset.name} onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.name = event.target.value; props.set_adset_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel id="demo-select-small-label">Account</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  disabled
                  fullWidth
                  value={props.adset_dialog_state.selected_account}
                  label="Account"
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.selected_account = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.accounts.map((item, index)=> (
                  <MenuItem
                  key={index} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
                </FormControl>

              </Grid> 


              <Grid item xs={12} sx={{marginTop:1}}>

                <Autocomplete
                    id="selected-campaigns"
                    disabled
                    options={props.adset_dialog_state.campaigns}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    value={props.adset_dialog_state.selected_campaign}
                    isOptionEqualToValue={(option, value) => {
                      return value.id ? value.id === option.id : false
                    }}
                    onChange={(event, newValue) => {
                      const temp_state = {...props.adset_dialog_state}; temp_state.selected_campaign = newValue; props.set_adset_dialog_state(temp_state);
                    }}
                    renderOption={(props, option, state) => {
                      //console.log('index was: ', props, " : ", option, " : ", state)
                      const key = `listItem-${option.id}`;
                      return (
                        <li {...props} key={key}>
                          {option.name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Campaigns" placeholder="Campaigns" />
                    )}
                />

                </Grid>

                <Grid item xs={12} sx={{marginTop:1}}>
                                  
                  <FormControl fullWidth >
                  <InputLabel shrink id="demo-select-small-label">Bid Strategy</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    input={<OutlinedInput notched label="Bid Strategy" />}
                    fullWidth
                    value={props.adset_dialog_state.adset.bid_strategy}
                    onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.bid_strategy = event.target.value; props.set_adset_dialog_state(temp_state);}}
                  >
                    {props.adset_dialog_state.all_bid_strategies.map((item, index)=> (

                      <MenuItem key={index} value={item}>{item}</MenuItem>

                    ))}
                  </Select>
                  </FormControl>
                  
              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
                                  
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Bid Type</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Bid Type" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.bid_type}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.bid_type = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.all_bid_types.map((item, index)=> (

                    <MenuItem key={index} value={item}>{item}</MenuItem>

                  ))}
                </Select>
                </FormControl>
                                  
              </Grid>

              { props.adset_dialog_state.adset.bid_type && props.adset_dialog_state.adset.bid_type !== "None" ?

              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth type='number' label="Bid Value" InputLabelProps={{shrink:true}} value={props.adset_dialog_state.adset.bid_value} onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.bid_value = event.target.value; props.set_adset_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>

              : null}


              <Grid item xs={12} sx={{marginTop:1}}>
                                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Optimization Goal</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Optimization Goal" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.optimization_goal}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.optimization_goal = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.optimization_goals.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}
                  </MenuItem>
                ))}
                </Select>
                </FormControl>
                
              </Grid> 

              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Start Time"
                      value={props.adset_dialog_state.adset.start_time}
                      onChange={(newValue) => {
                        const temp_state = {...props.adset_dialog_state};
                        temp_state.adset.start_time = newValue;
                        props.set_adset_dialog_state(temp_state);
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </LocalizationProvider>

              </Grid>

              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >

              <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="End Time"
                      value={props.adset_dialog_state.adset.end_time}
                      onChange={(newValue) => {
                        const temp_state = {...props.adset_dialog_state};
                        temp_state.adset.end_time = newValue;
                        props.set_adset_dialog_state(temp_state);
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </LocalizationProvider>

              </Grid>

                <Grid item xs={12} sx={{marginTop:1}}>
                                              
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Goal</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Goal" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.goal_type}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.goal_type = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.all_goal_types.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}
                  </MenuItem>
                ))}
                </Select>
                </FormControl>

              </Grid>

              { props.adset_dialog_state.adset.goal_type && props.adset_dialog_state.adset.goal_type !== "None" ?
              
              <Grid
              item
              xs={12}
              sx={{marginTop:1}}
              >
                
                <TextField fullWidth type="number" label="Goal Value" InputLabelProps={{shrink:true}} value={props.adset_dialog_state.adset.goal_value} onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.goal_value = event.target.value; props.set_adset_dialog_state(temp_state);}} variant="outlined" />
                  
              </Grid>

              :

              null
              
              }

              <Grid item xs={12} sx={{marginTop:1}}>
                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">View Through Conversion Type</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="View Through Conversion Type" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.view_through_conversion_type}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.view_through_conversion_type = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.view_through_conversion_types.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}</MenuItem>
                ))}
                </Select>
                </FormControl>
                
              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
                
                <FormControl fullWidth >
                <InputLabel shrink id="demo-select-small-label">Status</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  input={<OutlinedInput notched label="Status" />}
                  fullWidth
                  value={props.adset_dialog_state.adset.status}
                  onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.status = event.target.value; props.set_adset_dialog_state(temp_state);}}
                >
                  {props.adset_dialog_state.all_statuses.map((item, index)=> (
                  <MenuItem
                  key={index} value={item}>{item}</MenuItem>
                ))}
                </Select>
                </FormControl>
                
              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>

                <Typography variant="h6">Targeting Options</Typography>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <Autocomplete
                      multiple
                      id="selected-carriers"
                      options={props.adset_dialog_state.targeting_options.carriers} 
                      value={props.adset_dialog_state.adset.targeting.carriers}
                      onChange={(event, newValue) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting.carriers = newValue; props.set_adset_dialog_state(temp_state);}}
                      renderOption={(props, option, state) => {
                        //console.log('index was: ', props, " : ", option, " : ", state)
                        const key = `listItem-${option}`;
                        return (
                          <li {...props} key={key}>
                            {option}
                          </li>
                        );
                        
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Carriers" />
                      )}
                />     

              </Grid>


             {props.adset_dialog_state && props.adset_dialog_state.targeting_options.targeting_communities ? <Grid item xs={12} sx={{marginTop:1}}>

                <Autocomplete
                    id="selected-communities"
                    multiple
                    options={props.adset_dialog_state.targeting_options.targeting_communities}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    value={props.adset_dialog_state.adset.targeting.communities}
                    isOptionEqualToValue={(option, value) => {
                      return value.id ? value.id === option.id : false
                    }}
                    onChange={(event, newValue) => {
                      const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting.communities = newValue; props.set_adset_dialog_state(temp_state);
                    }}
                    renderOption={(props, option, state) => {
                      //console.log('index was: ', props, " : ", option, " : ", state)
                      const key = `listItem-${option.id}`;
                      return (
                        <li {...props} key={key}>
                          {option.name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Communities" placeholder="Communities" />
                    )}
                />

                </Grid> 

                 : null }


                {props.adset_dialog_state && props.adset_dialog_state.targeting_options.targeting_communities ? <Grid item xs={12} sx={{marginTop:1}}>

                <Autocomplete
                    id="excluded-communities"
                    multiple
                    options={props.adset_dialog_state.targeting_options.targeting_communities}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    value={props.adset_dialog_state.adset.targeting.excluded_communities}
                    isOptionEqualToValue={(option, value) => {
                      return value.id ? value.id === option.id : false
                    }}
                    onChange={(event, newValue) => {
                      const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting.excluded_communities = newValue; props.set_adset_dialog_state(temp_state);
                    }}
                    renderOption={(props, option, state) => {
                      //console.log('index was: ', props, " : ", option, " : ", state)
                      const key = `listItem-${option.id}`;
                      return (
                        <li {...props} key={key}>
                          {option.name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Excluded Communities" placeholder="Excluded Communities" />
                    )}
                />

                </Grid> 

                : null }

              {props.adset_dialog_state && props.adset_dialog_state.targeting_options.targeting_locations ? <Grid item xs={12} sx={{marginTop:1}}>

              <Autocomplete
                  id="selected-locations"
                  multiple
                  options={props.adset_dialog_state.targeting_options.targeting_locations}
                  getOptionLabel={(option) => option.name ? option.name : ""}
                  value={props.adset_dialog_state.adset.targeting.geolocations}
                  isOptionEqualToValue={(option, value) => {
                    return value.id ? value.id === option.id : false
                  }}
                  onChange={(event, newValue) => {
                    const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting.geolocations = newValue; props.set_adset_dialog_state(temp_state);
                  }}
                  renderOption={(props, option, state) => {
                    //console.log('index was: ', props, " : ", option, " : ", state)
                    const key = `listItem-${option.id}`;
                    return (
                      <li {...props} key={key}>
                        {option.name}
                      </li>
                    );
                    
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Geolocations" placeholder="Geolocations" />
                  )}
              />

              </Grid> 

              : null }


              {props.adset_dialog_state && props.adset_dialog_state.targeting_options.targeting_locations ? <Grid item xs={12} sx={{marginTop:1}}>

                <Autocomplete
                    id="selected-locations"
                    multiple
                    options={props.adset_dialog_state.targeting_options.targeting_locations}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    value={props.adset_dialog_state.adset.targeting.excluded_geolocations}
                    isOptionEqualToValue={(option, value) => {
                      return value.id ? value.id === option.id : false
                    }}
                    onChange={(event, newValue) => {
                      const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting.excluded_geolocations = newValue; props.set_adset_dialog_state(temp_state);
                    }}
                    renderOption={(props, option, state) => {
                      //console.log('index was: ', props, " : ", option, " : ", state)
                      const key = `listItem-${option.id}`;
                      return (
                        <li {...props} key={key}>
                          {option.name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Excluded Geolocations" placeholder="Excluded Geolocations" />
                    )}
                />

                </Grid> 

              : null }


                  {props.adset_dialog_state && props.adset_dialog_state.targeting_options.targeting_devices ? <Grid item xs={12} sx={{marginTop:1}}>

                  <Autocomplete
                      id="selected-devices"
                      multiple
                      options={props.adset_dialog_state.targeting_options.targeting_devices}
                      getOptionLabel={(option) => option.model ? option.model : ""}
                      value={props.adset_dialog_state.adset.targeting.devices}
                      isOptionEqualToValue={(option, value) => {
                        return value.model ? value.model === option.model : false
                      }}
                      onChange={(event, newValue) => {
                        const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting.devices = newValue; props.set_adset_dialog_state(temp_state);
                      }}
                      renderOption={(props, option, state) => {
                        //console.log('index was: ', props, " : ", option, " : ", state)
                        const key = `listItem-${option.model}`;
                        return (
                          <li {...props} key={key}>
                            {option.model}
                          </li>
                        );
                        
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Devices" placeholder="Devices" />
                      )}
                  />

                  </Grid> 

                  : null }

                <Grid item xs={12} sx={{marginTop:1}}>
                                
                  <FormControl fullWidth >
                  <InputLabel shrink id="demo-select-small-label">Gender</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    input={<OutlinedInput notched label="Gender" />}
                    fullWidth
                    value={props.adset_dialog_state.adset.targeting.gender}
                    onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting.gender = event.target.value; props.set_adset_dialog_state(temp_state);}}
                  >
                    {props.adset_dialog_state.targeting_options.genders.map((item, index)=> (
                    <MenuItem
                    key={index} value={item}>{item}
                    </MenuItem>
                  ))}
                  </Select>
                  </FormControl>
                
                  </Grid>



              {props.adset_dialog_state && props.adset_dialog_state.targeting_options.targeting_interests ? <Grid item xs={12} sx={{marginTop:1}}>

              <Autocomplete
                  id="selected-interests"
                  multiple
                  options={props.adset_dialog_state.targeting_options.targeting_interests }
                  getOptionLabel={(option) => option.name ? option.name : ""}
                  value={props.adset_dialog_state.adset.targeting.interests}
                  isOptionEqualToValue={(option, value) => {
                    return value.id ? value.id === option.id : false
                  }}
                  onChange={(event, newValue) => {
                    const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting.interests = newValue; props.set_adset_dialog_state(temp_state);
                  }}
                  renderOption={(props, option, state) => {
                    //console.log('index was: ', props, " : ", option, " : ", state)
                    const key = `listItem-${option.id}`;
                    return (
                      <li {...props} key={key}>
                        {option.name}
                      </li>
                    );
                    
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Interests" placeholder="Interests" />
                  )}
              />

              </Grid> 

              : null }


               <Grid item xs={12} sx={{marginTop:1}}>

                <TextField
                label="Keywords"
                variant="outlined"
                value={props.adset_dialog_state.keywords_text}
                onChange={(e) => {const temp_val = {...props.adset_dialog_state}; temp_val.keywords_text = e.target.value; props.set_adset_dialog_state(temp_val);}}
                onKeyPress={(event) => {

                if (event.key === 'Enter') {

                  const temp_state = {...props.adset_dialog_state}
                  const temp_input_val = temp_state.keywords_text

                  if(temp_input_val.trim() !== ''){

                    temp_state.adset.targeting.keywords = [...temp_state.adset.targeting.keywords, temp_input_val.trim()]
                    temp_state.keywords_text = ''

                    props.set_adset_dialog_state(temp_state)

                  }

                }

                }}
                fullWidth
                />

              </Grid> 

                <Grid
                item 
                xs={12}
                >

                {props.adset_dialog_state.adset.targeting.keywords.map((item, index) => { 

                  return <Chip
                  label={item}
                  key={index}
                  sx={{marginLeft:1}}
                  color='primary'
                  onDelete={() => {

                  const temp_state = {...props.adset_dialog_state}
                  temp_state.adset.targeting.keywords = temp_state.adset.targeting.keywords.filter((keyword, keyword_index) => keyword_index !== index)
                  props.set_adset_dialog_state(temp_state)

                  }}
                />

                })}

                </Grid>

                <Grid item xs={12} sx={{marginTop:1}}>

                <TextField
                label="Excluded Keywords"
                variant="outlined"
                value={props.adset_dialog_state.excluded_keywords_text}
                onChange={(e) => {const temp_val = {...props.adset_dialog_state}; temp_val.excluded_keywords_text = e.target.value; props.set_adset_dialog_state(temp_val);}}
                onKeyPress={(event) => {

                if (event.key === 'Enter') {

                  const temp_state = {...props.adset_dialog_state}

                  const temp_input_val = temp_state.excluded_keywords_text

                  if(temp_input_val.trim() !== ''){

                    temp_state.adset.targeting.excluded_keywords = [...temp_state.adset.targeting.excluded_keywords, temp_input_val.trim()]
                    temp_state.excluded_keywords_text = ''
                    props.set_adset_dialog_state(temp_state)

                  }

                }

                }}
                fullWidth
                />

                </Grid> 

                <Grid
                item 
                xs={12}
                >

                {props.adset_dialog_state.adset.targeting.excluded_keywords.map((item, index) => { 

                  return <Chip
                  label={item}
                  key={index}
                  sx={{marginLeft:1}}
                  color='primary'
                  onDelete={() => {

                  const temp_state = {...props.adset_dialog_state}
                  temp_state.adset.targeting.excluded_keywords = temp_state.adset.targeting.excluded_keywords.filter((keyword, keyword_index) => keyword_index !== index)
                  props.set_adset_dialog_state(temp_state)

                  }}
                />

                })}

                </Grid>


                <Grid item xs={12} sx={{marginTop:1}}>
                                
                    <FormControl fullWidth >
                    <InputLabel shrink id="demo-select-small-label">Locations</InputLabel>
                    <Select
                      multiple
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      input={<OutlinedInput notched label="Locations" />}
                      fullWidth
                      value={props.adset_dialog_state.adset.targeting.locations}
                      onChange={(event) => {const temp_state = {...props.adset_dialog_state}; temp_state.adset.targeting.locations = event.target.value; props.set_adset_dialog_state(temp_state);}}
                    >
                      {props.adset_dialog_state.targeting_options.locations.map((item, index)=> (
                      <MenuItem
                      key={index} value={item}>{item}
                      </MenuItem>
                    ))}
                    </Select>
                    </FormControl>
    
                  </Grid>


                  <Grid item xs={12} sx={{marginTop:1}}>
                                
                    <FormControl fullWidth >
                    <InputLabel shrink id="demo-select-small-label">Platforms</InputLabel>
                    <Select
                      multiple
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      input={<OutlinedInput notched label="Platforms" />}
                      fullWidth
                      value={props.adset_dialog_state.adset.targeting.platforms}
                      onChange={(event) => {
                      const temp_state = {...props.adset_dialog_state};
                      const temp_val = event.target.value;
                      var all_found = false

                      var final_values = []

                      temp_val.forEach((item, index) => { 
                        if(item === "ALL"){
                          all_found = true
                        }
                      })

                      console.log('all_found', all_found)

                      if(all_found){
                        props.adset_dialog_state.targeting_options.platforms.forEach((item, index) => {
                          final_values.push(item)
                        });
                      }else{
                        final_values = temp_val
                      }

                      console.log('final_values', final_values)

                      temp_state.adset.targeting.platforms = final_values

                      props.set_adset_dialog_state(temp_state);

                    }}
                    >
                      {props.adset_dialog_state.targeting_options.platforms.map((item, index)=> (
                      <MenuItem
                      key={index} value={item}>{item}
                      </MenuItem>
                    ))}
                    </Select>
                    </FormControl>
    
                  </Grid>           
              
            </Grid>

        </DialogContent>
        <DialogActions>
        <Button disabled={props.adset_dialog_state.is_dialog_loading} onClick={() => {props.post_adset(props.adset_dialog_state)}} variant='outlined'>{props.adset_dialog_state.type}</Button>
        <Button onClick={handle_close} variant='outlined'>{"Close"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

export default React.memo(AdsetDialog);