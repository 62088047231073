import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { OutlinedInput, InputAdornment, ButtonGroup, Button, Stack, Tab, TextField, Grid, Select, MenuItem , Chip} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useTheme } from '@mui/material/styles';
import { key_ui_map } from '../../Utilities/Metrics';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker from '../DatePicker/DatePicker';


function TablePaginationActions(props) {

  const theme = useTheme();
  
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (

    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );

  }


  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [

  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'time',
    numeric: false,
    disablePadding: false,
    label: 'Time',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'username',
  },
  {
    id: 'caption',
    numeric: false,
    disablePadding: false,
    label: 'Caption',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'Tags',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  /*
  pl: { sm: 2 },
     pr: { xs: 1, sm: 1 },
  ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
  })*/




  return (
    <Toolbar
    disableGutters
      sx={{
        pl: { sm: 2.5 },
        pr: { xs: 1, sm: 1 },
        pt:0,
        pb:0
      }}
    > 

      <Grid
      container
      alignItems="center"
      spacing={1}
      sx={{overflow:'visible', marginTop:2, marginBottom:2, marginLeft:{xs: 1, md:0}, marginRight:1}}
      >


      <Grid item xs={6} md={3}>

       <TextField id="outlined-basic" size="small" fullWidth label="Username" variant="outlined" value={props.filter_user_name} onChange={(event)=>{props.set_filter_user_name(event.target.value)}} InputLabelProps={{ shrink: true }} />
       
       </Grid>

       <Grid item xs={6} md={3}>

       <TextField id="outlined-basic" size="small" fullWidth label="Tags" variant="outlined" value={props.filter_tags} onChange={(event)=>{props.set_filter_tags(event.target.value)}} InputLabelProps={{ shrink: true }} />
 
       </Grid>

       <Grid item xs={props.ranges_button_text === "Time range" ? 6 : 10} md={props.ranges_button_text === "Time range" ? 2 : 4}>

        <Button size="small" fullWidth onClick={() => {console.log('clicked');props.set_calendar_open((previous_open) => !previous_open)}} variant="outlined"  endIcon={<CalendarMonthIcon />}>
          {props.ranges_button_text}
        </Button>

        </Grid>

        <Grid item xs={6} md={2}>
 
        <Button variant="contained" fullWidth size="small" onClick={() => {props.perform_search()}}>Search</Button>
 
        </Grid>

        <Grid item xs={6} md={2}> 

        <Button variant="contained" fullWidth size="small" onClick={() => {props.clearSearch()}} >Clear</Button>
 
        </Grid>

      </Grid>




    </Toolbar>

  );

};


EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const temp_styles = theme => ({
    listItemText:{
      fontSize:12
    }
  });


 function ReviewsAndCommentsListTable(props) {

  const theme = useTheme();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search_value, set_search_value] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [all_vendors, set_all_vendors] = React.useState([]);
  const [all_tags, set_all_tags] = React.useState([]);
  const [all_status, set_all_status] = React.useState(['Created', 'Saved', 'Deployed'])
  const [all_sorts, set_all_sorts] = React.useState(['Created', 'Updated', 'Title'])
  const [sku_value, set_sku_value] = React.useState('')


  const [selected_filters, set_selected_filters] = React.useState({"selected_vendors":[], "selected_tags":[],"selected_status":[],"selected_sorts":[]});




  useEffect(() => {

    /*

    const filteredRows = props.all_reviews_and_comments.filter((row) => {
      return row.title.toLowerCase().includes(search_value.toLowerCase());
    });

    const temp_products = [...props.all_reviews_and_comments]

    const found_vendors = []

    temp_products.forEach((product) =>{

      found_vendors.indexOf(product.vendor) === -1 ? found_vendors.push(product.vendor) : console.log("This item already exists");

    })

    set_all_vendors(found_vendors)

    const found_tags = []

    temp_products.forEach((product) =>{

      product.tags.split(", ").forEach((tag) => {

        found_tags.indexOf(tag) === -1 ? found_tags.push(tag) : console.log("This item already exists");

      })

    })

    set_all_tags(found_tags)

    setRows(filteredRows);

    */

  }, [props.all_reviews_and_comments])

  const selected = React.useMemo(() => {

    return(props.all_reviews_and_comments.filter((product) => product.is_checked).length)

  },[props.all_reviews_and_comments])



  console.log('table options were', props.all_reviews_and_comments)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_all_reviews_and_comments((previous_products) => {

      var temp_products = [...previous_products]

      temp_products = temp_products.map((product) => {
  
        product.is_checked = is_checked;
  
        return(product);
  
      })

      console.log('settings products', temp_products)
  
      return(props.set_all_reviews_and_comments(temp_products))

    })

  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleAllVendorsChange = ((event) =>{

    const {
      target: { value },
    } = event;


    //set_selected_filters((previous_filters) =>{

      const temp_filters = {...selected_filters}
      temp_filters.selected_vendors = typeof value === 'string' ? value.split(',') : value
      console.log('setting filters: ', temp_filters)
      set_selected_filters(temp_filters)

    //})

  })

  const handleAllTagsChange = ((event) =>{

    const {
      target: { value },
    } = event;

    //set_selected_filters((previous_filters) =>{

      const temp_filters = {...selected_filters}
      temp_filters.selected_tags = typeof value === 'string' ? value.split(',') : value
       set_selected_filters(temp_filters)

    //})


  })

  const handleAllStatusChange = ((event) => {

    const {
      target: { value },
    } = event;

    //set_selected_filters((previous_filters) =>{

      const temp_filters = {...selected_filters}
      temp_filters.selected_status = typeof value === 'string' ? value.split(',') : value
      set_selected_filters(temp_filters)

   // })


  })

  const handleAllSortsChange = ((event) =>{

    const {
      target: { value },
    } = event;


    //set_selected_filters((previous_filters) =>{

    const temp_filters = {...selected_filters}
    temp_filters.selected_sorts = typeof value === 'string' ? value.split(',') : value
    set_selected_filters(temp_filters)

    //})


  })


  // Avoid a layout jump when reaching the last page with empty rows.

        
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    
  const handle_row_update = (product_id, type, value, optionNum) => {

    if(type === 'price' && value < 0){
      return;
    }

   return props.set_all_reviews_and_comments((previousproducts)=>{

      const temp_options = [...previousproducts];

      console.log('temp options', temp_options,'num: ', optionNum)

      const index = temp_options.findIndex(object => {
        return object.id === product_id;
      });

      temp_options[index][type] = value;

      return(props.set_all_reviews_and_comments(temp_options))

    })

  }

  const handle_product_state = (type) => {

    const product = props.all_reviews_and_comments.filter((product) => product.is_checked === true)

    if(product.length > 0){

      console.log('product was', product)

      props.set_component_state({layout_type: type, product_id : product[0].id})

    }else{
      console.log("Error getting product")
    }

  }


  useEffect(() => {

    console.log('tags list changed', props.all_reviews_and_comments)
    

    if(props.all_reviews_and_comments && props.all_reviews_and_comments.length > 0){

      const filteredRows = [...props.all_reviews_and_comments]
/*
      const filteredRows = props.all_reviews_and_comments.filter((row) => {
        return row.title.toLowerCase().includes(search_value.toLowerCase());
      });

      */

      setRows(filteredRows);

    }

    

  }, [props.all_reviews_and_comments])


  useEffect(() => {

    /*

    if(props.all_reviews_and_comments && props.all_reviews_and_comments.length > 0) {

      const filteredRows = props.all_reviews_and_comments.filter((row) => {

        var found_sku = false;

        for (var i=0; i < row.variants.length; i++){

          if(row.variants[i].sku.toLowerCase().includes(sku_value.toLocaleLowerCase())){
            found_sku = true;
            break;
          }

        }

        return(found_sku)

      });

      setRows(filteredRows);
      
    }
    */

  }, [sku_value])


  useEffect(() => {

    /*

    if(props.all_reviews_and_comments && props.all_reviews_and_comments.length > 0){


      var filteredRows = [...props.all_reviews_and_comments]

      if(selected_filters.selected_vendors.length > 0){

        filteredRows = filteredRows.filter((row) =>{
          return selected_filters.selected_vendors.indexOf(row.vendor) > -1
        })

      }

      if(selected_filters.selected_tags.length > 0){

        filteredRows = filteredRows.filter((row) =>{
          return row.tags.split(", ").join(',').includes(selected_filters.selected_tags.join(','))
        })

      }

      if(selected_filters.selected_status.length > 0){

        console.log('selected status were: ', selected_filters.selected_status)

        filteredRows = filteredRows.filter((row) =>{
          return selected_filters.selected_status.indexOf(row.last_request_status) > -1
        })

        console.log('status filtered rows were: ', filteredRows)

      }

      filteredRows = filteredRows.filter((row) => {
        return row.title.toLowerCase().includes(search_value.toLowerCase());
      });
      

      setRows(filteredRows);

    }

    */

  }, [selected_filters])
  

  function findHashtags(searchText) {
      var regexp = /\B\#\w\w+\b/g
      var result = searchText.match(regexp);
      if (result) {
          return(result)
      } else {
          return([])
      }
  }

  function handleClick(index){

    console.log("index was: ", index)

    props.set_dialog(rows[index], true)

  }

  

  return (
    <>

     <Box sx={{ width: '100%'}} style={{marginTop:12}}>

      <Paper sx={{ width: '100%', mb: 2 }} >

      {/* <Grid
        container
        spacing={2}
        sx={{overflow:'visible', paddingTop:2, paddingLeft:2, paddingRight:2}}
      >

      <Grid
        item
        xs={3}
      >

        <TextField
          id="outlined_search"
          size='small'
          style={{width:'100%'}}
          value={search_value}
          onChange={(event) => {set_search_value(event.target.value)}}
          InputProps={{endAdornment : (
            <InputAdornment position="end">
              <SearchIcon></SearchIcon>
            </InputAdornment>
          ),
          }}
          label="Search"
        />

      </Grid>


      <Grid
        item
        xs={2.5}
      >

        <TextField
          id="outlined_sku"
          size='small'
          style={{width:'100%'}}
          value={sku_value}
          onChange={(event) => {set_sku_value(event.target.value)}}
          InputProps={{endAdornment : (
            <InputAdornment position="end">
              <SearchIcon></SearchIcon>
            </InputAdornment>
          ),
          }}
          label="Sku"
        />

      </Grid>

    

      <Grid
        item
        xs={2}
      >

      <FormControl fullWidth size='small'>
        <InputLabel id="multiple-vendor-label">Vendor</InputLabel>
        <Select
          labelId="multiple-vendor-label"
          id="multiple-vendor-name"
          multiple
          size='small'
          value={selected_filters.selected_vendors}
          label="Vendor"
          style={{'width':'100%'}}
          onChange={(handleAllVendorsChange)}
          input={<OutlinedInput label="Vendor" />}
        >
          {all_vendors.map((vendor, index) => (
            <MenuItem
              key={index}
              value={vendor}
            >
              {vendor}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      <Grid
        item
        xs={1.5}
      >

      <FormControl fullWidth size='small'>
        <InputLabel id="multiple-tags-label">Tags</InputLabel>
        <Select
          labelId="multiple-tags-label"
          id="multiple-tags-name"
          multiple
          size='small'
          value={selected_filters.selected_tags}
          style={{'width':'100%'}}
          onChange={(handleAllTagsChange)}
          input={<OutlinedInput label="Tags" />}
        >
          {all_tags.map((tag, index) => (
            <MenuItem
              key={index}
              value={tag}
            >
              {tag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      <Grid
        item
        xs={1.5}
      >

      <FormControl fullWidth size='small'>
        <InputLabel id="multiple-status-label">Status</InputLabel>
        <Select
          labelId="multiple-status-label"
          id="multiple-status-name"
          multiple
          size='small'
          value={selected_filters.selected_status}
          style={{'width':'100%'}}
          onChange={(handleAllStatusChange)}
          input={<OutlinedInput label="Status" />}
        >
          {all_status.map((status, index) => (
            <MenuItem
              key={index}
              value={status}
            >
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      <Grid
        item
        xs={1.5}
      >

  

          <Button style={{width:'100%'}} variant="contained" onClick={(event) => {set_selected_filters({"selected_vendors":[], "selected_tags":[],"selected_status":[],"selected_sorts":[]});set_search_value("")}}>Clear</Button>
          
      </Grid>

          </Grid>*/}
        
        <EnhancedTableToolbar handle_timestamp_order_change={props.handle_timestamp_order_change} perform_search={props.perform_search} clearSearch={props.clearSearch} ranges_button_text={props.ranges_button_text} filter_user_name={props.filter_user_name} set_filter_user_name={props.set_filter_user_name} filter_tags={props.filter_tags} set_filter_tags={props.set_filter_tags} calendar_open={props.calendar_open} set_calendar_open={props.set_calendar_open} onDateChange={props.onDateChange} timestampOrder={props.timestampOrder} numSelected={selected} delete_products={props.delete_products} handle_product_state={handle_product_state} />
        
        
        { props.all_reviews_and_comments && props.all_reviews_and_comments.length > 0 ? <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_reviews_and_comments.length}
            />
            <TableBody>
              {(rows
               ).map((tag, index) => {

                var hash_tags = [];

                if(tag.caption){
                  hash_tags = findHashtags(tag.caption)
                }

                console.log('hash tags were: ', hash_tags)

                var url = "";

                console.log('tag url was: ', tag)

                if(tag.url && tag.url.length > 0){
                  url = tag.url
                }else if(tag.media_url){
                  url = tag.media_url
                }else{
                 url = "/static/images/photo.png"
                }


              return(

                <TableRow
                      style={{cursor:'pointer'}}
                      hover
                      onClick={(event) => handleClick(index)}
                      role="checkbox"
                      //aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                    // selected={isItemSelected}
                    >
     
                      <TableCell align="left" >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{border:'1px solid lightgray', width:50 ,height:50,borderRadius:4}}
                      >
                         <img style={{width:48,height: 48}} alt="snag" src={url} onError={({currentTarget}) => {currentTarget.onerror=null;console.log('image rror received') ;currentTarget.src='/static/images/photo.png'; }}/>
                      </Box>     
                      </TableCell>
                       <TableCell
                       component="th"
                       //id={labelId}
                       scope="row"
                       style={{minWidth:200}}
                     >
                      <strong>{tag.created_at}</strong>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{tag.consumer_name}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12} style={{width:400}} noWrap>{tag.review_text}</Typography>
                      </TableCell>
                      <TableCell align="left">
                      <div style={{display:'flex',width:200,overflowX:'scroll'}}>
                      {hash_tags.map((hash_tag, index) =>{
                         return(<Chip key={index} color="primary" label={hash_tag} style={{marginLeft:4, marginTop:4, marginBottom:4}}></Chip>)
                       })}
                       </div>
                      </TableCell>
                    </TableRow>
                      
                  );

              })}
              
            </TableBody>
            <TableFooter>
          {/*<TableRow>
            <TablePagination
              rowsPerPageOptions={[ 10, { label: 'All', value: -1 }]}
              colSpan={12}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
            </TableRow>*/ }
        </TableFooter>
          </Table>
        </TableContainer> : null}
    
      </Paper>

      <DatePicker onDateChange={props.onDateChange} calendar_open={props.calendar_open} set_calendar_open={props.set_calendar_open}></DatePicker>
      
    </Box> 
    </>
  );
}


export default React.memo(ReviewsAndCommentsListTable);