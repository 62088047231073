import React, { useContext, useState, useEffect } from 'react';

import { Box, InputLabel, FormControl, Select, MenuItem} from '@mui/material';

import {Route, Routes, useParams} from 'react-router-dom'


import DeployProducts from '../DeployProducts/DeployProducts';
import CmsContext from '../../../store/cms-context';
import CreateProduct from './CreateProduct';



function ProductEncapsulation(props) {

       const { store, product_id } = useParams();

       const cmsContext = useContext(CmsContext);

       const [selected_store, set_selected_store] = React.useState({})

       useEffect(() =>{

        if(store && store.length > 0){

        if(store === "master"){

          console.log('yes master')

          set_selected_store({"name":"Master","currency":"GBP","store_domain":"master","targetCountry":"GB","google_merchant_available":true})

        }else{

        const temp_stores = [...cmsContext.current_shop.connected_stores]

        var temp_selected_store = {}

        temp_stores.forEach((temp_store) =>{
          if(temp_store.store_domain === store){
            temp_selected_store = temp_store
          }
        })

        set_selected_store(temp_selected_store)

        }

      }

       },[cmsContext.current_shop.connected_stores])


      return(
    
    
    
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >

            {props.type === "Create" ? 

            <CreateProduct type="Create" product_type="Master" is_enabled={true}>

            </CreateProduct>

            :props.type === "Edit" ?
            
            <CreateProduct type="Edit" is_enabled={true} product_id={props.product_id} component_state={props.component_state} set_component_state={props.set_component_state}>

            </CreateProduct>

             :

             props.type === "Deploy" ? 

             <DeployProducts type="Deploy" store={props.store} product_type={props.product_type} product_id={props.product_id} component_state={props.component_state} set_component_state={props.set_component_state}></DeployProducts>
             : 

             props.type === "independent_view" && selected_store && Object.keys(selected_store).length > 0 ?
             
             <DeployProducts type="Deploy" store={selected_store} product_type={selected_store.store_domain === "master" ? "Master": "Variation"} product_id={product_id} component_state={props.component_state} set_component_state={props.set_component_state}></DeployProducts>

             : null

            }
    
         
        </Box>
    
      );


}


export default ProductEncapsulation;