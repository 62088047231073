import { Add } from '@mui/icons-material';
import { Grid, Paper, Checkbox, FormControlLabel,Typography, Divider, Box, Button, Snackbar} from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import { cloneDeep } from 'lodash';


import React from 'react';

import { default_variant } from '../../../../Utilities/Metrics';


import NestedList from '../../NestedList/NestedList'


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ProductOptions(props){

    // console.log('product options render',props.product_options)

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    };



    const add_base_option = (checked) => {

      // console.log('checked was',checked)

      if(checked){

      const options = [...props.product_options];

      const temp_option = cloneDeep(default_variant);

      temp_option.unique_id = new Date().getTime().toString();

      options.push({'id': 'option-' + options.length.toString(),'option_name':'', option_values:[temp_option]});

      props.set_product_options(options);

      }else{
        props.set_product_options([]);

      }

    }


    return(

      <Paper  elevation = {2} style={{marginTop:20, padding:20}}>
      
      <Grid
        container
        spacing={3}
        alignItems="center"
      >

        
        
        <Grid
          item
          xs={12}
        >

        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={open} autoHideDuration={5000} onClose={handleClose}>

        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          A maximum of 3 options are allowed by Shopify.
        </Alert>

       </Snackbar>

      <Typography variant='h6'>Options</Typography>

      <FormControlLabel control={<Checkbox checked={props.product_options.length > 0} onChange={ (event) => {add_base_option(event.target.checked)}}/>} label="This product has options, like size or color" />
      
        </Grid>


      <Grid
          item
          xs={12}
        >

      <Divider></Divider>

      </Grid>

      {props.product_options.length > 0 ?

      <>

      <Grid
          item
          xs={12}
        >

      <NestedList product_options={props.product_options} set_product_options = {props.set_product_options}></NestedList>

      <Button startIcon={<Add />} onClick={() => {

        if(props.product_options.length < 3){

        const options = [...props.product_options];

        const temp_option = cloneDeep(default_variant);

        temp_option.unique_id = new Date().getTime().toString();

        options.push({'id': 'option-' + options.length.toString(),'option_name':'', option_values:[temp_option]})

        props.set_product_options(options)

        }else{
          setOpen(true);
        }

      }}>
        Add another option
      </Button>

      </Grid>

      </>

      : null}


      </Grid>
      
      </Paper>

    )



}


export default React.memo(ProductOptions);