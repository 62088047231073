import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../store/cms-context';

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { default_trust_pilot_review_invitation_setting_dialog } from "../../../Utilities/Metrics";

import cloneDeep from 'lodash/cloneDeep';

import InvitationSettingsDialog from "./SubComponents/InvitationSettingsDialog";

import InvitationSettingsTable from "./SubComponents/InvitationSettingsTable";

import CurrenciesInfo from "../../../Utilities/CurrenciesInfo";

import dayjs from 'dayjs';


function TrustPilotReviewsInvitations(props) {

  console.log("Trust Pilot Review Invitations rendered!")

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_business_units, set_all_business_units] = useState([]);

  const [all_invitation_settings, set_all_invitation_settings] = useState([]);

  const [invitation_setting_dialog_state, set_invitation_setting_dialog_state] = React.useState(cloneDeep(default_trust_pilot_review_invitation_setting_dialog));


  useEffect(() => {

    isMountedRef.current = true

    get_all_invitation_settings()

    return () => isMountedRef.current = false;

  }, [])


 

  function show_create_invitation_setting_dialog() {
    
    const temp_dialog_state = cloneDeep(default_trust_pilot_review_invitation_setting_dialog);

    temp_dialog_state.is_dialog_open = true

    temp_dialog_state.invitation_setting.all_business_units = all_business_units

    temp_dialog_state.type = "Create"

    set_invitation_setting_dialog_state(temp_dialog_state);

  }


  const delete_invitation_settings = useCallback(() => {
    
    console.log('delete invitation settings')

    var temp_invitation_settings = [...all_invitation_settings].filter((invitation_setting) => invitation_setting.is_checked);

    console.log('filtered were', temp_invitation_settings)
    
    if(temp_invitation_settings.length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid entry!")
      return;

    }


    temp_invitation_settings = temp_invitation_settings.map((invitation_setting) => {

      return(invitation_setting.business_unit.id)

    })

    console.log('final invitation settings were', temp_invitation_settings)

    const request_body = {}

    request_body.invitation_setting = temp_invitation_settings

    request_body.request_type = "Delete"

    request_body.account_email = cmsContext.current_shop.email

    console.log('delete body was: ', request_body)
    
    setIsLoading(true)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reviews/trustpilot/invitations', {
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was: ', result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_invitation_settings()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {

                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)

                }
          );
  
      });

  }, [all_invitation_settings]);



  const set_dialog_invitation_setting = useCallback((invitation_setting, is_open) => {

      const temp_invitation_setting_dialog_state = cloneDeep(default_trust_pilot_review_invitation_setting_dialog)

      temp_invitation_setting_dialog_state.invitation_setting.all_business_units = all_business_units

      temp_invitation_setting_dialog_state.invitation_setting.business_unit = invitation_setting.business_unit.id

      temp_invitation_setting_dialog_state.invitation_setting.template_id = invitation_setting.template_id

      temp_invitation_setting_dialog_state.invitation_setting.delay = invitation_setting.delay

      temp_invitation_setting_dialog_state.invitation_setting.reply_to = invitation_setting.reply_to

      if( invitation_setting.preferred_day_time != null){

        temp_invitation_setting_dialog_state.invitation_setting.preferred_day_time = dayjs(invitation_setting.preferred_day_time)

      }

      temp_invitation_setting_dialog_state.invitation_setting.preferred_day_time_is_active = invitation_setting.preferred_day_time_is_active

      temp_invitation_setting_dialog_state.is_dialog_open = is_open

      temp_invitation_setting_dialog_state.type = "Update"

      console.log("new state was: ", temp_invitation_setting_dialog_state)

      set_invitation_setting_dialog_state(temp_invitation_setting_dialog_state)

  }, [all_business_units])


  const set_dialog_loading = useCallback(( is_loading) => {

    const temp_invitation_setting_dialog_state = {...invitation_setting_dialog_state}

    temp_invitation_setting_dialog_state.isLoading = is_loading

    console.log('dialog state was: ', temp_invitation_setting_dialog_state)

    set_invitation_setting_dialog_state(temp_invitation_setting_dialog_state)

  },[invitation_setting_dialog_state])



  const reset_dialog_state = useCallback((is_loading) => {

    set_invitation_setting_dialog_state(cloneDeep(default_trust_pilot_review_invitation_setting_dialog))

  },[])





  const post_invitation_setting = useCallback((invitation_setting, request_type) => {

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.invitation_setting = invitation_setting

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reviews/trustpilot/invitations', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was: ', result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_dialog_state()
                
                get_all_invitation_settings()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }, [reset_dialog_state, set_dialog_loading, cmsContext]);







  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px", overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Email Invitations</Typography>

          <div>

          <Button variant="contained" onClick = {() => {show_create_invitation_setting_dialog()}}>Create Target</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >


          {all_invitation_settings ? <InvitationSettingsTable delete_invitation_settings={delete_invitation_settings} set_dialog_invitation_setting={set_dialog_invitation_setting} all_invitation_settings={all_invitation_settings} set_all_invitation_settings={set_all_invitation_settings}>

          </InvitationSettingsTable>  : null}

        </Grid>

      </Grid>

    </Container>      

    </Box>

     }

     {invitation_setting_dialog_state ? <InvitationSettingsDialog all_invitation_settings={all_invitation_settings} post_invitation_setting={post_invitation_setting} invitation_setting_dialog_state={invitation_setting_dialog_state} set_invitation_setting_dialog_state={set_invitation_setting_dialog_state}></InvitationSettingsDialog> : null}

    </> 


  );


  function get_all_invitation_settings() {
    
    console.log('store_access_token')

    setIsLoading(true)

    getAccessJwtToken().then((jwt) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reviews/trustpilot/invitations',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
  
              setIsLoading(false)
  
              console.log('invitation setting result was: ',result)

             
              if(result.data) {

                if(result.data.all_business_units.length > 0){

                  set_all_business_units(result.data.all_business_units)

                }

                if(result.data.all_settings.length === 0) {

                  cmsContext.setSnackBarOpen(true, "info", "No configurations yet. Let's create one to get started.")

                  set_all_invitation_settings([])

                  return

                }

                const all_invitation_settings = []

                var temp_invitation_setting;

                result.data.all_settings.forEach((setting) => {

                    temp_invitation_setting = cloneDeep(setting)
                    temp_invitation_setting.is_checked = false
                    all_invitation_settings.push(temp_invitation_setting)

                })

                set_all_invitation_settings(all_invitation_settings)

              }else if (result.errorMessage) {
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else {

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
              
            },
        
            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
           
      )

    })


  }

};

export default TrustPilotReviewsInvitations;