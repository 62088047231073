import {Route, Routes} from 'react-router-dom'
import DashboardLayout from './components/DashboardLayout/DashboardLayout';

import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';

import { useEffect, useContext } from "react";

import { getAccessJwtToken } from './store/globalUtilities';


import CmsContext from './store/cms-context';

import {Auth} from 'aws-amplify';

Amplify.configure(awsExports);




function App() {


  const cmsContext = useContext(CmsContext);


  //Auth.signOut()

  useEffect(() => {

    get_stores_data()

  },[])

  return (
    
    <>

    <Routes>

      <Route path='/customers/reviews_comments' element={<DashboardLayout type="reviews_comments"></DashboardLayout>}>
        
      </Route>

      <Route path='/products' element={<DashboardLayout type="products"></DashboardLayout>}>
        
      </Route>

      <Route path='/products/create' element={<DashboardLayout type="products"></DashboardLayout>}>
        
      </Route>

      <Route path='/products/list' element={<DashboardLayout type="list_products"></DashboardLayout>}>
        
      </Route>

      <Route path='/products/:store/:product_id' element={<DashboardLayout type="product_ind_view"></DashboardLayout>}>
        
      </Route>

      <Route path='/products/scheduled' element={<DashboardLayout type="schedule_products"></DashboardLayout>}>
        
      </Route>

      <Route path='/products/styles' element={<DashboardLayout type="product_styles"></DashboardLayout>}> 
      
      </Route>

      <Route path='/products/variants' element={<DashboardLayout type="product_variants"></DashboardLayout>}> 
      
      </Route>

      <Route path='/collections' element={<DashboardLayout type="collections"></DashboardLayout>}>
        
      </Route>

      <Route path='/collections/create' element={<DashboardLayout type="collections"></DashboardLayout>}>
        
      </Route>

      <Route path='/collections/list' element={<DashboardLayout type="list_collections"></DashboardLayout>}>
        
      </Route>

      <Route path='/collections/deploy' element={<DashboardLayout type="deploy_collections"></DashboardLayout>}>
        
      </Route>

      <Route path='/account' element={<DashboardLayout type="account"></DashboardLayout>}>
        
      </Route>

      <Route path='/settings' element={<DashboardLayout type="settings"></DashboardLayout>}>
        
      </Route>

      <Route path='/facebook/configs' element={<DashboardLayout type="facebook_configs"></DashboardLayout>}>
        
      </Route>

      <Route path='/facebook/ads' element={<DashboardLayout type="facebook_ads"></DashboardLayout>}>
        
      </Route>

      <Route path='/facebook/Sync' element={<DashboardLayout type="facebook_sync"></DashboardLayout>}>
        
      </Route>

      <Route path='/analytics/contacts' element={<DashboardLayout type="analytics_contacts"></DashboardLayout>}>
        
      </Route>

      <Route path='/instagram/tags' element={<DashboardLayout type="instagram_tags"></DashboardLayout>}>
        
      </Route>

      <Route path='/facebook/tags' element={<DashboardLayout type="facebook_tags"></DashboardLayout>}>
        
      </Route>

      <Route path="/google/ads" element={<DashboardLayout type="google_ads"></DashboardLayout>}>

      </Route>

      <Route path="/google/search" element={<DashboardLayout type="google_search"></DashboardLayout>}>

      </Route>

      <Route path="/google/manage" element={<DashboardLayout type="google_manage"></DashboardLayout>}>

      </Route>

      <Route path="/instagram/shopify" element={<DashboardLayout type="instagram_shopify"></DashboardLayout>}>

      </Route>

      <Route path="/storage/images" element={<DashboardLayout type="storage_images"></DashboardLayout>}>

      </Route>

      <Route path="/shipping/services" element={<DashboardLayout type="shipping_services"></DashboardLayout>}>

      </Route>

      <Route path="/inventory/reports" element={<DashboardLayout type="inventory_reports"></DashboardLayout>}>

      </Route>

      <Route path="/shipping/reports" element={<DashboardLayout type="shipping_reports"></DashboardLayout>}>

      </Route>

      <Route path="/shopify/preorder" element={<DashboardLayout type="shopify_order"></DashboardLayout>}>

      </Route>


      <Route path='/shopify/bring_it_back/configs' element={<DashboardLayout type="bring_it_back_configs"></DashboardLayout>}>

      </Route>

      <Route path='/shopify/bring_it_back/targets' element={<DashboardLayout type="bring_it_back_targets"></DashboardLayout>}>

      </Route>

      <Route path='/shopify/products/sync' element={<DashboardLayout type="products_sync"></DashboardLayout>}>

      </Route>

      <Route path='shopify/checkouts/post_purchase' element={<DashboardLayout type="post_purchase"></DashboardLayout>}>

      </Route>

      <Route path='shopify/conversion/rates' element={<DashboardLayout type="shopify_conversion_rates"></DashboardLayout>}>

      </Route>

      <Route path='/shopify/products/discounts' element={<DashboardLayout type="product_discounts"></DashboardLayout>}>

      </Route>

      <Route path='/facebook/breakdowns' element={<DashboardLayout type="facebook_breakdowns"></DashboardLayout>}>
        
      </Route>

      <Route path='/facebook/ad_groups' element={<DashboardLayout type="facebook_ad_groups"></DashboardLayout>}>

      </Route>

      <Route path='/warehouse/mintsoft' element={<DashboardLayout type="mintsoft_order_splits"></DashboardLayout>}>

      </Route>

      <Route path='/warehouse/mintsoft/orders/sync' element={<DashboardLayout type="mintsoft_orders_sync"></DashboardLayout>}>

      </Route>

      <Route path='/warehouse/couriers' element={<DashboardLayout type="carrier_services"></DashboardLayout>}>

      </Route>

      <Route path='/reviews/trust_pilot/invitations' element={<DashboardLayout type="trust_pilot_reviews_invitations"></DashboardLayout>}>

      </Route>


      <Route path='/reddit/configs' element={<DashboardLayout type="reddit_configs"></DashboardLayout>}>
        
      </Route>

      <Route path='/reddit/ads' element={<DashboardLayout type="reddit_ads"></DashboardLayout>}>
        
      </Route>

      <Route path='/reddit/sync' element={<DashboardLayout type="reddit_sync"></DashboardLayout>}>
        
      </Route>

      <Route path='/reddit/breakdowns' element={<DashboardLayout type="reddit_breakdowns"></DashboardLayout>}>
        
      </Route>
  
      <Route path='/reddit/ad_groups' element={<DashboardLayout type="reddit_ad_groups"></DashboardLayout>}>

      </Route>

      <Route path="*" element={<DashboardLayout type=""></DashboardLayout>}>

      </Route>
      

      </Routes>

    </>

  );


  function get_stores_data() {

    

    getAccessJwtToken().then((jwt)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/stores',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'store_domain': cmsContext.current_shop.store_domain,
            'Authorization':jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              console.log('result was', result)
  
              if(result.stores){

                const temp_store = {...cmsContext.current_shop}

                temp_store.connected_stores = result.stores

                cmsContext.set_current_shop(temp_store)

              }
  
            },
        
            (error) => {
              Auth.signOut()
              console.log('error was')
            }
      )

    })


  }


}

export default withAuthenticator(App);


// Remove unlayer editor/ extra packages

// npx generate-react-cli component MyComponent
