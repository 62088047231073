import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Button, Stack} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DatePicker from '../../DatePicker/DatePicker';



import cloneDeep from 'lodash/cloneDeep';


function TablePaginationActions(props) {

  const theme = useTheme();
  
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (

    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );

  }


  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'ads',
    numeric: false,
    disablePadding: false,
    label: 'Ads',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
      </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {

  const { numSelected } = props;

  return (
    <>

    <Toolbar
    disableGutters
      sx={{
        pl: { sm: 2.5 },
        pr: { xs: 1, sm: 1 },
        pt:0,
        pb:0
      }}
    >
       {numSelected > 0 ? (

        <Stack direction="row"  sx={{ flex: '1 1 100%' }} alignItems="center">

        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>


        {numSelected === 1 ? 
          <>


          <Button sx={{marginLeft:1}} variant='outlined' size="small"  onClick={() => {props.handle_ad_group_action("Deploy")}}>
            Edit / Deploy
          </Button>
          
          </>

          : null}


          </Stack>

        ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
        )}

      {numSelected > 0 ? (

        <>



      <Tooltip title="Delete">
        <IconButton onClick={(event) =>{props.delete_ad_groups()}}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      </>
      ): null}
    </Toolbar>
  
    </>
  );
};


EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const temp_styles = theme => ({
    listItemText:{
      fontSize:12
    }
  });


 function RedditAdGroupsListTable(props) {

  const theme = useTheme();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search_value, set_search_value] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [all_vendors, set_all_vendors] = React.useState([]);
  const [all_ad_groups, set_all_ad_groups] = React.useState([]);
  const [all_status, set_all_status] = React.useState(['Created', 'Saved', 'Deployed']);
  const [all_sorts, set_all_sorts] = React.useState(['Created', 'Updated', 'Title']);
  const [sku_value, set_sku_value] = React.useState('');
  

  const [selected_filters, set_selected_filters] = React.useState({"selected_vendors":[], "selected_ad_groups":[], "selected_status":[], "selected_sorts":[]});


  console.log("reddit ad groups state was: ", props.all_reddit_ad_groups)


  const selected = React.useMemo(() => {

    return(props.all_reddit_ad_groups.filter((product) => product.is_checked).length)

  }, [props.all_reddit_ad_groups])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_all_reddit_ad_groups((previous_products) => {

      var temp_products = [...previous_products]

      temp_products = temp_products.map((product) => {
  
        product.is_checked = is_checked;
  
        return(product);
  
      })

      console.log('settings products', temp_products)
  
      return(props.set_all_reddit_ad_groups(temp_products))

    })

  };



        
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    
  const handle_row_update = (product_id, type, value, optionNum) => {

    if(type === 'price' && value < 0){
      return;
    }

    const temp_options = cloneDeep(props.all_reddit_ad_groups);


    const index = temp_options.findIndex(object => {
      return object.id === product_id;
    });

    temp_options[index][type] = value;


    props.set_all_reddit_ad_groups((previousproducts) => {

      if(previousproducts){

        const temp_options = [...previousproducts];

        const index = temp_options.findIndex(object => {
          return object.id === product_id;
        });


        temp_options[index][type] = value;

        return(props.set_all_reddit_ad_groups(temp_options))

      }

      })


  }

  const handle_product_state = (type) => {

    const product = props.all_reddit_ad_groups.filter((product) => product.is_checked === true)

    if(product.length > 0){

      console.log('product was: ', product)

      props.set_component_state({layout_type: type, product_id: product[0].id})

    }else{

      console.log("Error getting product")

    }

  }


  useEffect(() => {
    

    if(props.all_reddit_ad_groups && props.all_reddit_ad_groups.length > 0) {

      const filteredRows = [...props.all_reddit_ad_groups]

      setRows(filteredRows);

    }

    

  }, [props.all_reddit_ad_groups])





  function findHashad_groups(searchText) {
      var regexp = /\B\#\w\w+\b/g
      var result = searchText.match(regexp);
      if (result) {
          return(result)
      } else {
          return([])
      }
  }


  function handleClick(event, index){

    //event.preventDefault()

    console.log('checked: ', event.target.checked)

    handle_row_update(props.all_reddit_ad_groups[index].id, "is_checked", event.target.checked, index)

  }

  

  return (
    <>
     <Box sx={{ width: '100%'}} style={{marginTop:20}}>

      <Paper sx={{ width: '100%', mb: 2 }} >
        
        <EnhancedTableToolbar delete_ad_groups={props.delete_ad_groups} handle_ad_group_action={props.handle_ad_group_action} filter_title={props.filter_title} set_filter_title={props.set_filter_title} filter_spend={props.filter_spend} set_filter_spend={props.set_filter_spend} filter_headline={props.filter_headline} set_filter_headline={props.set_filter_headline} filter_description ={props.filter_description} set_filter_description={props.set_filter_description} selected_country={props.selected_country} set_selected_country={props.set_selected_country} handle_timestamp_order_change={props.handle_timestamp_order_change} perform_search={props.perform_search} clearSearch={props.clearSearch} ranges_button_text={props.ranges_button_text} filter_user_name={props.filter_user_name} set_filter_user_name={props.set_filter_user_name} filter_ad_groups={props.filter_ad_groups} set_filter_ad_groups={props.set_filter_ad_groups}  calendar_open={props.calendar_open} set_calendar_open={props.set_calendar_open} onDateChange={props.onDateChange} timestampOrder={props.timestampOrder} numSelected={selected} delete_products={props.delete_products} handle_product_state={handle_product_state} />

        {props.all_reddit_ad_groups.length > 0 ? <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_reddit_ad_groups.length}
            />
            <TableBody>
              {(rows
               ).map((ad_group, index) => {

                var hash_ad_groups = [];

                if(ad_group.message_ad_groups){

                  if(ad_group.message_ad_groups.length > 0){
                    hash_ad_groups = ad_group.message_ad_groups.split(',');
                  }else{
                    hash_ad_groups = ad_group.message_ad_groups
                  }

                }


                var url = "";


                if(ad_group.url && ad_group.url.length > 0){
                  url = ad_group.url
                }else if(ad_group.full_picture){
                  url = ad_group.full_picture
                }else{
                 url = "/static/images/marketing.png"
                }



              var cpa = "";

              if(ad_group.cpa != null){

                cpa = (ad_group.cpa).toFixed(2).toString()

              }

              var spend = "";

              if(ad_group.spend_company_fx != null){

                spend = (ad_group.spend_company_fx).toFixed(2).toString()

              }



              return(

                <TableRow
                style={{cursor:'pointer'}}
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
                >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={ad_group.is_checked}
                          onChange={(event) => {handleClick(event, index)}}
                        />
                      </TableCell>
                      <TableCell align="left" sx={{width:100}}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{width:50, height:50}}
                      >
                        <img style={{width: 48, height: 48}} alt="snag" src={url}/>
                      </Box> 
                      </TableCell>

                      <TableCell align="left">
                       <Typography fontSize={12}>{ad_group.id}</Typography>
                      </TableCell>

                      <TableCell align="left">
                       <Typography fontSize={12}>{ad_group.total_number_of_ads}</Typography>
                      </TableCell>

                    </TableRow>
                    
                  );
                
              })}
              
            </TableBody>
            <TableFooter>

        </TableFooter>
          </Table>
        </TableContainer> : null}
    
      </Paper>

      <DatePicker onDateChange={props.onDateChange} calendar_open={props.calendar_open} set_calendar_open={props.set_calendar_open}></DatePicker>
      
    </Box> 
    </>
  );
}


export default React.memo(RedditAdGroupsListTable);