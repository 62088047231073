import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, List, ListItem, FormControl, Input, InputLabel, InputAdornment} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import Dropzone from 'react-dropzone'

import { UserCircle as UserCircleIcon } from '../../../icons/user-circle';


import LoadingButton from '@mui/lab/LoadingButton';





function TagsDialog(props) {


  function handle_close(){

    const temp_state = {...props.tags_dialog_state}

    temp_state.is_dialog_open = false

    props.set_tags_dialog_state(temp_state)

  }

  function handle_newly_added(){

    const temp_state={...props.tags_dialog_state}; 
    
    temp_state.newly_added_tags.push(temp_state.search_value.trim()); 
    
    temp_state.search_value = "";
    
    temp_state.filtered_tags=[...temp_state.all_tags];
    
    temp_state.filtered_tags = temp_state.filtered_tags.filter((row) => {

      return temp_state.selected_tags.indexOf(row) === -1
      
      });
      
      props.set_tags_dialog_state(temp_state);

  }


  return (
    <div>
      <Dialog
        open={props.tags_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.tags_dialog_state.action_type === "add_tags" ? "Add tags to" : "Remove tags from"} {props.tags_dialog_state.numSelected} Product(s)
        </DialogTitle>

        <DialogContent dividers>


          <Grid
            container
            spacing={3}
            alignItems="center"
            >

              <Grid item xs={12}>

              <TextField
              sx={{padding:0}}
              fullWidth
              size='small'
              value={props.tags_dialog_state.search_value}
              onChange={(event) => {const temp_state = {...props.tags_dialog_state}; temp_state.search_value = event.target.value;props.handle_tags_dialog_search_value(event.target.value);}}
              variant="outlined"
              id="input-with-icon-textfield"
              label="Search to find tags"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

              </Grid>

              {props.tags_dialog_state != null && props.tags_dialog_state.action_type === "add_tags" && props.tags_dialog_state.search_value.trim().length > 0 && props.tags_dialog_state.all_tags.indexOf(props.tags_dialog_state.search_value.trim()) === -1 ? <Grid item xs={12}>

                <div style={{display:'flex'}}>

                  <Button style={{justifyContent: "flex-start"}} fullWidth variant="text" startIcon={<AddCircleIcon />} onClick={() => {handle_newly_added()}}>Add "{props.tags_dialog_state.search_value}" </Button>

                </div>




              </Grid> : null}


              {props.tags_dialog_state && props.tags_dialog_state.newly_added_tags.length > 0 ? 

                <>

                <Grid item xs={12}>

                <Typography fontSize={12} color="gray"><strong>NEWLY ADDED</strong></Typography>

                </Grid>

                <Grid item xs={12}>

                  <List style={{paddingTop:0}}>

                    {props.tags_dialog_state && props.tags_dialog_state.newly_added_tags.map((tag, index) => {

                      return(<ListItem key={index}>
                        <Checkbox checked={true} onChange={() => {const temp_state = {...props.tags_dialog_state};temp_state.newly_added_tags.splice(temp_state.selected_tags.indexOf(tag),1);props.set_tags_dialog_state(temp_state);}}></Checkbox>
                        <Typography fontSize={12}>{tag}</Typography>
                        </ListItem>)

                    })}

                  </List>

                </Grid>

                </>

                : null}

              {props.tags_dialog_state && props.tags_dialog_state.selected_tags.length > 0 ? 

              <>

              <Grid item xs={12}>

              <Typography fontSize={12} color="gray"><strong>TO {props.tags_dialog_state.action_type === "add_tags" ? "ADD" : "REMOVE"}</strong></Typography>

              </Grid>

              <Grid item xs={12}>

                <List style={{paddingTop:0}}>

                  {props.tags_dialog_state && props.tags_dialog_state.selected_tags.map((tag, index) => {

                    return(<ListItem key={index}>
                      <Checkbox checked={true} onChange={() => {const temp_state = {...props.tags_dialog_state};temp_state.selected_tags.splice(temp_state.selected_tags.indexOf(tag),1);temp_state.filtered_tags.push(tag);props.set_tags_dialog_state(temp_state);}}></Checkbox>
                      <Typography fontSize={12}>{tag}</Typography>
                      </ListItem>)
              
                  })}

                </List>

              </Grid>
              
              </>

               : null}

              <Grid item xs={12}>

              <Typography fontSize={12} color="gray"><strong>AVAILABLE</strong></Typography>

              </Grid>

              <Grid item xs={12}>

                <List style={{paddingTop:0}}>

                  {props.tags_dialog_state && props.tags_dialog_state.filtered_tags.map((tag, index) => {

                    return(<ListItem key={index}>
                      <Checkbox checked={false} onChange={() => {const temp_state = {...props.tags_dialog_state};temp_state.filtered_tags.splice(temp_state.filtered_tags.indexOf(tag),1);temp_state.selected_tags.push(tag);props.set_tags_dialog_state(temp_state);}}></Checkbox>
                      <Typography fontSize={12}>{tag}</Typography>
                      </ListItem>)
              
                  })}

                </List>

              </Grid>


            </Grid>


            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.tags_dialog_state.isLoading} disabled={false} onClick={props.handle_bulk_actions}>Save</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default TagsDialog;