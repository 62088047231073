import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, FormControl, InputLabel, Select, MenuItem, List, Container, ListItem, IconButton, Chip} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { format } from 'date-fns';

import Dropzone from 'react-dropzone'

import CardTravelIcon from '@mui/icons-material/CardTravel';

import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';

import DeleteIcon from '@mui/icons-material/Delete';

import FolderIcon from '@mui/icons-material/Folder';

import ListItemAvatar from '@mui/material/ListItemAvatar';

import ListItemText from '@mui/material/ListItemText';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../store/cms-context';

import Inventory2Icon from '@mui/icons-material/Inventory2';

import CurrenciesInfo from '../../../../Utilities/CurrenciesInfo';


const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));


function InvitationSettingsDialog(props) {

  console.log("invitation settings dialog rendered: ", props.invitation_setting_dialog_state)

  const cmsContext = React.useContext(CmsContext);


  function set_invitation_setting(key, value) {
    
    const temp_state = {...props.invitation_setting_dialog_state}

    temp_state.invitation_setting[key] = value

    props.set_invitation_setting_dialog_state(temp_state)
    
  }



  function handle_invitation_setting() {
    
    const invitation_setting = {...props.invitation_setting_dialog_state.invitation_setting}
    
    if(!(invitation_setting.business_unit && invitation_setting.business_unit.length > 0)) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid business unit!")
      return

    }

    if(invitation_setting.template_id.trim().length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Template ID!")
      return

    }

    if(invitation_setting.reply_to.trim().length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid reply email!")
      return

    }

    var delay = invitation_setting.delay.toString().trim()

    delay = isNaN(parseInt(delay)) ? -1 : parseInt(delay);

    console.log("delay was: ", delay)

    if(delay < 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid delay!")
      return

    }
    
    var formattedTime = null;

    if(invitation_setting.preferred_day_time_is_active){

    const temp_time = invitation_setting.preferred_day_time;

    console.log('temp time was: ', temp_time, " : ", typeof(temp_time));

    if (temp_time && temp_time.$isDayjsObject && temp_time.isValid()) {
        // Use Day.js formatting
        formattedTime = temp_time.format('YYYY-MM-DDTHH:mm:ss');
        console.log('Formatted Time:', formattedTime);
    } else {
        cmsContext.setSnackBarOpen(true, "error", "Please select a valid time value!");
        return;
    }

  }

    console.log("invitation setting was: ", invitation_setting)

    const payload = {}

    payload.business_unit = invitation_setting.business_unit

    payload.template_id = invitation_setting.template_id.trim()

    payload.delay = delay

    payload.reply_to = invitation_setting.reply_to.trim()

    payload.preferred_day_time_is_active = invitation_setting.preferred_day_time_is_active

    payload.preferred_day_time = formattedTime

    console.log("payload was: ", payload)

    props.post_invitation_setting(payload, props.invitation_setting_dialog_state.type)

  }


  function handle_close(){

    const temp_state = {...props.invitation_setting_dialog_state}

    temp_state.is_dialog_open = false

    props.set_invitation_setting_dialog_state(temp_state)

  }

  function handle_tag_delete(index) {
    
    const temp_dialog_state = {...props.invitation_setting_dialog_state}

    temp_dialog_state.invitation_setting.omitted_tags.splice(index, 1)

    props.set_invitation_setting_dialog_state(temp_dialog_state)

  }


  return (

      <Dialog
        open={props.invitation_setting_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.invitation_setting_dialog_state.type} Target
        </DialogTitle>

        <DialogContent dividers>

          <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar

                    sx={{
                        height: 120,
                        width: 120,
                        ml: 'auto',
                        mr:'auto'
                    }}
                    src="/static/images/message.png"
                    >

                </Avatar>
                
              </Grid> 


              <Grid item xs={12}>

              <FormControl sx={{ width:'100%'}}>

              <InputLabel id="demo-simple-select-autowidth-label">Account</InputLabel>

              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                fullWidth
                value={props.invitation_setting_dialog_state.invitation_setting.business_unit}
                onChange={(event) => {const temp_dialog_state = {...props.invitation_setting_dialog_state}; temp_dialog_state.invitation_setting.business_unit = event.target.value; props.set_invitation_setting_dialog_state(temp_dialog_state);}}
                label="Account"
              > 
              
                {
                  props.invitation_setting_dialog_state.invitation_setting.all_business_units.map((business_unit, index) => {
                    return <MenuItem key={index} value={business_unit.id}>{business_unit.name}</MenuItem>
                  })
  
                }

              </Select>

              </FormControl>

              </Grid>

              <Grid
                  item
                  xs={12}
              >

                <TextField
                  label="Template ID"
                  value={props.invitation_setting_dialog_state.invitation_setting.template_id}
                  onChange={(event) => {const temp_state = {...props.invitation_setting_dialog_state}; temp_state.invitation_setting.template_id = event.target.value; props.set_invitation_setting_dialog_state(temp_state);}}
                  fullWidth
                  variant="outlined"
                />

              </Grid>

              <Grid
                  item
                  xs={12}
              >

                <TextField
                  label="Reply To"
                  value={props.invitation_setting_dialog_state.invitation_setting.reply_to}
                  onChange={(event) => {const temp_state = {...props.invitation_setting_dialog_state}; temp_state.invitation_setting.reply_to = event.target.value; props.set_invitation_setting_dialog_state(temp_state);}}
                  fullWidth
                  variant="outlined"
                />

              </Grid>

              <Grid
                  item
                  xs={12}
              >

                <TextField
                  type="number"
                  label="Delay"
                  value={props.invitation_setting_dialog_state.invitation_setting.delay}
                  onChange={(event) => {const temp_state = {...props.invitation_setting_dialog_state}; temp_state.invitation_setting.delay = event.target.value; props.set_invitation_setting_dialog_state(temp_state);}}
                  fullWidth
                  variant="outlined"
                />

              </Grid>

              <Grid item xs={12}>

              <FormControlLabel control={<Checkbox checked={props.invitation_setting_dialog_state.invitation_setting.preferred_day_time_is_active} onChange={(event) => {const temp_state = {...props.invitation_setting_dialog_state}; temp_state.invitation_setting.preferred_day_time_is_active = event.target.checked; props.set_invitation_setting_dialog_state(temp_state);}} />} label="Send At Preferrence" />


              {props.invitation_setting_dialog_state.invitation_setting.preferred_day_time_is_active ? 

                <LocalizationProvider dateAdapter={AdapterDayjs}>

                <TimePicker
                  label="Send At"
                  value={props.invitation_setting_dialog_state.invitation_setting.preferred_day_time}
                  onChange={(newValue) => {
                    
                    console.log("new value was: ", newValue)
                    const temp_state = {...props.invitation_setting_dialog_state};
                    temp_state.invitation_setting.preferred_day_time = newValue;
                    props.set_invitation_setting_dialog_state(temp_state);

                  }}
                  renderInput={(params) => <TextField sx={{width: '100%', marginTop:1.5}} {...params} />}
                />

                </LocalizationProvider>
          

             : null}

              </Grid>


            </Grid>


        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        <LoadingButton variant='outlined' loading={props.invitation_setting_dialog_state.isLoading} disabled={false} onClick={() => {handle_invitation_setting()}}>{props.invitation_setting_dialog_state.type}</LoadingButton>
        </DialogActions>
      </Dialog>


  );

}


export default React.memo(InvitationSettingsDialog);