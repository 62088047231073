import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Paper,
  Toolbar,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { alpha } from "@mui/material/styles";

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function ContactsListTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const selectedContacts = React.useMemo(
    () => props.all_contacts.filter((contact) => contact.is_checked),
    [props.all_contacts]
  );

  const handleSelectAllClick = (event) => {
    const isChecked = event.target.checked;
    props.set_all_contacts((prevContacts) =>
      prevContacts.map((contact) => ({
        ...contact,
        is_checked: isChecked,
      }))
    );
  };

  const handleSelectClick = (email, isChecked) => {
    props.set_all_contacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.email === email ? { ...contact, is_checked: isChecked } : contact
      )
    );
  };

  const handleEditContact = () => {
    const contactToEdit = selectedContacts[0]; // Assuming single selection
    if (contactToEdit) {
      contactToEdit.request_type = "update"; // Explicitly set request_type to "update"
      props.set_dialog_contact(contactToEdit, true);
    }
  };

  const handleDeleteContacts = () => {
    props.delete_contacts(selectedContacts);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selectedContacts.length > 0 && {
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
          }}
        >
          {selectedContacts.length > 0 ? (
            <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1">
              {selectedContacts.length} selected
            </Typography>
          ) : (
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" component="div">
              Contacts
            </Typography>
          )}
          {selectedContacts.length === 1 && (
            <Button onClick={handleEditContact} variant="contained" sx={{ mr: 2 }}>
              Edit
            </Button>
          )}
          {selectedContacts.length > 0 && (
            <Tooltip title="Delete">
              <IconButton onClick={handleDeleteContacts}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedContacts.length > 0 &&
                      selectedContacts.length < props.all_contacts.length
                    }
                    checked={
                      props.all_contacts.length > 0 &&
                      selectedContacts.length === props.all_contacts.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Notifications</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.all_contacts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((contact, index) => {
                  const isChecked = selectedContacts.some(
                    (selectedContact) => selectedContact.email === contact.email
                  );

                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isChecked}
                          onChange={(event) =>
                            handleSelectClick(contact.email, event.target.checked)
                          }
                        />
                      </TableCell>
                      <TableCell>{contact.email}</TableCell>
                      <TableCell>{contact.phone}</TableCell>
                      <TableCell>
                        {contact.notifications && contact.notifications.length > 0 ? (
                          contact.notifications.map((notification, notifIndex) => (
                          <Box key={notifIndex} sx={{ mb: 1 }}>
                            <Chip
                              label={notification.notificationType || "N/A"}
                              color="primary"
                              sx={{ fontWeight: "bold", mb: 0.5 }}
                            />
                            {(notification.notificationType === "Hourly Sales Summary" || notification.notificationType === "Hourly Sales Country Breakdown") && (
                              <>
                                {/* Sort Days in the Correct Week Order */}
                                <Typography variant="body2">
                                  Days: {notification.days && notification.days.length > 0 
                                    ? notification.days
                                        .sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b))
                                        .join(", ") 
                                    : "N/A"}
                                </Typography>

                                {/* Sort Hours in Ascending Order */}
                                <Typography variant="body2">
                                  Hours: {notification.hours && notification.hours.length > 0 
                                    ? notification.hours
                                        .slice()  // Clone array to avoid mutating original data
                                        .sort((a, b) => a - b)
                                        .join(", ") 
                                    : "N/A"}
                                </Typography>
                              </>
                            )}
                          </Box>
                          ))
                        ) : (
                          <Typography variant="body2" color="text.secondary">
                            No Notifications
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.all_contacts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default React.memo(ContactsListTable);