import React, { useState, useRef, useEffect, useContext } from "react";
import { Box, Container, Grid, Typography, Stack, Button, CircularProgress } from "@mui/material";
import CmsContext from "../../store/cms-context";
import { getAccessJwtToken } from "../../store/globalUtilities";
import ContactDialog from "../ContactDialog/ContactDialog";
import ContactsListTable from "../ContactsListTable/ContactsListTable";
import { default_contact } from "../../Utilities/Metrics";
import cloneDeep from "lodash/cloneDeep";

function AnalyticsCMS(props) {
  const cmsContext = useContext(CmsContext);
  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [all_contacts, set_all_contacts] = useState([]);
  const [contact_dialog_state, set_contact_dialog_state] = useState(cloneDeep(default_contact));

  useEffect(() => {
    console.log("Component mounted: AnalyticsCMS");
    isMountedRef.current = true;
    get_all_contacts();

    return () => {
      console.log("Component unmounted: AnalyticsCMS");
      isMountedRef.current = false;
    };
  }, []);

  function get_all_contacts() {
    console.log("Fetching all contacts from API...");
    setIsLoading(true);

    getAccessJwtToken()
      .then((jwt) => {
        console.log("JWT token retrieved:", jwt);
        return fetch("https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/contacts", {
          crossDomain: true,
          method: "GET",
          headers: {
            Authorization: jwt,
            email: cmsContext.current_shop.email,
            "content-type": "application/json",
          },
        });
      })
      .then((res) => {
        console.log("Fetch response status:", res.status);
        return res.json();
      })
      .then((result) => {
        console.log("API response received:", result);
        setIsLoading(false);

        if (result.contacts) {
          console.log("Processing contacts...");
          set_all_contacts(result.contacts);
        } else {
          console.error("No contacts field in API response.");
          cmsContext.setSnackBarOpen(true, "error", "Failed to fetch contacts.");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching contacts:", error);
        cmsContext.setSnackBarOpen(true, "error", "Error fetching contacts. Please try again.");
      });
  }

  function update_create_contact(contact, request_type) {
    console.log("Preparing to process contact...");
    console.log("Contact data:", contact);
  
    const finalRequestType = request_type || (contact.id ? "update" : "create");
  
    const cleanContact = {
      email: contact.email,
      phone: contact.phone,
      notifications: contact.notifications || [],
    };
  
    const request_body = {
      request_type: finalRequestType,
      contact: cleanContact,
    };
  
    console.log("Generated API payload for contact operation:", request_body);
  
    return getAccessJwtToken()
      .then((jwt) => {
        console.log("JWT token retrieved for API call:", jwt);
  
        return fetch("https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/contacts", {
          crossDomain: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
            email: cmsContext.current_shop.email,
          },
          body: JSON.stringify(request_body),
        });
      })
      .then((res) => {
        console.log("API response status for contact operation:", res.status);
        if (!res.ok) {
          throw new Error(`API returned status: ${res.status}`);
        }
        return res.json();
      })
      .then((result) => {
        console.log("API response for contact operation:", result);
  
        if (result.message) {
          console.log("Contact operation successful. Message:", result.message);
          cmsContext.setSnackBarOpen(true, "success", result.message);
          get_all_contacts(); // Refresh the contacts
        } else {
          console.error("API error during contact operation:", result.errorMessage || "Unknown error");
          cmsContext.setSnackBarOpen(true, "error", result.errorMessage || "Error processing contact.");
        }
      });
  }

  function delete_contacts(selectedContacts) {
    console.log("Preparing to delete contacts...");
    console.log("Selected contacts for deletion:", selectedContacts);

    const request_body = {
      request_type: "delete",
      contacts: selectedContacts.map((contact) => ({
        email: contact.email,
        phone: contact.phone,
        id: contact.id,
        notifications: contact.notifications || [],
      })),
    };

    console.log("Generated API payload for delete:", request_body);

    getAccessJwtToken()
      .then((jwt) => {
        console.log("JWT token retrieved for delete API call:", jwt);

        return fetch("https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/contacts", {
          crossDomain: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
            email: cmsContext.current_shop.email,
          },
          body: JSON.stringify(request_body),
        });
      })
      .then((res) => {
        console.log("API response status for delete:", res.status);
        if (!res.ok) {
          throw new Error(`API returned status: ${res.status}`);
        }
        return res.json();
      })
      .then((result) => {
        console.log("API response for delete:", result);

        if (result.message) {
          console.log("Contacts successfully deleted. Message:", result.message);
          cmsContext.setSnackBarOpen(true, "success", result.message);
          get_all_contacts(); // Refresh the contacts
        } else {
          console.error("API error during delete:", result.errorMessage || "Unknown error");
          cmsContext.setSnackBarOpen(true, "error", result.errorMessage || "Error deleting contacts.");
        }
      })
      .catch((error) => {
        console.error("Error during delete contacts:", error);
        cmsContext.setSnackBarOpen(true, "error", "Error deleting contacts. Please try again.");
      });
  }

  function show_create_contact_dialog() {
    console.log("Opening create contact dialog.");
    const temp_dialog_state = cloneDeep(default_contact);
    temp_dialog_state.is_dialog_open = true;
    temp_dialog_state.type = "create"; // Explicitly set to "create"
    temp_dialog_state.contact.notifications = []; // Ensure notifications array is initialized
  
    console.log("Initialized dialog state for creating contact:", temp_dialog_state);
    set_contact_dialog_state(temp_dialog_state);
  }

  function set_dialog_contact(contact, is_open) {
    console.log("Setting dialog contact. Is dialog open?", is_open);
    console.log("Contact data:", contact);
  
    const temp_contact_state = cloneDeep(default_contact);
    temp_contact_state.contact = contact;
    temp_contact_state.is_dialog_open = is_open;
  
    // Determine request type dynamically
    temp_contact_state.type = contact.id ? "update" : "create";
  
    console.log("Updated contact dialog state:", temp_contact_state);
    set_contact_dialog_state(temp_contact_state);
  }

  function handleEditContact(contact) {
    console.log("Editing contact:", contact);
  
    set_dialog_contact(contact, true); // Open the dialog with the contact pre-filled
  
    // Trigger `update_create_contact` when saving the dialog
    update_create_contact(contact, "update");
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress style={{ position: "absolute", top: "50%", left: "50%" }} />
      ) : (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
          <Container maxWidth={false} style={{ maxWidth: "1000px", overflow: "visible" }}>
            <Grid container spacing={2} style={{ overflow: "visible" }}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
                  <Typography variant="h5">Contacts</Typography>
                  <Button variant="contained" onClick={show_create_contact_dialog}>
                    Create Contact
                  </Button>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <ContactsListTable
                  delete_contacts={delete_contacts}
                  set_dialog_contact={set_dialog_contact}
                  all_contacts={all_contacts}
                  set_all_contacts={set_all_contacts}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}

      <ContactDialog
        post_contact={update_create_contact}
        contact_dialog_state={contact_dialog_state}
        set_contact_dialog_state={set_contact_dialog_state}
      />
    </>
  );
}

export default AnalyticsCMS;