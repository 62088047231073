import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import { Typography, Grid, Box, Checkbox, CircularProgress, Link, Divider, Stack, Avatar, TextField, Switch, FormGroup, FormControlLabel, createTheme, ThemeProvider, makeStyles, Autocomplete} from '@mui/material';

import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';

import LoadingButton from '@mui/lab/LoadingButton';

import CmsContext from '../../../../store/cms-context';

import cloneDeep from 'lodash/cloneDeep';

import { default_variant_style } from '../../../../Utilities/Metrics';

import MintsoftCountries from '../../../../Utilities/MintsoftCountries';
import { parseDouble } from '../../../../Utilities/HelperFunctions';



const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function CreateProductDialog(props) {

  const cmsContext = React.useContext(CmsContext);

  const dropzoneRef = React.useRef(null);




  const onSelect = (index, checked) => {

    const temp_variant_image = {...props.selected_variant_image}

    if(checked){
      temp_variant_image.image_index = index
    }else{
      temp_variant_image.image_index = -1
    }

    props.set_selected_variant_image(temp_variant_image)
    
  }

  function set_style_state(key, value){
    
    const temp_state = {...props.create_product_dialog_state}

    temp_state.product[key] = value

    props.set_create_product_dialog_state(temp_state)
    
  }

  function set_variant_style_state(key, value){
    
    const temp_state = {...props.create_product_dialog_state}

    temp_state.variant_style[key] = value

    props.set_create_product_dialog_state(temp_state)
    
  }

  function handle_style(request_type) {

    var temp_state = cloneDeep(props.create_product_dialog_state)

    console.log('temp state was: ', temp_state)
    
    if(temp_state.style_id < 0){
      cmsContext.setSnackBarOpen(true, "error", "Invalid Style!")
      return
    }

    const sku = temp_state.product.sku.trim();

    const barcode = temp_state.product.barcode.trim();

    const size = temp_state.product.size;

    const weight = parseDouble(temp_state.product.weight);

    const cost_price_gbp = parseDouble(temp_state.product.cost_price_gbp);
    
    console.log("cost price was: ",  cost_price_gbp)

    if (sku.length === 0) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid sku!")
      return
    }

    if (barcode.length === 0) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid barcode!")
      return
    }

    if (!weight || weight < -1) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid weight!")
      return
    }

    if (!cost_price_gbp || cost_price_gbp < -1) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid cost price!")
      return
    }

    props.post_product({style_id: temp_state.style_id, sku: sku, barcode: barcode, weight: weight, cost_price_gbp: cost_price_gbp, size: size}, request_type)

  }

  

  function handle_close(){

    const temp_state = {...props.create_product_dialog_state}

    temp_state.is_dialog_open = false

    props.set_create_product_dialog_state(temp_state)

  }

  function set_all_variants(all_variants){

    const temp_state = {...props.create_product_dialog_state}

    temp_state.all_variants = all_variants

    props.set_create_product_dialog_state(temp_state)

  }

  function handle_variant(action){

      const temp_state = cloneDeep(props.create_product_dialog_state)

      const style = cloneDeep(temp_state.variant_style)

      const name = style.variant_name.trim()
    
      const weight = style.weight.toString().trim()

      const barcode = style.barcode.toString().trim()

      if(name.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid name!")
        return
      }

      if(weight.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid weight!")
        return
      }

      if(barcode.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid barcode!")
        return
      }

      style.variant_name = name

      style.weight = parseFloat(weight)

      var dateString = ''

      try{

        if(isNaN(style.active_from.getTime())) {

          throw new Error("Invalid date")
  
        }

        dateString = `${style.active_from.getFullYear().toString()}-${('0' + (style.active_from.getMonth() + 1)).slice(-2)}-${('0' + style.active_from.getDate()).slice(-2)} ${('0' + style.active_from.getHours()).slice(-2)}:${('0' + style.active_from.getMinutes()).slice(-2)}:${('0' + style.active_from.getSeconds()).slice(-2)}`;

        style.active_from = dateString

        if(style.active_to){

          dateString = `${style.active_to.getFullYear().toString()}-${('0' + (style.active_to.getMonth() + 1)).slice(-2)}-${('0' + style.active_to.getDate()).slice(-2)}-${('0' + style.active_to.getHours()).slice(-2)}:${('0' + style.active_to.getMinutes()).slice(-2)}:${('0' + style.active_to.getSeconds()).slice(-2)}`;

          style.active_to = dateString

        }

      }catch(e){
        cmsContext.setSnackBarOpen(true, "error", "Please select a valid date!")
        return
      }
      
      
      console.log("style was: ", style)

      if(action === "add"){

        temp_state.all_variants.push(style)

      }else if(action === "update"){

      console.log('new state was: ', temp_state)

      temp_state.all_variants[props.create_product_dialog_state.editing_variant] = style

      }else if(action === "cancel"){

        temp_state.all_variants.forEach((variant) =>{
          variant.is_checked = false
        })

      }else{
        console.log("invalid action")
      }

      temp_state.variant_style = cloneDeep(default_variant_style)

      temp_state.editing_variant = -1

      props.set_create_product_dialog_state(temp_state)

  }

  function delete_variants(){

    const temp_state = {...props.create_product_dialog_state}

    const new_variants = []

    temp_state.all_variants.forEach((variant)=>{

      if(!variant.is_checked){
        new_variants.push(variant)
      }

    })

    temp_state.all_variants = new_variants

    props.set_create_product_dialog_state(temp_state)

  }


  const selected = React.useMemo(() => {

    if(props.create_product_dialog_state.all_variants){

    console.log('all variants were: ', props.create_product_dialog_state.all_variants)

    const temp_selected = props.create_product_dialog_state.all_variants.filter((variant) => variant.is_checked).length

    return(temp_selected)

    }

  },[props.create_product_dialog_state.all_variants])



  function start_editing_variant(index){

    if(index > -1){

    const temp_state = {...props.create_product_dialog_state}

    temp_state.variant_style = cloneDeep(props.create_product_dialog_state.all_variants[index])

    temp_state.variant_style.active_from = new Date(temp_state.variant_style.active_from)

    console.log('new date was: ', temp_state.variant_style.active_from)

    if(temp_state.variant_style.active_to){

      temp_state.variant_style.active_to = new Date(temp_state.variant_style.active_to)

    }

    temp_state.editing_variant = index

    props.set_create_product_dialog_state(temp_state)

  }

  }


  return (
    <div>
      <Dialog
        open={props.create_product_dialog_state.is_dialog_open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
         {props.create_product_dialog_state.type} Product
        </DialogTitle>

        <DialogContent dividers>


        <Grid
            container
            spacing={3}
            alignItems="center"
            >

             <Grid
              item
              xs={12}
             
              >

                <Avatar
                    sx={{
                      height: 160,
                      width: 160,
                      ml: 'auto',
                      mr:'auto'
                    }}
                    src="/static/images/prototype.png"
                  >
                    
                    <UserCircleIcon fontSize="small" />

                </Avatar>
                
              </Grid> 


              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" disabled={true} label="Name" variant="outlined" value={props.create_product_dialog_state.product.name} onChange={(event)=>{set_style_state("name", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" disabled={true} label="Colour" variant="outlined" value={props.create_product_dialog_state.product.colour} onChange={(event)=>{set_style_state("colour", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              style={{marginTop:16}}
              >

                <TextField id="outlined-basic" disabled={true} label="Customs Description" variant="outlined" value={props.create_product_dialog_state.product.customs_description} onChange={(event)=>{set_style_state("customs_description", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid item xs={12} sx={{marginTop:1}}>
              
                <Autocomplete
                    id="country-of-origin"
                    disabled={true}
                    options={MintsoftCountries}
                    getOptionLabel={(option) => option.Name ? option.Name : ""}
                    value={props.create_product_dialog_state.product.country_of_origin}
                    isOptionEqualToValue={(option, value) => {
                      return value.ID ? value.ID === option.ID : false
                    }}
                    onChange={(event, newValue) => {

                      set_style_state("country_of_origin", newValue)

                    }}
                    renderOption={(props, option, state) => {
                      //console.log('index was: ', props, " : ", option, " : ", state)
                      const key = `listItem-${option.ID}`;
                      return (
                        <li {...props} key={key}>
                          {option.Name}
                        </li>
                      );
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Country Of Origin" placeholder="Country Of Origin" />
                    )}
                />

                </Grid>


              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} label="HS Code" variant="outlined" value={props.create_product_dialog_state.product.hs_code} onChange={(event)=>{set_style_state("hs_code", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Price (GBP)" variant="outlined" value={props.create_product_dialog_state.product.price_gbp} onChange={(event)=>{set_style_state("price_gbp", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Cost Price (GBP)" variant="outlined" value={props.create_product_dialog_state.product.cost_price_gbp} onChange={(event)=>{set_style_state("cost_price_gbp", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>


              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} label="Sage Product Code" variant="outlined" value={props.create_product_dialog_state.product.sage_product_code} onChange={(event)=>{set_style_state("sage_product_code", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>


              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} label="Sage Product Group" variant="outlined" value={props.create_product_dialog_state.product.sage_product_group} onChange={(event)=>{set_style_state("sage_product_group", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Price (EUR)" variant="outlined" value={props.create_product_dialog_state.product.price_eur} onChange={(event)=>{set_style_state("price_eur", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Cost Price (EUR)" variant="outlined" value={props.create_product_dialog_state.product.cost_price_eur} onChange={(event)=>{set_style_state("cost_price_eur", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Price (USD)" variant="outlined" value={props.create_product_dialog_state.product.price_usd} onChange={(event)=>{set_style_state("price_usd", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Cost Price (USD)" variant="outlined" value={props.create_product_dialog_state.product.cost_price_usd} onChange={(event)=>{set_style_state("cost_price_usd", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Price (CAD)" variant="outlined" value={props.create_product_dialog_state.product.price_cad} onChange={(event)=>{set_style_state("price_cad", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Cost Price (CAD)" variant="outlined" value={props.create_product_dialog_state.product.cost_price_cad} onChange={(event)=>{set_style_state("cost_price_cad", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Price (AUSD)" variant="outlined" value={props.create_product_dialog_state.product.price_ausd} onChange={(event)=>{set_style_state("price_ausd", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" disabled={true} type="number" label="Cost Price (AUSD)" variant="outlined" value={props.create_product_dialog_state.product.cost_price_ausd} onChange={(event)=>{set_style_state("cost_price_ausd", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid 
              item
              xs={12}
              >

                <Typography variant='h6'>Variation</Typography>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" autoFocus label="SKU" variant="outlined" value={props.create_product_dialog_state.product.sku} onChange={(event)=>{set_style_state("sku", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Barcode" variant="outlined" value={props.create_product_dialog_state.product.barcode} onChange={(event)=>{set_style_state("barcode", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" label="Size" variant="outlined" value={props.create_product_dialog_state.product.size} onChange={(event)=>{set_style_state("size", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type='number' label="Weight (KG)" variant="outlined" value={props.create_product_dialog_state.product.weight} onChange={(event)=>{set_style_state("weight", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>

              <Grid
              item
              xs={12}
              >

                <TextField id="outlined-basic" type='number' label="Cost Price (GBP)" variant="outlined" value={props.create_product_dialog_state.product.cost_price_gbp} onChange={(event)=>{set_style_state("cost_price_gbp", event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

              </Grid>


        </Grid>

            
        </DialogContent>
        <DialogActions>
        <Button onClick={handle_close} variant='outlined'>{"Cancel"}</Button>
        {props.create_product_dialog_state.type !== "Deploy" ? <LoadingButton variant='outlined' loading={props.create_product_dialog_state.isLoading} disabled={false} onClick={() => {handle_style("Create")}}>{props.create_product_dialog_state.type}</LoadingButton> : null}

       {props.create_product_dialog_state.type === "Deploy"  ? <>

       {props.create_product_dialog_state.isLoading ? <CircularProgress sx={{marginLeft:2, marginRight:2}} />: <>

        <LoadingButton variant='outlined' loading={props.create_product_dialog_state.isLoading} disabled={false} onClick={() =>{handle_style("Save")}}>Update</LoadingButton> 

        <LoadingButton variant='outlined' loading={props.create_product_dialog_state.isLoading} disabled={false} onClick={() => {handle_style("Deploy")}}>Deploy</LoadingButton> 
        </>}
        </> : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default CreateProductDialog;