import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Avatar,
  Typography,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  IconButton,
  Box,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { grey, blue } from "@mui/material/colors";
import { UserCircle as UserCircleIcon } from "../../icons/user-circle";
import LoadingButton from "@mui/lab/LoadingButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CmsContext from "../../store/cms-context";

const CustomSwitch = styled(Checkbox)(({ theme }) => ({
  color: grey[300],
  "&.Mui-checked": {
    color: blue[600],
  },
}));

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function ContactDialog(props) {
  const cmsContext = React.useContext(CmsContext);
  const [errorMessages, setErrorMessages] = useState({}); // Error state for each notification

  // Set contact state
  function set_contact_state(key, value) {
    const temp_state = { ...props.contact_dialog_state };
    temp_state.contact[key] = value;
    props.set_contact_dialog_state(temp_state);
  }

  // Set notification details
  function set_notification_details(index, key, value) {
    const temp_state = { ...props.contact_dialog_state };
    temp_state.contact.notifications[index][key] = value;
    props.set_contact_dialog_state(temp_state);
  }

  // Add a new notification
  function add_notification() {
    const temp_state = { ...props.contact_dialog_state };
    if (!temp_state.contact.notifications) temp_state.contact.notifications = [];
    temp_state.contact.notifications.push({
      notificationType: "",
      days: [],
      hours: [],
    });
    props.set_contact_dialog_state(temp_state);
  }

  // Remove a notification
  function remove_notification(index) {
    const temp_state = { ...props.contact_dialog_state };
    temp_state.contact.notifications.splice(index, 1);
    props.set_contact_dialog_state(temp_state);
  }

  // Validate hours input
  function validateHoursInput(input) {
    if (/[^0-9, ]/.test(input)) {
      return "Invalid characters detected. Only numbers between 0-23 and commas are allowed.";
    }
    const sanitizedInput = input.replace(/[^0-9, ]/g, "");
    const hoursArray = sanitizedInput
      .split(",")
      .map((h) => h.trim())
      .filter((h) => h !== "")
      .map(Number);

    const invalidHours = hoursArray.filter((h) => isNaN(h) || h < 0 || h > 23);
    const hasDuplicates = new Set(hoursArray).size !== hoursArray.length;

    if (invalidHours.length > 0) {
      return `Invalid hours: ${invalidHours.join(", ")}. Enter numbers between 0 and 23.`;
    }
    if (hasDuplicates) {
      return "Duplicate hours are not allowed.";
    }

    return ""; // No errors
  }

  // Handle contact creation or update
  function handle_contact() {
    const contact = { ...props.contact_dialog_state.contact };
    const email = contact.email.trim();
    const phone = contact.phone.trim();

    if (email.length === 0 || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid email!");
      return;
    }
    if (phone.length > 0 && !/^\s*(?:\+?(\d{1,4}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone)) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid phone number!");
      return;
    }

    const newErrorMessages = {};
    contact.notifications.forEach((notification, index) => {
      if (
        notification.notificationType === "Hourly Sales Summary" ||
        notification.notificationType === "Hourly Sales Country Breakdown"
      ) {
        const error = validateHoursInput(notification.hours.join(","));
        if (error) {
          newErrorMessages[index] = error;
        }
      }
    });

    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMessages(newErrorMessages);
      return;
    }

    props.post_contact(contact, props.contact_dialog_state.type.toLowerCase())
      .then(() => handle_close())
      .catch((error) => {
        console.error("Error during contact operation:", error);
        cmsContext.setSnackBarOpen(true, "error", "Error processing contact. Please try again.");
      });
  }

  // Close dialog
  function handle_close() {
    const temp_state = { ...props.contact_dialog_state };
    temp_state.is_dialog_open = false;
    props.set_contact_dialog_state(temp_state);
  }

  return (
    <Dialog open={props.contact_dialog_state.is_dialog_open} onClose={handle_close} fullWidth>
      <DialogTitle>
        {props.contact_dialog_state.type === "create" ? "Create Contact" : "Update Contact"}
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Avatar sx={{ height: 120, width: 120, ml: "auto", mr: "auto" }} src="/static/images/profile.png">
              <UserCircleIcon fontSize="small" />
            </Avatar>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              value={props.contact_dialog_state.contact.email}
              onChange={(event) => set_contact_state("email", event.target.value)}
              fullWidth
              disabled={props.contact_dialog_state.type === "update"} // Grey out for updates
              InputProps={{
                style: props.contact_dialog_state.type === "update" ? { backgroundColor: "#f5f5f5" } : {},
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Phone"
              variant="outlined"
              value={props.contact_dialog_state.contact.phone}
              onChange={(event) => set_contact_state("phone", event.target.value)}
              fullWidth
            />
          </Grid>

          {props.contact_dialog_state.contact.notifications?.map((notification, index) => (
            <Box key={index} sx={{ mb: 2, border: "1px solid #ccc", borderRadius: 2, padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontSize={14}><strong>Select Notification Type</strong></Typography>
                  <Select
                    value={notification.notificationType || ""}
                    onChange={(event) =>
                      set_notification_details(index, "notificationType", event.target.value)
                    }
                    fullWidth
                  >
                    {[
                      "Daily Sales Summary",
                      "Hourly Sales Summary",
                      "Hourly Sales Country Breakdown",
                      "Daily Top 19 Best Selling SKUs by Quantity Sold",
                      "Daily Top 19 Best Selling SKUs by Net Sales",
                      "Shopify Store Performance",
                      "Single Line Item SKUs",
                    ]
                      .filter(
                        (type) =>
                          !props.contact_dialog_state.contact.notifications.some(
                            (notif, notifIndex) =>
                              notif.notificationType === type && notifIndex !== index
                          )
                      )
                      .map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>

                {(notification.notificationType === "Hourly Sales Summary" ||
                  notification.notificationType === "Hourly Sales Country Breakdown") && (
                  <>
                    <Grid item xs={12}>
                      <Typography fontSize={14}><strong>Select Days</strong></Typography>
                      <Grid container spacing={1}>
                        {daysOfWeek.map((day) => (
                          <Grid item xs={4} key={day}>
                            <FormControlLabel
                              control={
                                <CustomSwitch
                                  checked={notification.days?.includes(day) || false}
                                  onChange={(event) => {
                                    const updatedDays = [...(notification.days || [])];
                                    if (event.target.checked) {
                                      updatedDays.push(day);
                                    } else {
                                      const index = updatedDays.indexOf(day);
                                      if (index > -1) updatedDays.splice(index, 1);
                                    }
                                    set_notification_details(index, "days", updatedDays);
                                  }}
                                />
                              }
                              label={day}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography fontSize={14}><strong>Specify Hours (0-23, comma-separated)</strong></Typography>
                      <TextField
                        label="Hours (e.g., 9, 15, 21)"
                        variant="outlined"
                        placeholder="Enter hours between 0-23"
                        value={notification.hours.join(", ") || ""}
                        onChange={(event) =>
                          set_notification_details(index, "hours", event.target.value.split(",").map((h) => h.trim()))
                        }
                        fullWidth
                        error={!!errorMessages[index]}
                        helperText={errorMessages[index] || ""}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <IconButton onClick={() => remove_notification(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}

          <Grid item xs={12}>
            <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={add_notification} fullWidth>
              Add Notification Type
            </Button>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handle_close} variant="outlined">Cancel</Button>
        <LoadingButton variant="outlined" onClick={handle_contact}>
          {props.contact_dialog_state.type === "create" ? "Create" : "Update"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ContactDialog;