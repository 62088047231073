import * as React from 'react';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Button, Stack, Tab, Tabs} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import styles from './ListProducts.module.css';

import { tabsClasses } from '@mui/material/Tabs';

import { styled } from '@mui/material/styles';



const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    
  }),
);




function TablePaginationActions(props) {

  const theme = useTheme();
  
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (

    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );

  }


  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'sku',
    numeric: false,
    disablePadding: false,
    label: 'SKU',
  },
  {
    id: 'style',
    numeric: false,
    disablePadding: false,
    label: 'Style',
  },
  {
    id: 'barcode',
    numeric: false,
    disablePadding: false,
    label: 'Barcode',
  },
  {
    id: 'country_of_origin',
    numeric: false,
    disablePadding: false,
    label: 'Country of Origin',
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Created At',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Updated At',
  }
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  /*
  pl: { sm: 2 },
     pr: { xs: 1, sm: 1 },
  ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
  })*/

  return (
    <>
    {numSelected < 1 ?  <StyledTabs
          value={props.selected_tab}
          onChange={props.handleTabChange}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            marginTop:1.2,
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >


          {props.all_tabs.map((store, index) => {

            return(<StyledTab key={index} label={store.name} />)

          })}

        </StyledTabs>

    :
    <Toolbar
    disableGutters
      sx={{
        pl: { sm: 2.5 },
        pr: { xs: 1, sm: 1 },
        pt:0,
        pb:0
      }}
    >
       {numSelected > 0 ? (

        <Stack direction="row"  sx={{ flex: '1 1 100%' }} alignItems="center">

        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>


        {numSelected === 1 ? 
          <>


          <Button sx={{marginLeft:1}} variant='outlined' size="small"  onClick={() => {props.handle_product_state("deploy")}}>
            Edit / Deploy
          </Button>
          
          </>

          : null}


          </Stack>

        ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
        )}

      {numSelected > 0 ? (

        <>



      <Tooltip title="Delete">
        <IconButton onClick={(event) =>{props.delete_products()}}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      </>
      ): null}
    </Toolbar>
    }
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const temp_styles = theme => ({
    listItemText:{
      fontSize:12
    }
  });



 function ProductsListTable(props) {

  console.log("products from list table were: ", props.all_products)

  const theme = useTheme();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [all_vendors, set_all_vendors] = React.useState([]);
  const [all_skus, set_all_skus] = React.useState([]);
  const [all_status, set_all_status] = React.useState(['Created', 'Saved', 'Deployed'])
  const [all_sorts, set_all_sorts] = React.useState(['Created', 'Updated', 'Title'])



  console.log('products inside were: ', props.all_products)

  const selected = React.useMemo(() => {

    return(props.all_products.filter((product) => product.is_checked).length)

  },[props.all_products])



  // console.log('table options were', props.all_products)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    const is_checked = event.target.checked;

    props.set_all_products((previous_products) => {

      var temp_products = [...previous_products]

      temp_products = temp_products.map((product) => {
  
        product.is_checked = is_checked;
  
        return(product);
  
      })

      // console.log('settings products', temp_products)
  
      return(props.set_all_products(temp_products))

    })

  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


    
  const handle_row_update = (product_id, type, value, optionNum) => {

    if(type === 'price' && value < 0){
      return;
    }

   return props.set_all_products((previousproducts)=>{

      const temp_options = [...previousproducts];

      const index = temp_options.findIndex(object => {
        return object.id === product_id;
      });

      temp_options[index][type] = value;

      return(props.set_all_products(temp_options))

    })

  }

  const handle_product_state = (type, index) => {

    console.log('index was: ', index)

    if(index > -1){

      console.log('yes index')
      
      props.set_component_state({layout_type: type, product_id : props.all_products[index].id})
      
    }else{

    const product = props.all_products.filter((product) => product.is_checked === true)

    if(product.length > 0){

      props.set_component_state({layout_type: type, product_id : product[0].id})

    }

  }

  }

  useEffect(() => {

    const filteredRows = get_products_from_filters(props.all_products);

    setRows(filteredRows)

  }, [props.selected_filters, props.all_products])


  function get_products_from_filters(all_products){

    if(all_products.length === 0){
      return([])
    }


      var filteredRows = [...props.all_products]

      if(props.selected_filters.search_value.length > 0){

        filteredRows = filteredRows.filter((row) => {
          return row.title.toLowerCase().includes(props.selected_filters.search_value.toLowerCase());
        });

      }

      if(props.selected_filters.selected_vendors.length > 0){

        filteredRows = filteredRows.filter((row) =>{
          return props.selected_filters.selected_vendors.indexOf(row.vendor) > -1
        })

      }

      if(props.selected_filters.selected_tags.length > 0){

        filteredRows = filteredRows.filter((row) =>{
          return row.tags.split(", ").join(',').includes(props.selected_filters.selected_tags.join(','))
        })

      }

      if(props.selected_filters.selected_status.length > 0){

        // console.log('selected status were: ', props.selected_filters.selected_status)

        filteredRows = filteredRows.filter((row) =>{
          return props.selected_filters.selected_status.indexOf(row.last_request_status) > -1
        })

        // console.log('status filtered rows were: ', filteredRows)

      }


      if(props.selected_filters.selected_sku.length > 0){

        filteredRows = filteredRows.filter((row) => {

          var found_sku = false;

          for (var i=0; i < row.variants.length; i++){

            if(row.variants[i].sku.toLowerCase().includes(props.selected_filters.selected_sku.toLocaleLowerCase())){
              found_sku = true;
              break;
            }

          }

          return(found_sku)

        });

      }
      

      return filteredRows;

    
  }
  
  

  function handleClick(event, index){

    event.preventDefault()

    handle_product_state("deploy", index)


  }


  return (
    <>
    {props.all_products ? <Box sx={{ width: '100%'}} style={{marginTop:20}}>

      <Paper sx={{ width: '100%', mb: 2 }} >
        
        <EnhancedTableToolbar products_type={props.products_type} selected_tab={props.selected_tab} handleTabChange={props.handleTabChange} all_tabs={props.all_tabs} handle_bulk_actions_change={props.handle_bulk_actions_change} numSelected={selected} delete_products={props.delete_products} handle_product_state={handle_product_state} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.all_products.length}
            />
            <TableBody>
              {(props.rowsPerPage > 0
                 ? rows.slice(page * props.rowsPerPage, page * props.rowsPerPage + props.rowsPerPage)
                : rows
               ).map((product, index) => {

                var inventory = 0;

                if(Array.isArray(product.variants)){

                product.variants.forEach((variant) =>{
                  inventory = inventory + (parseInt(variant.quantity) || 0)
                })
              }


                var url = "";

                if(product.imageFiles && product.imageFiles.length > 0 && product.imageFiles[0].url){
                  url = product.imageFiles[0].url
                }else{
                  url = "/static/images/brand_identity.png"
                }

                var variant_text = ""

                if(product.variants){
                  variant_text = inventory.toString() + " in stock for " + product.variants.length.toString() + " variants"
                }else{
                  variant_text = inventory.toString() + " in stock"
                }

              return(

                <TableRow
                      style={{cursor:'pointer'}}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell padding="checkbox" className={styles.selectCheckbox}>
                        <Checkbox
                          color="primary"
                          className={styles.selectCheckbox}
                          checked={product.is_checked}
                          onChange={(event) => {handle_row_update(product.id,"is_checked",event.target.checked,index)}}
                        />
                      </TableCell>
                      <TableCell align="left" style={{paddingRight:0}}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{border:'1px solid lightgray', width:50 ,height:50,borderRadius:4}}
                      >
                         <img style={{width:48,height: 48}} alt="snag" src={url}/>
                      </Box>     
                      </TableCell>
                      {<TableCell
                       component="th"
                       scope="row"
                       style={{minWidth:200}}
                     >
                      <a href={"/products/" + props.selected_option.store_domain + "/" + product.id} onClick={(event) => handleClick(event, index)}><strong>{product.sku}</strong></a>
                      </TableCell>}
                      <TableCell align="left">
                       <Typography fontSize={12}>{product.name}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{product.barcode}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{product.country_of_origin.Name}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{product.created_at}</Typography>
                      </TableCell>
                      <TableCell align="left">
                       <Typography fontSize={12}>{product.updated_at}</Typography>
                      </TableCell>
                    </TableRow>
                      
                  );

              })}
              
            </TableBody>
            <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={12}
              count={rows.length}
              rowsPerPage={props.rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
          </Table>
        </TableContainer>
    
      </Paper>
      
    </Box> : null}
    </>
  );
}


export default React.memo(ProductsListTable);